import React from "react";
import { CarouselArrow } from "../../../styles";
import { ConvertedMarkdown } from "../../ConvertedMarkdown";

export const Arrow = ({ type, className, onClick, arrowText, isiSnippet, gtmlabel }) => {
	return (
    <CarouselArrow className={className + ` gtm-click`} onClick={onClick} data-isi={isiSnippet} data-gtm-event-label={gtmlabel}>
			{type==='forward' && <div><ConvertedMarkdown mdhtml={arrowText} /></div> }
      <div className={type} />
	 { type==='backward' && <div><ConvertedMarkdown mdhtml={arrowText} data-isi={isiSnippet} /></div> }
    </CarouselArrow>
  );
};
