import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2rem auto;

  .profileWrapper {
    display: inline;
  }
`;

export const CarouselItemsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    width: 910px !important;
  }

  .innerWrap {
    display: inherit;
    flex-direction: row;
    justify-content: space-between;
    width: 2000px !important;
  }
`;

export const CarouselArrow = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 20px;
  color: #440099;
  div:not(.forward, .backward) {
	color: #440099;
	font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.slick-disabled {
    div {
      &.backward {
        background-image: url("/images/icon-back-gray-arrow.svg");
        background-repeat: no-repeat;
      }

      &.forward {
        background-image: url("/images/icon-forward-gray-arrow.svg");
        background-repeat: no-repeat;
      }
    }
  }
  &.slick-next {
	  right: 0;
    text-align: right;
  }
  &.slick-prev {
	  left: 0;
    text-align: left;
  }
  &.slick-arrow,
  &.slick-next,
  &.slick-prev {
    bottom: -27px;
    display: flex;
    flex-direction: row;
    line-height: 1rem;
    top: initial;
    width: auto;
    z-index: 98;
    
    &::before {
      content: "";
      height: 20px;
      position: absolute;
      width: 20px;
    }

    @media ${device.mobile} {
      bottom: -9.25rem;
      line-height: 1.5rem;
      max-width: 27vw;
    }

    &> div {
      max-width: 15rem;
      width: 100%;

      @media screen and (max-width: 840px) {
        max-width: 12rem;
      }

      p {
        @media ${device.mobile} {
          font-size: 1rem;
        }
      }
    }
  }

  div {
    display: inherit;
    height: 20px;

    &.backward {
      background-image: url("/images/icon-back-arrow.svg");
      background-repeat: no-repeat;
	    width: 20px;
    }

    &.forward {
      background-image: url("/images/icon-forward-arrow.svg");
      background-repeat: no-repeat;
	  width: 20px;
	  background-position: right;
    }
  }
`;

export const CarouselSlide = styled.div`
  display: inline;
  scroll-snap-align: start;
  flex-shrink: 0;
  margin-right: 3.5rem;
  background: transparent;
  transition: transform 0.5s;
`;
