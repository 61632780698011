import React, { useEffect, useState } from "react";
import { PatientImage } from "./utils";
import { SlickCarousel, StackedSlides } from "./partials";
import { PatientProfileCard } from "../PatientProfileCard";
import { PatientModal } from "../Modals";
import { useStaticQuery, graphql } from "gatsby";
import { handleLock } from "../Modals/utils";
import { isWindow, scrollToTop } from "../Helpers";
import { Injector } from "../Injector/partials";

export const PatientCarousel = ({
	zoom,
	setZoom,
	trackWProps,
	setTrackWProps,
	isiSnippet,
	arrowISI,
	transcriptState,
	setTranscriptState
	
}) => {
	const [modalState, setModalState] = useState(null);
	const [videoPatientTranscript, setVideoPatientTranscript] = useState(``);

	const data = useStaticQuery(graphql`
		{
			allMarkdownRemark(
				filter: {
					frontmatter: { markdown_id: { eq: "patient-profiles" } }
				}
			) {
				edges {
					node {
						frontmatter {
							slug
							description
							keywords
							markdown_id
							title
							profiles {
								id
								name
								slide
								img
								title
								copy
								altScanText
								patientInfo {
									title
									copy
									img
									video
									realPatient
									seeStory {
										isStory
										cta
										gtmlabel
										gtmclose
									}
									scans {
										key
										title
										img
										gtmlabel
									}
									history {
										title
										list
									}
									videoTranscript
								}
								isiSnippets
							}
						}
						id
						html
					}
				}
			}
		}
	`);

	const profiles = data.allMarkdownRemark.edges[0].node.frontmatter.profiles;
	const mobArr = [profiles.slice(0, 3), profiles.slice(3, profiles.length)];

	let videoProfileTranscript = ``;

	Array.from(profiles).forEach((el) => {
		if (videoPatientTranscript === el.name.toLowerCase()) {
			videoProfileTranscript = el.patientInfo.videoTranscript[0];
		}
	});

	const handleClick = (i, name) => {
		scrollToTop();
		setModalState(i);
		lock(i);
		if (trackWProps.trackID !== name) {
			setTrackWProps(name);
		}
		setVideoPatientTranscript(name);
	};

	const lock = (i) => {
		if (modalState !== null || modalState === true) {
			handleLock(currentModal(i));
		} else {
			setTimeout(() => {
				handleLock(false);
			}, 500);
		}
	};

	const buildSlides = () => {
		if (profiles.length > 0) {
			if (isWindow) {
				if (window.innerWidth > 480) {
					return profiles.map((profile, i) => {
						return (
							<div
								role="button"
								tabIndex={i}
								data-index={i}
								key={`${profile.name}-${i}-patient-profile-slide-desktop`}
								onClick={() =>
									handleClick(
										profile.slide,
										profile.name.toLowerCase()
									)
								}
								onKeyDown={() =>
									handleClick(
										profile.slide,
										profile.name.toLowerCase()
									)
								}
								data-isi={profile.isiSnippets}
							>
								<PatientProfileCard
									id={profile.id}
									backgroundImage={PatientImage(profile.name)}
									title={profile.title}
									copy={profile.copy}
									patientInfo={profile.patientInfo}
									setTrackWProps={setTrackWProps}
								/>
							</div>
						);
					});
				} else if (window.innerWidth < 480) {
					return mobArr.map((profiles, i) => {
						return (
							<div key={i} data-index={i}>
								<StackedSlides
									handleClick={handleClick}
									slides={profiles}
								/>
							</div>
						);
					});
				}
			}
		}
	};

	const currentModal = (i) => {
		if (i === modalState) {
			return true;
		}
	};

	const buildModals = () => {
		if (profiles.length > 0) {
			return profiles.map((profile, i) => {
				return (
					<span key={`${i}-${profile.name}-patient-modal`}>
						<PatientModal
							videoProfileTranscript={videoProfileTranscript}
							setModalState={setModalState}
							handleModal={lock}
							id={i}
							isiSnippet={profile.isiSnippets}
							modalOpen={
								currentModal(profile.slide) ? true : false
							}
							modalName={profile.id}
							patientID={i}
							patientName={profile.name}
							patientInfo={profile.patientInfo}
							patientImage={PatientImage(profile.name)}
							gtmlabel={profile.patientInfo.scans.gtmlabel}
							gtmclose={profile.patientInfo.seeStory.gtmclose}
							trackWProps={trackWProps}
							setTrackWProps={setTrackWProps}
							closeClick={handleClick}
							zoom={zoom}
							setZoom={setZoom}
							transcriptState={transcriptState}
							setTranscriptState={setTranscriptState}
						/>
					</span>
				);
			});
		}
	};

	const manipulateDOM = () => {
		const _patientProfileContainer = document.querySelector(
			'[data-component="patient-carousel"]'
		);
		const _patientProfileComponent = document.querySelector(
			"div.patient-carousel"
		);
		if (_patientProfileContainer && _patientProfileComponent) {
			_patientProfileContainer.appendChild(_patientProfileComponent);
		}
	};

	useEffect(manipulateDOM);

	return (
		<div>
			<Injector
				container={"patient-carousel-id"}
				content={
					<SlickCarousel
						className={`nomargin`}
						desktopSlides={3}
						desktopToScroll={3}
						mobileSlides={1}
						mobileToScroll={1}
						centerMode={false}
						centerPadding={0}
						variableWidth={false}
						infinite={false}
						initialSlide={0}
						setTrackWProps={() => {}}
						isiSnippet={isiSnippet}
						arrowTextISI={arrowISI}
					>
						{buildSlides()}
					</SlickCarousel>
				}
			/>

			<div>{buildModals()}</div>
		</div>
	);
};
