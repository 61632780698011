import React from "react";
import { InfoModal } from "../Modals";
import { createMarkup } from "../Helpers";
import { FetchComponents } from "./utils";
import { ImageParser } from "../Partials";

export const PopupsInjector = ({ componentID, setTrackWProps }) => {
  const { popups } = FetchComponents(componentID);

  const buildInjectors = () => {
    return popups ? popups.map(
      ({ container, audioTrack, trigger, setInner, header, copy, img, isiSnippets, altText, closegtm}, i) => {
        return (
          <div key={`${i}-isi-injector`}>
			<InfoModal
			  modalName={`${container}-info-modal`}
			  audioSrcTrack={audioTrack}
			  setInner={setInner}
			  trigger={trigger}
			  content={buildPopup(header, copy, img, altText)}
			  closegtm={closegtm}
			  isiSnippets={isiSnippets}
			  setTrackWProps={setTrackWProps}
			  modalI={i}
			  container={container}
			/>
          </div>
        );
      }
    ) : null;
  };

  const buildPopup = (header, copy, img, altText) => {
    if (componentID !== "culture-conversion-components") {
      return (
        <div className="modal-popup-content overflow">
          <div className="modal-header">
            <div dangerouslySetInnerHTML={createMarkup(header)} />
          </div>
          <div dangerouslySetInnerHTML={createMarkup(copy)} />
          {img ? (
            <ImageParser
              className={"kit-image"}
              images={img}
              altText={altText}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="modal-popup-content overflow">
          <div className="modal-header">
            <div dangerouslySetInnerHTML={createMarkup(header)} />
          </div>
          {img ? (
            <ImageParser
              className={"kit-image"}
              images={img}
              altText={altText}
            />
          ) : null}
          <div className={"copy-text"} dangerouslySetInnerHTML={createMarkup(copy)} />
        </div>
      );
    }
  };

  return <>{popups ? buildInjectors() : null}</>;
};
