import styled from "styled-components/macro";
import { device } from "../components/Helpers";
import Expand from "../images/button-expand.svg";
import Minimize from "../images/button-minimize.svg";

export const ImageWrapper = styled.div`
  @media ${device.tablet} {
  }
`;

export const ImageZoomContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  height: auto;
  transition: 0.5s all;
  border-radius: 0.25rem;

  img {
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    box-shadow: ${(props) => props.isZoomed ? "0 2px 4px 0" : "rgba(0, 0, 0, 0.5)"} ${(props) => props.theme.colors.brand_black_00};
    @media ${device.mobile} {
      width: 100%;
    }
  }

  transform: scale(${(props) => (props.isZoomed ? 2.75 : 1)});
  transform-origin: bottom left;
  z-index: ${(props) => (props.isZoomed ? 1002 : 1001)};
`;

export const ImageExpandIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  width: 33px;
  height: 33px;
  transform: scale(${(props) => (props.isZoomed ? 0.5 : 1)});
  transform-origin: top right;
  background-image: url(${(props) => (props.isZoomed ? Minimize : Expand)});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ImageKitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  .kit-image {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    height: auto;
    position: relative;
    margin-bottom: 1rem;

    .alt-text {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      align-items: center;
      display: flex;
      color: ${(props) => props.theme.colors.brand_purple_00};
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0 auto;
    }
  }
  @media ${device.mobile} {
    flex-direction: column;
    .kit-image {
      margin: 0 auto;
      .alt-text {
        position: relative;
      }
    }
  }
`;

export const ImageZoomMobileContainer = styled.div`
  .container {
    position: relative;
    border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
    padding: 0.75rem 1.25rem;
    border-radius: 0.313rem;
    margin: 2.5rem auto;
    @media ${device.mobile} {
      margin: 1.6667rem auto 2.5rem;
    }
    .expand {
      content: url("/images/icon-expand.svg");
      height: auto;
      margin: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 2.05rem;
    }

    .innerWrap {
      display: flex;
      flex-direction: column;

      .content {
        color: ${(props) => props.theme.colors.brand_purple_00};
        font-size: 0.75rem;
        margin-bottom: 1.25rem;
        margin-left: -1rem;
        @media ${device.mobile} {
          margin-left: -0.5rem;
        }
        p {
          color: ${(props) => props.theme.colors.brand_gray_03};

          @media ${device.mobile} {
            line-height: 0.5rem !important;
            font-size: 0.25rem !important;
          }

          &:nth-of-type(1) {
            @media ${device.mobile} {
              line-height: 1rem !important;
              font-size: 0.75rem !important;
              padding-right: 1.5rem;
            }
          }
        }

        sup {
          @media ${device.mobile} {
            font-size: 0.25rem;
          }
        }
      }

      .img-container {
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;
