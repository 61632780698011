import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const SafetyStyles = styled.main`
  h1 {
    @media ${device.mobile} {
      margin-bottom: 1rem;
    }
  }
  .modal-popup-content {
    @media ${device.mobile} {
      padding: 1rem;
    }
    .warnings-header {
      @media ${device.mobile} {
        padding-right: 2.5rem;
        font-size: 1.9167rem;
      }
    }
  }

  .md-buffer {
    margin-bottom: 1.25rem;
  }
  #d-survey-management-image {
    @media ${device.mobile} {
      display: none !important;
    }
  }

  #clinical-trial-safety {
    /* margin-top:7rem; */
    @media ${device.mobile} {
      margin-top: 5rem;
    }
    img {
      width: 100%;
      &.safetyimg {
        margin-top: 2.5rem;
        padding-left: 7rem;
        padding-right: 6.125rem;
        @media ${device.mobile} {
          padding: 0;
          width: 500px;
          margin-top: 1rem;
        }
      }
    }

    .svg5 {
      width: 8.75rem;
      left: 44%;
      /* margin: 0 auto; */
      display: block;
      position: relative;
      top: -2.5rem;
      margin-bottom: -1rem;

      @media ${device.mobile} {
        left: 0;
        margin: 0 auto;
      }
    }

    .calloutbox1 {
      margin-top: 2rem;
      padding-left: 4.6875rem;
      padding-right: 5.9375rem;
      padding-bottom: 2.5rem;
      @media ${device.mobile} {
        padding-left: 1.1667rem;
        padding-right: 0.5rem;
      }
      h2 {
        margin-bottom: 0.6875rem;
        @media ${device.mobile} {
          font-size: 1.6667rem;
          margin-bottom: 1.6667rem;
        }
      }
      p {
        margin-bottom: 1.25rem;
      }

      div {
        ul {
          list-style: none;
          li {
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              content: "•";
              color: red;
              display: inline-block;
              width: 1rem;
              margin-left: -1rem;
              margin-top: -0.2rem;
            }
          }
        }
      }
    }
  }

  #additional-adverse-footnotes-content {
    > div:first-of-type {
      margin: 2.5rem 0 7.5rem 0;
    }
  }

  #management-strategies {
    & > img {
      display: block;
      margin: 0 auto 3.75rem;
      width: 96%;
    }
    & > ul {
      & > li {
        @media ${device.mobile} {
          font-size: 1.3333rem;
        }
      }
    }
    h2 {
      margin-bottom: 1.9375rem;
    }
    h3 {
      @media ${device.mobile} {
        font-size: 1.3333rem;
        margin-bottom: 1rem;
      }
    }
    ul {
      margin-bottom: 3.75rem;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
        &::before {
          content: "•";
          color: red;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          margin-top: -0.2rem;
        }
      }
    }
    .iconwrappers {
      position: relative;

      &.white {
        padding: 3rem 2rem 0.5rem 2rem;
        @media ${device.mobile} {
          padding-left: 0;
          margin-left: 0;
          margin-right: 0;
          margin-top: 2rem;
        }
        &:last-child {
          @media ${device.mobile} {
            margin-top: 5rem;
          }
        }
      }
    }
    .icons {
      background-color: #fff;
      height: 4px;
      position: relative;
      width: 40%;
      margin: 0 auto;
      top: -50px;
      @media ${device.mobile} {
        top: -39px;
      }
      img {
        position: relative;
        top: -35px;
        @media ${device.mobile} {
          top: -38px;
        }
      }
      &.icon2 {
        img {
          top: -20px;
          @media ${device.mobile} {
            top: -21px;
          }
        }
      }
    }
    .flexbtn {
      display: flex;
      justify-content: end;
      margin-bottom: 2.5rem;
    }
    a.btn-2 {
      width: 19.4375rem;
      &:after {
        line-height: 2rem;
        border-left: none;
        content: "";
      }
    }
    .treatment1 {
      width: 33%;
      text-align: center;
      & > h2 {
        margin-bottom: 4rem;
        @media ${device.mobile} {
          text-align: left;
        }
      }
      @media ${device.mobile} {
        width: 100%;
      }
    }
    .treatment2 {
      text-align: center;
      width: 67%;

      @media ${device.mobile} {
        width: 100%;
        margin-top: 3.3333rem;
      }
      & > h2 {
        margin-bottom: 4rem;
        @media ${device.mobile} {
          text-align: left;
        }
      }
      .calloutwrapper {
        display: flex;
        @media ${device.mobile} {
          flex-direction: column;
        }
        & > div {
          width: 50%;
          @media ${device.mobile} {
            width: 100%;
          }
        }
      }
    }
  }

  #next-link {
    width: 18.75rem;
    @media ${device.mobile} {
      width: 19rem;
    }
    .btn-1 {
      padding: 0.7rem 2.75rem 0.7rem 1.5rem;
      width: 300px;
      @media ${device.mobile} {
        width: auto;
        padding: 0.7rem 1.75rem 0.7rem 1.5rem;
      }
    }
  }

  #teaes-over-time {
    h2 {
      margin-bottom: 1.25rem;
    }
    ul {
      margin-bottom: 0.75rem;
      list-style: none;
      li {
        @media ${device.mobile} {
          font-size: 1.33333rem;
        }
        &::before {
          content: "•";
          color: red;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          margin-top: -0.2rem;
        }
      }
    }
    .flexbtn {
      display: flex;
      justify-content: end;
      margin-bottom: 2.5rem;
    }
    a.btn-2 {
      width: fit-content;
      padding: 0.7rem 4rem 0.7rem 1.5rem;
      &:after {
        line-height: 2rem;
      }
    }
    .text-wrapper {
      p {
        margin-bottom: 0.75rem;
      }
    }

    .smalltext {
      p {
        @media ${device.mobile} {
          font-size: 1.0833rem;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .adverse-reactions-wrapper {
    @media ${device.mobile} {
      width: 100%;
      overflow-x: scroll;
    }
  }

  .list-container {
    display: flex;
    margin: 2rem;
    margin-left: 10.5625rem;
    margin-right: 12.6875rem;
    @media ${device.mobile} {
      margin-left: 0;
      margin-right: 0;
      margin-top: 3.333rem;
      margin-bottom: 2.5rem;
    }
    img {
      @media ${device.mobile} {
        width: 100%;
      }
    }
  }

  .marBot25 {
    margin-bottom: 3.75rem;
  }

  .safety-list-container {
    display: flex;
    margin: 0;
    justify-content: space-between;
    @media ${device.mobile} {
      flex-direction: column;
    }
    ul {
      li {
        @media ${device.mobile} {
          font-size: 1.3333rem;
        }
      }
    }
    & > div:first-child {
      @media ${device.mobile} {
        margin-bottom: 0.5rem;
      }
    }
  }

  .smalltext p {
    margin-bottom: 2.5rem;

    span.no-anchor > a {
      color: inherit;
      cursor: default;
      text-decoration: none;
    }
  }

  .footnote {
    &:first-of-type {
      margin-top: 2.5rem;
      margin-bottom: 6.5rem;
    }
  }

  .scrolltext {
    margin-top: 1.6667rem;
    font-size: 1.33333rem;
  }

  .treatmentwrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    h2 {
      line-height: 5rem;
      text-align: center;
    }

    .icons {
      background: #fff;
      margin: -4rem auto 0;
      position: relative;
      text-align:center;
      top: 0;
      width: 5rem;

      img {
        text-align:center;
        margin: auto;
      }
    }
  }
`;

export const MobileSurveyMgntChartStyles = styled.div`
  display: none;
  margin: 1rem auto;

  @media ${device.mobile} {
    display: block;
    margin-bottom: 2.333rem;
  }

  & > div {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:nth-of-type(1) {
      margin-bottom: 1rem;
    }

    & > a {
      align-items: center;
      display: flex;
      height: 5.5rem;
      justify-content: center;
      padding-left: 3.25rem;
      width: 48.75%;

      &[data-selector] {
        background-image: url("../images/increased-coughing-orange.svg");
        background-position: 7% 0.75rem;
        background-repeat: no-repeat;
        background-size: 3.25rem;
      }

      &[data-selector="one"] {
        background-image: url("../images/increased-coughing-white.svg");
        background-size: 28px 30px;
        background-position: 1rem;
      }
      &[data-selector="two"] {
        background-image: url("../images/dyspnea-white.svg");
        background-size: 23px 30px;
        background-position: 1rem;
      }
      &[data-selector="three"] {
        background-image: url("../images/dysphonia-white.svg");
        background-size: 23px 30px;
        background-position: 1rem;
      }
      &[data-selector="four"] {
        background-image: url("../images/increased-sputum-white.svg");
        background-size: 29px 30px;
        background-position: 1rem;
      }

      &.active {
        cursor: default;

        &[data-selector="one"] {
          background-image: url("../images/increased-coughing-orange.svg");
          background-size: 28px 30px;
          background-position: 1rem;
        }
        &[data-selector="two"] {
          background-image: url("../images/dyspnea-orange.svg");
          background-size: 23px 30px;
          background-position: 1rem;
        }
        &[data-selector="three"] {
          background-image: url("../images/dysphonia-orange.svg");
          background-size: 23px 30px;
          background-position: 1rem;
        }
        &[data-selector="four"] {
          background-image: url("../images/increased-sputum-orange.svg");
          background-size: 29px 30px;
          background-position: 1rem;
        }
      }
    }
  }

  #management-chart {
    display: flex;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 0 10%;
    width: 100%;
    @media ${device.mobile} {
      padding: 0;
    }
  }
`;
