import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const ReferencesContainer = styled.div`
  margin: 0.75rem 0;
  margin-top: 0;
  font-size: 0.75rem;
  .references {
    span {
      @media ${device.mobile} {
        font-size: 1rem;
      }
    }
  }
  a {
    &.no-anchor {
      color: inherit;
      text-decoration: none;
    }
  }
`;

export const ReferencesHeader = styled.div`
  text-align: left;
  font-weight: bold;
  color: ${(props) => props.theme.colors.brand_gray_03};
  @media ${device.mobile} {
    font-size: 1rem;
  }
`;
