import React from "react";
import { PatientProfileCard } from "../../PatientProfileCard";
import { PatientImage } from "../utils";

export const StackedSlides = ({ slides, handleClick, isiSnippets }) => {
  const buildStackedSlides = () => {
    if (slides.length > 0) {
      return slides.map((slide, i) => {
        return (
          <div
            role={"button"}
            tabIndex={i}
            data-index={0}
            key={`${slide.name}-${i}-patient-profile-slide`}
            onKeyDown={() => handleClick(slide.slide, slide.name.toLowerCase())}
            onClick={() => handleClick(slide.slide, slide.name.toLowerCase())}
			data-isi={slide.isiSnippets}
          >
            <PatientProfileCard
              backgroundImage={PatientImage(slide.name)}
              title={slide.title}
              copy={slide.copy}
              patientInfo={slide.patientInfo}
            />
          </div>
        );
      });
    }
  };

  return <>{buildStackedSlides()}</>;
};
