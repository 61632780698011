import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const PageStayInformedStyles = styled.main`
  margin-top: 0rem;
  padding-top: 2rem;
  /* padding-bottom: 7.5rem !important; */
  @media ${device.mobile} {
    padding-top: 9rem !important;
    padding-bottom: 4rem !important;
  }
  .signup-form {
    margin-top: 0;
    padding-top: 4rem !important;
  }

  .md-container {
    padding-top: 2rem;
    @media ${device.mobile} {
      padding-top: 0rem !important;
    }
  }

  .submit-button {
    @media ${device.mobile} {
      margin-bottom: 0 !important;
    }
  }
`;
