import styled from "styled-components/macro";
import { device } from "../components/Helpers";
export const DefinitionsContainer = styled.div`
  margin: 0 0 0.75rem 0;
  font-size: 0.75rem;
  padding-left: .55rem;

  @media ${device.mobile} {
    line-height: 1rem;
    margin-top: 3rem;
  }

  p {
    @media ${device.mobile} {
      font-size: 1rem !important;
      line-height: inherit;
    }
  }
`;
