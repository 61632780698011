import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export const Seo = ({ description, keywords, title, image, url, author }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaTitle = title || data.site.siteMetadata.title;
        const metaUrl = url || data.site.siteMetadata.url;
        // const metaImage = image || data.allMdx.edges.node.frontmatter.featureImage.publicURL;
        const metaKeywords = keywords || [
          "ARIKAYCE",
          "regimen",
          "refractory",
          "MAC",
          "lung",
          "safety",
          "prescribing",
          "NTM",
        ];
		    // const bodyAttributes = unbranded.includes(window.location.pathname) ? { class: 'unbranded-page' } : {};

        return (
          <Helmet
            title={title}
            link={[
              {
                rel: `icon`,
                type: `image/png`,
                href: `favicon.ico`,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                name: `author`,
                content: `Area23 Healthcare: An IPG Health Network Company`,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              // {
              //     name: `og:image`,
              //     content: metaImage
              // },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
            bodyAttributes={{ class: 'scrollable-parent' }}
            // htmlAttributes={bodyAttributes}
          />
        );
      }}
    />
  );
};

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
