import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const PageSummaryStyles = styled.main`
position: relative;
    margin: 12.5rem auto 0;
  
  section{
    margin-top:0;
  }

  span {
    @media ${device.mobile} {
    font-size:1.33rem;
   }
  }
  
  ${'' /* div:nth-child(1) > p {
    color:${(props) => props.theme.colors.brand_purple_00};
  } */}

  div:not(.footnotes) > p {
	  color: #440099;
  }

	p {
		margin-bottom: 1.5rem;
	}
  
  a {
	  text-decoration: underline;
    font-weight: bold;
    @media ${device.mobile} {
      font-size:1.33rem;
     }
  }

  .highlight {
    @media ${device.mobile} {
     font-size:1.33rem;
    }
  }

  .summary-columns {
    color: #440099;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 1.5rem;
    margin-top: 5.5rem;

    @media ${device.mobile} {
      flex-direction: column;
      margin-top: 3.5rem;
    }

    &> div {
      align-items: center;
      border-radius: 0.5rem;
      border: 2px solid #e35205;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 4rem 1.5rem 3rem 1.5rem;
      position: relative;
      text-align: center;
      width: 18%;

      @media ${device.mobile} {
        width: 100%;
        margin-bottom: 4.5rem;
        margin-left: 0;
      }

      &::before {
        content: "";
        background-color: ${(props) => props.theme.colors.white};
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1rem solid ${(props) => props.theme.colors.white};
        margin-top: -3.35rem;
        position: absolute;
        top: 0;
      }

      &.calendar::before {
        background-image: url("../images/calendar.svg");
        padding: 2.25rem;
        background-color: transparent;
        border: none;
        margin-top: -2.5rem;
      }

      &.card::before {
        background-image: url("../images/card.svg");
        padding: 2.25rem;
        @media ${device.mobile} {
          padding: 2.5rem;
        }
      }

      &.patients::before {
        background-image: url("../images/patients.svg");
        padding: 2.25rem;
        @media ${device.mobile} {
          padding: 2.5rem;
        }
      }
      
      &.sun::before {
        background-image: url("../images/sun.svg");
        padding: 2.25rem;
        @media ${device.mobile} {
          padding: 2.5rem;
        }
      }

      &.target::before {
        background-image: url("../images/target.svg");
        padding: 2.25rem;
        @media ${device.mobile} {
          padding: 2.5rem;
        }
      }

      &> h2 {
        margin-bottom: 1.25rem;
        padding: 0 2rem;
        text-align: center;
      }

      span:not(:nth-child(1)) {
        margin-top: 1rem;
        font-size: 14px;
      } 
    }
  }

  .footnote {
    margin: 4rem 0 1.25rem 0;
    @media ${device.mobile} {
      margin: 3rem 0 1.25rem 0;
      }
  }

  .definitions,.definitions-section {
    margin-bottom:0;
    @media ${device.mobile} {
      margin-top:0;
    }
  }

  .definitions > p {
    margin-bottom:0.5rem;
    color:${(props) => props.theme.colors.brand_gray_03} !important;
  }

  #references {
    margin:0;
    line-height:0.875rem;
    letter-spacing: 0.01071em;
  }

  .transcript{
    margin-top:1.875rem;
  }
`;
