import React, { useState } from "react";
import {
	ModalContainer,
	ModalPopup,
	ModalContentWrap,
	ModalClose,
} from "../../styles";
import { ModalImages, handleLock } from "./utils";
import { Injector } from "../Injector/partials";

export const MobileScanModal = ({ modalName, trigger, image }) => {
	const [open, setOpen] = useState(false);
	const handleClick = () => {
		setOpen(!open);
		handleLock(open);

		if (!open) {
			document.querySelector(`header`).classList.add(`hidden`);
			document.getElementById(`isi-tray`).classList.add(`hidden`);
		} else {
			document.querySelector(`header`).classList.remove(`hidden`);
			document.getElementById(`isi-tray`).classList.remove(`hidden`);
		}
	};

	return (
		<ModalContainer id={modalName}>
			<div
				role={"button"}
				tabIndex={modalName}
				id={`${modalName}-trigger`}
				className="modal-scan-trigger"
				onClick={handleClick}
				onKeyDown={handleClick}
			>
				{trigger}
			</div>
			<Injector
				container={`container`}
				content={
					<ModalContainer id={`${modalName}-popup-container`}>
						<ModalPopup isOpen={open} id={`${modalName}-popup`}>
							<ModalContentWrap
								className={"mobile-scan-modal-content"}
								isOpen={open}
								backgroundImage={image}
							>
								<span className="pinch-and-zoom" />
								<ModalClose
									className="mobile-scan-modal-close"
									IconClose={ModalImages()}
									onClick={handleClick}
								/>
							</ModalContentWrap>
							<div className="rotate-device">
								Please rotate device for best view.
							</div>
						</ModalPopup>
					</ModalContainer>
				}
			/>
		</ModalContainer>
	);
};
