import React from "react";
import { Injector, ISIToggle } from "./partials";

export const ISIToggleInjector = ({ query, handler }) => {
  const buildInjectors = () => {
    return query.map(({ container, trigger }, i) => {
      return (
        <div key={`${i}-isi-injector`}>
          <Injector
            container={container}
            content={<ISIToggle trigger={trigger} handler={handler} />}
          />
        </div>
      );
    });
  };

  return <>{buildInjectors()}</>;
};
