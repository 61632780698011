import React, { useState, useEffect } from "react";
import { createMarkup } from "./Helpers";
import { HeaderStyle } from "../styles/HeaderStyle";
import { Seo } from "./Seo";
import { Analytics } from "./Analytics";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Navigation } from "./Navigation";
import { MobileMenu } from "./MobileMenu";
import { SecondaryNavControl } from "./SecondaryNavControl";
import { InterstitialInjector } from "./Injector";
import { isWindow } from "./Helpers";
import AudioNavigator from "./AudioNavigator/AudioNavigator";
import unbranded from "./Data/unbranded";

export const Header = (props) => {
  const [loaded, setLoaded] = useState(false),
  trackWProps = props.trackWProps,
  setTrackWProps = props.setTrackWProps,
  showISITrack = props.showISITrack,
  setTranscriptState = props.setTranscriptState,
  initialAudioEngagement = props.initialAudioEngagement,
  setInitialAudioEngagement = props.setInitialAudioEngagement;

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { markdown_id: { eq: "header" } } }
      ) {
        edges {
          node {
            frontmatter {
              markdown_id
              interstitials {
                container
                trigger
                disclaimer
                setInner
                externalURL
                gtmlabel
              }
              isiToggle {
                container
                trigger
              }
            }
            html
          }
        }
      }
    }
  `);

  const headerHTML = data.allMarkdownRemark.edges[0].node.html;
  const interstitials = data.allMarkdownRemark.edges[0].node.frontmatter.interstitials;
  const slug = props.currentslug;

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  const iSITrack = () => {
	return showISITrack ? {
		trackID: "isi",
		src: "/audio-tracks/Arikayce_Full_ISI_040122_AVO.mp3",
		title: "Important Safety Information"
	} : trackWProps;
};

  return (
    <>
      <Analytics />
      <Seo
        title={props.seoTitle}
        // image={seoImage}
        description={props.seoDesc}
      />
      <HeaderStyle className={`secondary`}>
        <div className={`wrapper`}>
          <MobileMenu isLoaded={loaded} />
          <Navigation componentID={props.componentID} noSubNav={props.noSubNav} />
          {
            (isWindow && !unbranded.includes(window.location.pathname)) ? 
              <Link
                id={`arikayce-logo`}
                to={`/`}
                className={`gtm-logo`}
                data-gtm-event-label={`Arikayce Logo`}
              >
              <img
                src={`/images/logo-arikayce.svg`}
                alt={`Arikayce Logo`}
                className={`gtm-logo`}
                data-gtm-event-label={`Arikayce Logo`}
              />
              </Link>
            : null
          }
          <div
            data-component={`header`}
            className={`wrapper`}
            dangerouslySetInnerHTML={createMarkup(headerHTML)}
          />
          <SecondaryNavControl markdownID={props.markdownID} componentID={props.componentID} slug={slug} />
        </div>
        
		    <AudioNavigator 
          markdownID={props.markdownID} 
          track={iSITrack()} 
          trackWProps={trackWProps} 
          setTrackWProps={setTrackWProps} 
          setTranscriptState={setTranscriptState} 
          showISITrack={showISITrack}
          initialAudioEngagement={initialAudioEngagement} 
          setInitialAudioEngagement={setInitialAudioEngagement}
        />
        <InterstitialInjector query={interstitials} />
      </HeaderStyle>
    </>
  );
};
