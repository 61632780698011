import React from "react";
import { AudioTranscriptStyles } from "../styles/AudioTranscriptStyles";
import { createMarkup } from "./Helpers";

export const AudioTranscript = ({
	id,
	className,
	transcriptCopy,
	markdownID,
	transcriptState,
	setTranscriptState,
}) => {
	const handleClick = () => {
		const openedTranscriptBoxes = document.querySelectorAll(
			`.transcript-accordion > .transcript.open`
		);

		setTranscriptState(!transcriptState);

		Array.from(openedTranscriptBoxes).forEach((el) => {
			el.classList.remove(`open`);
			setTranscriptState(false);
			el.parentNode.children[0].innerHTML = `+`;
		});
	};

	return (
		<AudioTranscriptStyles
			id={id}
			className={
				`audio-transcript transcript` +
				(className ? ` ` + className : ``) +
				(transcriptState ? ` audiotrayopen` : ``)
			}
		>
			<div className={`header`}>
				{className === `patient-video-transcript`
					? `Video Transcript`
					: `Transcript`}
			</div>
			<div className={`transcript-accordion`}>
				<div
					tabIndex={0}
					className={
						`trigger gtm-accordian ` +
						(markdownID === `home-content` ? `yellow` : ``)
					}
					onClick={handleClick}
					onKeyDown={handleClick}
					aria-hidden={true}
					data-gtm-event-label={`Footnote`}
					role={`button`}
				>
					{transcriptState ? "-" : "+"}
				</div>
				<div
					className={
						`transcript ` +
						(markdownID === `home-content` ? `inverse` : ``)
					}
					dangerouslySetInnerHTML={createMarkup(transcriptCopy)}
				/>
			</div>
		</AudioTranscriptStyles>
	);
};
