import React from "react";
import { createMarkup, scrollToTop } from "../Helpers";
import { ImageZoomMobileContainer } from "../../styles";
import { handleLock } from "../Modals/utils";

export const ImageZoomMobile = ({ zoom, setZoom, content, img, alt }) => {

  const handleClick = () => {
    setZoom(!zoom);    
    setTimeout(() => { scrollToTop(`auto`); }, 500);
    // setTimeout(() => {  }, 800);

    setTimeout(() => {
      handleLock(zoom);
    }, 2000);
  };

  return (
    <ImageZoomMobileContainer onClick={handleClick}>
      <div className="container">
        <span className="expand" />
        <div className="innerWrap">
          <div
            className="content"
            dangerouslySetInnerHTML={createMarkup(content)}
          />
          <div className="img-container">
            <img src={img} alt={alt} />
          </div>
        </div>
      </div>
    </ImageZoomMobileContainer>
  );
};
