import styled from "styled-components";

export const ContainerStyle = styled.div`
background-color: ${(props) => (props.theme.colors.brand_purple_00)};
max-width: 1170px!important;
margin: 12.45rem auto 0;
min-width: 300px!important;
width: 90%!important;
position: fixed;
top: 0;
left: ${typeof navigator !== "undefined" && navigator.platform.indexOf('Win') > -1 ? '-17px' : '0'};
right: 0;
height: 10px;
z-index: 1;
&:before{
	content: '';
	display: block;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-top: 10px solid ${(props) => props.theme.colors.white};
	border-right: 10px solid ${(props) => props.theme.colors.white};
	border-left: 10px solid ${(props) => props.theme.colors.white};
}
`;
