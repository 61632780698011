import React from "react";
import { QueryInjector, ISITrayToggle } from "./partials";

export const ISITrayToggleInjector = ({ trigger, handler }) => {
  const buildInjector = () => {
    return (
      <>
        <QueryInjector
          container={
            "#isi-tray > div > #important-safety-information > div.isi-title-bar > #isi-tray-toggler-container"
          }
          tag={"isi-tray-toggler-content"}
          content={<ISITrayToggle handler={handler} trigger={trigger} />}
        />
      </>
    );
  };

  return <>{buildInjector()}</>;
};
