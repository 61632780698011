import React from "react";
import { ImageZoomContainer, ImageExpandIcon } from "../../styles";

export const ImageZoom = ({ zoom, setZoom, img, alt, gtmlabel }) => {
	const handleClick = () => {
		setZoom(!zoom);
	};

	return (
		<ImageZoomContainer
			onClick={handleClick}
			isZoomed={zoom}
			data-gtm-event-label={gtmlabel}
		>
			<ImageExpandIcon
				isZoomed={zoom}
				className={`gtm-accordian`}
				data-gtm-event-label={gtmlabel}
			/>
			<img src={img} alt={alt} />
		</ImageZoomContainer>
	);
};
