import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailPrescribeArikayceStyles = styled.main`
position: relative;
    margin: 12.5rem auto 0;
@media ${device.mobile} {
  font-size:1.33rem;
  position:relative;
}
#tabbing-through{
  @media ${device.mobile} {
    width:96% !important;
	z-index: ${(props) => (props.isZoomed ? "-1" : "3")} !important;
  }
}

section {

  margin-top:0;
}

section[data-tab-index="1"], 
section[data-tab-index="2"]{
  padding-top: 3.375rem;

  
  @media ${device.desktop_xlg} {
    padding-top: 1.375rem;
  }
  
  @media ${device.desktop_mobile} {
    padding-top: 1.375rem;
  }
}

// section[data-tab-index="2"]{
//   .slick-list {
//       @media ${device.mobile} {
//         height:470px !important;
//       }
//     }
// }

.mobile-hide {
  @media ${device.desktop_mobile} {
    display:none;
  }
}
#extra-help-program-interstitial-container{
  text-decoration:underline;
}

  .alt-text {
	font-size: 1.25rem;
	font-weight: bold;
	// margin-bottom: 1.5rem;
	text-align: center;
	color: #440099;
  }
  img {
	  max-width: 100%;
	  margin: 0 auto;
	  &.twentyeight-day-arikayce-kit {
      margin-top:1rem;
      width: 70%;
      
    @media ${device.mobile} {
      margin: 1.57rem auto 0 auto !important;
      width:100%;
    }
	  }
  }
  .lamira-nebulizer-system-kit, .getting-started-all-pieces-kit {
	  width: 50%;
	  display: inline-block;
    padding: 25px;
    
    @media ${device.mobile} {
    width:100%;
    }
    .alt-text {
      @media ${device.mobile} {
        font-size:1.583rem;
        }
    }
  }

  #arikayce-coverage {
    /* margin-top:8rem; */

    @media ${device.mobile} {
      margin-bottom: 0;
    }
    .callout-container {
      align-items: stretch;
      display: flex;
      flex-wrap: nowrap;
      margin-top: 4.75rem;
      margin-bottom: 2rem;

      & > [data-component="callout"] {
        margin: 0 1rem;
        position: relative;
        width: 33%;

        @media ${device.mobile} {
          margin: 0 auto;
          width: 100%;
        }

        &::after {
          content: "";
          background-color: ${(props) => props.theme.colors.white};
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 0.75rem solid ${(props) => props.theme.colors.white};
          left: 0;
          margin-left: 40%;
          margin-top: -3.35rem;
          position: absolute;
          top: 0;
        }

        &:nth-of-type(1) {
          margin-left: 0;
          @media ${device.mobile} {
            height: 16.5rem;
          }
        }

        &:last-of-type {
          margin-right: 0;
          em,
          span {
            font-weight: bold;
          }
        }

        &.check-mark::after {
          background-image: url("../images/icon-form-check-mark.svg");
          border: none;
          margin-top: -2.25rem;
          padding: 2.15rem;
          @media ${device.mobile} {
            padding: 2.5rem;
            margin-top: -2.5rem;
          }
        }

        &.copay::after {
          background-image: url("../images/ARIKAYCE_Icon_Card_Copay_Orange_RGB.svg");
          margin-left: 35%;
          padding: 2.25rem;
          @media ${device.mobile} {
            padding: 2.5rem;
          }
        }

        &.support::after {
          background-color: transparent;
          background-image: url("../images/icon-support.svg");
          margin-left: 35%;
          margin-top: -3.75rem;
          padding: 2.15rem;
          @media ${device.mobile} {
            padding: 2.5rem;
          }
        }

        h2 {
          line-height: 1.5rem;
          margin-bottom: 2rem;
          margin-top: 2.25rem;
          position: relative;
          text-align: center;
          z-index: 1;
        }
      }
      @media ${device.mobile} {
        flex-direction: column;
        align-items: center;
      }
    }

	.tiny {
		margin-bottom: 0.5rem;
    color:${(props) => props.theme.colors.brand_black_00};
		&:nth-last-child(1) {
			// margin-bottom: 1rem;
		}
	}

    [data-component="callout"] {
      @media ${device.mobile} {
        height: 174px;
        margin-bottom: 4rem !important;
      }
      h2 {
        @media ${device.mobile} {
          line-height: normal !important;
          margin-top: 1.25rem !important;
        }
      }
    }

    .white.support {
      @media ${device.mobile} {
        margin-bottom: 0 !important;
      }
    }

    h2 {
      &.zip-section-header {
        display: block;
      }
    }
  }

  .green.truck {
    ul {
      li {
        margin-bottom: 0.75rem;
        @media ${device.mobile} {
          font-size: 1.33rem;
        }
      }
    }
  }

  [data-component="tabs"] ~ h2 {
    display: none;

    &.zip-section-header {
      display: block !important;
    }
  }

  [data-component="tabs"] {
    padding-bottom: 0.75rem;

    [data-tab-item="2"] {
      position: relative;
      right: 0.25rem;
      border-top-right-radius: 5px !important;
      @media ${device.mobile} {
        right: 0rem;
      }
    }

    [data-tab-item="1"] {
      border-top-left-radius: 5px !important;
    }

    [data-tab-item="2"],
    [data-tab-item="1"] {
      @media ${device.mobile} {
        line-height: normal;
        padding: 11px 0;
        text-align: center;
        border-radius: 0;
        font-size: 1.5333rem;
      }
    }

    & > div {
      @media ${device.mobile} {
        padding: 3rem 1rem 3.75rem 1rem;
      }
      [data-tab-content] {
        position: relative;

        & > h2 {
          padding-left: 4.75rem;
          margin-bottom: 1.25rem;
          @media ${device.mobile} {
            padding-left: 6.55rem;
          }
          &:nth-of-type(2) {
            padding-left: 0;
          }
        }

        & > h2::after {
          content: "";
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          margin-left: -0.35rem;
          margin-top: -0.75rem;
          padding: 2.2rem;
          position: absolute;
          top: 0;

          @media ${device.mobile} {
            width: 5.75rem;
            height: 3.4rem;
            margin-left: 0;
            margin-top: 0.25rem;
          }
        }

        li {
          margin-bottom: 1rem;
          @media ${device.mobile} {
            font-size: 1.33rem;
          }
        }

        a {
          font-weight: bold;
        }

        p {
          &.tiny {
            margin-bottom: 0.438rem;
          }
        }

        .hang-reference {
          display: flex;
          flex-direction: row;

          p {
            &.tiny {
              margin-bottom: 0.438rem;
            }
          }
        }
      }

      [data-tab-content="1"] > h2::after {
        background-image: url("../images/ARIKAYCE_Icon_Card_Copay_Orange_RGB.svg");
      }

      [data-tab-content="2"] > h2::after {
        background-image: url("../images/ARIKAYCE_Icon_Card_ID_Orange_RGB.svg");
        @media ${device.mobile} {
        }
      }
    }
  }

  [data-component="formulary"] > div {
    padding-left: 0;
    @media ${device.mobile} {
      padding-bottom: 0;
    }
  }

  #formularyData {
    /* margin-top:-11.25rem; */
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    padding-top: 0;
    padding-bottom: 2.5rem;
    @media ${device.ie11} {
      align-items: center;
    }
    @media ${device.mobile} {
      flex-direction: column;
      padding-bottom: 0;
      align-items: flex-end;
    }

    div:nth-child(1) {
      margin: unset;
      padding-top: 0;
      margin-top: -1rem;
      @media ${device.mobile} {
        padding-top: 2rem;
        padding-bottom: 0;
        width: 100%;
      }
      @media ${device.ie11} {
        margin: 0;
        margin-top: 0.4rem;
      }
    }

    .zip-code {
      padding-top: 0;
      height: 2.8rem;
      width: 21.5rem;
      margin-top: 0;
      margin-bottom: 0.75rem;
      @media ${device.mobile} {
        width: 100%;
        height: 3.75rem;
        max-width: unset;
        margin-bottom: -0.25rem;
      }
    }
    .submit-button {
      @media ${device.mobile} {
        padding-top: 0;
        margin-top: 1.67rem;
        position: relative;
        left: 0;
        margin-right: 0;
      }
      @media ${device.ie11} {
        padding-top: 0;
        margin-top: 0;
      }
      margin-bottom: 0;
      .btn-1 {
        right: -3rem;
        bottom: 0;
        @media ${device.mobile} {
          right: 0;
        }
      }
    }

    .btn-1 {
      width: 7.8rem;
      height: 2.8rem;
      max-width: 7.8rem;
      padding: 3% 15% 6% 20%;
      right: -3rem;
      bottom: 0.3rem;

      img {
        margin-right: 0.6rem;
      }
    }
  }

  #formulary-results {
    display: none;

    @media ${device.mobile} {
      margin-top: 0;
    }

    &.active {
      display: block;
    }

    > div {
      .submit-button {
        margin-bottom: 0;
        .btn-1 {
          right: -3rem;
          bottom: -0.1rem;
        }
      }
    }
  }

  #arrows-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1.25rem;

    #left-arrow,
    #right-arrow {
      height: 1.25rem;
      &:hover {
        cursor: pointer;
      }
    }

    #right-arrow {
      margin-left: 5rem;

      &.gray {
        transform: rotate(180deg);
        pointer-events: none;
      }
      @media ${device.mobile} {
        width: 0.583rem;
        height: 1.083rem;
        position: relative;
        left: 5.5rem;
      }
    }
    #left-arrow {
      margin-right: 5rem;
      pointer-events: none;
      &.orange {
        transform: rotate(180deg);
        pointer-events: auto;
      }
      @media ${device.mobile} {
        width: 0.583rem;
        height: 1.083rem;
        position: relative;
        right: 5.5rem;
      }
    }

    @media ${device.mobile} {
      position: relative;
      top: 7rem;
    }
  }

  .orange {
    font-weight: bold;
    color: #e35205;
  }

  [data-component="formulary-results"] {
    .tiny {
      @media ${device.mobile} {
        margin-top: 9rem;
      }
    }
    div:nth-of-type(1) {
      padding-left: 0;
      @media ${device.mobile} {
        margin-top: 10.3rem;
      }
    }
  }

  #formularyResults {
    margin-top: -13rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    @media ${device.mobile} {
      flex-direction: column;
    }

    .submit-button {
      @media ${device.mobile} {
        margin-top: 1.33rem;
        padding-top: 0;
        position: relative;
        left: 14rem;
      }
    }

    div:nth-child(1) {
      margin: unset;
      @media ${device.mobile} {
        padding-bottom: 0;
      }
    }

    #plan-name {
      width: 22rem;
      height: 2.8rem;
      margin-bottom: 0.25rem;
      max-width: 345px;
    }

    .btn-1 {
      width: 7rem;
      height: 2.8rem;
      max-width: 7.8rem;
      padding: 3% 15% 6% 21%;
      right: 17.8rem;
      bottom: 0.5rem;
    }
  }

  #formulary-result-table {
    bottom: 7.9rem;
    margin-bottom: -6rem;
    margin-top: 2rem;

    .table-cell {
      padding-left: 1.5rem;
    }

    @media ${device.mobile} {
      margin-top: 2.5rem;
      bottom: 0;
    }
  }

  #arikayce-coverage-footnotes{
    margin-top:1.875rem;
  }

  #how-to-prescribe {
    &[data-index="2"]{
      .image-kit-container {
        @media ${device.mobile} {
          display:flex;
          flex-direction:column;
          align-items:center;
        }
      }
    }
      h2{
        font-weight:500;
        // font-size:1.75rem;
      }
    div{
      h2:nth-of-type(1){
        padding-top:1rem !important;
        padding-bottom:1rem !important;
        @media ${device.mobile} {
         font-weight:700;
        }
      }
      h1:nth-of-type(1){
        line-height:1.5rem;
        @media ${device.mobile} {
          line-height:2.2rem;
        }
      }
    }
    margin-bottom: 3rem;
    margin-top: 0 !important;

    @media ${device.mobile} {
      margin-top: 0rem !important;
      margin-bottom: 0;
    }

    h1 {
      @media ${device.mobile} {
        margin-bottom: 1rem;
      }
    }

    h1 {
      @media ${device.mobile} {
        line-height: normal;
      }
    }

    [data-component="callout"].purp {
      margin-left: 3rem;
      padding-left: 5.25rem;
      padding-top: 2.5rem;
      position: relative;

      @media ${device.mobile} {
        margin-left: 0;
        margin-top: 0;
        padding-left: 2rem;
      }

      &::after {
        content: "";
        background-image: url("../images/icon-guidelines.svg");
        background-repeat: no-repeat;
        background-size: contain;
        left: 0;
        margin-left: -1.75rem;
        margin-top: 4.25rem;
        padding: 2.15rem;
        position: absolute;
        top: 0;

        @media ${device.mobile} {
          left: 11.1rem;
          margin-left: 0;
          margin-top: 0;
          top: -2rem;
        }
      }

      & > h2 {
        margin-bottom: 0.5rem;
        @media ${device.mobile} {
          line-height: normal;
          margin: 1rem 0;
        }
      }

      a {
        display: inline-block;
        line-height: 3rem;
        padding: 0 0 0 1.5rem;
        width: 26rem;

        @media ${device.mobile} {
          padding-top: 0.75rem;
        }

        &::after {
          margin-top: 0.5rem;
          @media ${device.mobile} {
            margin-top: -1rem;
            right: 1.5rem;
          }
        }
      }

      p {
        &:last-child {
          @media ${device.mobile} {
            padding-top: 1.3333rem;
          }
        }
      }

      .btn-3 {
        @media ${device.mobile} {
          width: 18.2rem;
          height: 4.4rem;
          line-height: normal;
          left: 2rem;
        }
      }
    }

    .callout-container {
      display: flex;
      margin-bottom: 4rem;

      & > [data-component="callout"] {
        margin: 0 1rem;
        padding: 3.75rem 1.75rem;
        position: relative;
        width: 33%;

        @media ${device.mobile} {
          margin: 0 auto 4rem auto !important;
        }

        &.document::after {
          background-image: url("../images/icon-arikares-document.svg");
          padding: 2.25rem;
        }

        &.support::after {
          background-image: url("../images/icon-arikares-support.svg");
          padding: 2.25rem;
        }

        &.truck::after {
          background-image: url("../images/icon-arikares-truck.svg");
          padding: 2.25rem;
          background-color: #ffffff;
        }

        &::after {
          content: "";
          background-color: ${(props) => props.theme.colors.white};
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 1rem solid ${(props) => props.theme.colors.white};
          left: 0;
          margin-left: 35%;
          margin-top: -3.35rem;
          position: absolute;
          top: 0;
        }

        &:nth-of-type(1) {
          margin-left: 0;
          @media ${device.mobile} {
            width: 23.3rem;
            padding-bottom: 2.5rem;
          }
          h2 {
            @media ${device.mobile} {
              position: relative;
              bottom: 0.5rem;
              margin-bottom: 0;
            }
          }
        }

        &:nth-of-type(2) {
          margin-left: 0;
          @media ${device.mobile} {
            width: 23.3rem;
            padding-bottom: 2.5rem;
          }
          h2 {
            @media ${device.mobile} {
              position: relative;
              bottom: 0.5rem;
              margin-bottom: 0;
            }
          }
        }

        &:last-of-type {
          margin-right: 0;

          @media ${device.mobile} {
            width: 23.3rem;
            padding-bottom: 2.5rem;
          }
          h2 {
            @media ${device.mobile} {
              position: relative;
              bottom: 0.5rem;
              margin-bottom: 0;
            }
          }
        }

        & > h2 {
          font-size: 1.5rem;
          text-align: center;
          margin-bottom: 1.5rem;
        }
      }

      @media ${device.mobile} {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  #patients-receive {
    margin-bottom: 1.5rem;
    @media ${device.mobile} {
      margin-top: 5rem;
      margin-bottom: 0;
    }

    ol {
      margin-left: 1rem;
      @media ${device.mobile} {
        margin-top: 2.1rem;
      }
    }

    ol > li {
      margin-bottom: 0.75rem;
      @media ${device.mobile} {
        font-size: 1.33rem;
      }
    }

    .link-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @media ${device.mobile} {
        flex-direction: column;
        margin-top: 1rem;
        margin-left: 9.8rem;
      }
      & > #welcome-kit-popup {
        @media ${device.mobile} {
          margin-left: 3.5rem;
        }
      }
      & > #nebulizer-kit-popup {
        & > div span {
          a {
            @media ${device.mobile} {
              padding-left: 1.25rem;
            }
            span {
              @media ${device.mobile} {
                line-height: 1rem;
              }
            }
            &::after {
              @media ${device.mobile} {
                top: 1px;
                padding-top: 5px;
              }
            }
          }
        }
      }
    }

    .link-container > div {
      margin-right: 2rem;
      @media ${device.mobile} {
        margin-bottom: 1.7rem;
        margin-right: 0;
      }
    }

    #welcomePackKitModal-trigger {
      .btn-2 {
        @media ${device.mobile} {
          width: 13.1rem;
          height: 2.8rem;
          align-items: center;
          justify-content: center;
          padding: 0.75rem 2.5rem 1rem 0rem;
          margin-left: 3rem;
        }
      }
    }

    #arikayceKitModal-trigger {
      .btn-2 {
        @media ${device.mobile} {
          width: 16.33rem;
          height: 2.8rem;
          align-items: center;
          justify-content: center;
          padding: 0.75rem 2.5rem 1rem 0rem;
        }
      }
    }

    #nebulizerKit-trigger {
      .btn-2 {
        @media ${device.mobile} {
          width: 16.25rem;
          height: 4.6rem;
          align-items: center;
          justify-content: center;
        }
        &::after {
          @media ${device.mobile} {
            line-height: 4rem;
          }
        }
      }
    }

    .link-container a {
      font-size: 1.15rem;
      padding: 0.75rem 3.85rem 1rem 1.5rem;
      @media ${device.mobile} {
        padding: 0.75rem 1.75rem 1rem 0;
      }
      &::after {
        line-height: 2rem;
      }
    }
  }

  .footnote {
    margin-top: 3rem;
  }

  .table-col-head {
    &:nth-of-type(1) {
      width: 45%;
    }
    &:nth-of-type(2) {
      width: 15%;
    }
  }

  .list-container {
    display: flex;
    margin: 2rem;
  }

  .searched-zip {
    color: ${(props) => props.theme.colors.brand_orange_00};
  }

  .paragraph-wrap {
    p {
      &:first-of-type {
        margin-bottom: 0;
      }

      margin-bottom: 0.75rem;
    }
  }

  .paragraph-wrap:nth-of-type(1) {
    @media ${device.mobile} {
      margin-top: -1rem;
    }
  }

  .definitions {
    margin-top: 0;
  }

  .definitions-section{
    @media ${device.mobile} {
      margin-top: 0;
    }
  }

  .copy {
    @media ${device.mobile} {
      font-size: 1.33rem;
      margin-bottom: 1rem;
    }
  }

  [alt="Lamira Nebulizer System"] {
    @media ${device.mobile} {
      width: 100%;
      margin: 0rem -1rem 1rem -1rem !important;
    }
  }

  [alt="28-day ARIKAYCE Kit"] {
    @media ${device.mobile} {
      margin: 0rem -1rem 1rem -1rem !important;
    }
  }

  [alt="Getting Started Kit"] {
    @media ${device.mobile} {
      width: 100%;
    }
    & + .alt-text {
      @media ${device.mobile} {
        margin-top:1rem !important;
        margin-bottom: 2rem !important;
      }
    }
  }

  .alt-text {
    @media ${device.mobile} {
      margin: 0 auto !important;
    }
  }

  .table-00 {
    border-radius: 0.3125rem;
  }


  .slick-prev, .slick-next {
    @media ${device.mobile} {
      top:unset !important;
    }
  }

  .slick-dots {
    margin: 1.25rem 0 1.875rem 0;
    bottom:0;
    position:inherit;
    }
    .slick-arrow {
    bottom:-.375rem;
    }

      .transcript {
        margin-top:1.875rem;
      }
      .footnote {
        margin:0 0 1.25rem 0;
        @media ${device.mobile} {
        margin-top:2.333rem;
        }
      }

      .arikares-form-image {
        width:50%;
        margin:1.938rem auto 0 auto;
        @media ${device.mobile} {
          width:100%;
          margin:3.063rem auto 0 auto;
        }
      }
      section[data-index="1"],
      section[data-index="2"],
      section[data-index="3"]{
        h2{
          margin-bottom:1rem;
          font-size:1.75rem;
          font-weight:regular;
        }
      }

      #pi-link {
        margin-left:0.75rem;
      }

  .slick-initialized .slick-slide {
    padding: 0 .25rem;
  }

`;
