import React from "react";
import { createMarkup } from "./Helpers";
import { DefinitionsContainer } from "../styles";

export const Definitions = ({ definitions, className}) => {
  return (
    <DefinitionsContainer className={"definitions-section"} id={className}>
      <div className="definitions">
        <p dangerouslySetInnerHTML={createMarkup(definitions)} />
      </div>
    </DefinitionsContainer>
  );
};
