import React, { useEffect, useState } from "react";
import { BackToTopContainer, BackToTopButton } from "../../styles";
import Caret from "../../images/caret.svg";
import { scrollToTop } from "../Helpers";
import { isWindow } from "../Helpers";

export const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else if (scrolled <= 300) {
        setVisible(false);
      }
    };

    if (isWindow) {
      window.addEventListener("scroll", toggleVisible);
    }
  });

  return (
    <BackToTopContainer onClick={scrollToTop}>
      <BackToTopButton visible={visible}>
        <img src={Caret} alt="Caret" />
      </BackToTopButton>
    </BackToTopContainer>
  );
};
