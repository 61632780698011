import React from "react";
import { SlickCarousel } from "./partials";
import { createMarkup } from "../Helpers";

const isBrowser = typeof window !== "undefined";

export const SectionCarousel = ({
	id,
	slider,
	slidesYouWantToMake,
	arrowText,
	arrowTextISI,
	dataOptions,
	className,
	setTrackWProps,
}) => {
	const buildSlides = () => {
		if (isBrowser) {
			return slidesYouWantToMake.map((section, i) => {
				return (
					<section
						id={id}
						className={className}
						key={`${i}-slide-title-whatever`}
						data-index={i}
					>
						<div dangerouslySetInnerHTML={createMarkup(section)} />
					</section>
				);
			});
		}
	};

	return (
		<>
			<SlickCarousel
				className={className}
				arrowText={arrowText}
				arrowTextISI={arrowTextISI}
				desktopSlides={1}
				desktopToScroll={1}
				slider={slider}
				mobileSlides={1}
				mobileToScroll={1}
				centerMode={false}
				centerPadding={0}
				variableWidth={false}
				infinite={false}
				initialSlide={0}
				adaptiveHeight={false}
				dataOptions={dataOptions}
				classOptions={className}
				setTrackWProps={setTrackWProps}
			>
				{buildSlides()}
			</SlickCarousel>
		</>
	);
};
