import styled from "styled-components";
import { device } from "../components/Helpers";
import caret from "../images/caret-down.svg";

export const FormContainer = styled.div`
  margin-top: 20vh;
  padding-top: 2rem;
  width: 100%;
  background-color: white;

  &.unsubscribe {
    margin-top: 0;

    .form-group-md {
      margin: 2.5rem auto;
      padding: 0;

      .email-field {
        margin-bottom: 2.5rem;

        @media ${device.mobile} {
          input {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.prescribe-zip-form {
    padding-top: 0;
    margin-top: 0;
  }
  &.signup-form,
  &.contact-form {
    padding: 2.5rem 1.25rem 0 1.25rem !important;

    .form-group {
      @media ${device.mobile} {
        > div {
          margin-bottom: 2.5rem;
        }

        margin-bottom: 0;
      }

      .zip-code {
        margin: 1.25rem 0 0.25rem 0 !important;

        @media ${device.mobile} {
          margin-top: 1.25rem;
        }
      }
    }

    .disclaimer {
      margin-top: 0;
      padding-left: 1.68rem;
    }

    .checkbox-wrapper {
      &.no-padding {
        padding-bottom: 0;
      }
    }

    .privacy-terms-and-conditions {
      display: flex;
      flex-direction: row;
      #signup-privacy-policy-interstitial-modal-trigger,
      #signup-terms-and-conditions-interstitial-modal-trigger {
        color: ${(props) => props.theme.colors.brand_purple_00};
        font-weight: bold;
        padding-right: 1rem;
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0.75rem;
      }

      @media ${device.mobile} {
        flex-direction: column;
      }
    }
  }

  &.signup-form {
    .checkbox-wrapper {
      .checkbox-field-container {
        .checkbox-label-container {
          .strong {
            padding-left: 0;
            font-weight: bold;
          }
        }
      }
    }
  }

  hgroup {
    h1,
    h2 {
      margin-bottom: 1.25rem;
      line-height: 1.1;
    }
    h2 {
      margin-bottom: 2.5rem;
    }
  }

  .info {
    > p {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Form = styled.div`
  > div {
    margin: 0 auto;
    padding: 1.25rem 0;

    &.form-group {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin-bottom: 1.875rem;

      &:nth-of-type(2) {
        margin-bottom: 3.5rem;
      }

      &:last-of-type {
        margin-bottom: 1.25rem;
      }

      &:first-of-type {
        margin-top: 1.25rem;
      }

      &.extra-spacing {
        margin-bottom: 3.75rem;
      }

      @media ${device.mobile} {
        flex-direction: column;
      }

      > div {
        width: 100%;
        max-width: 475px;

        label {
          font-size: 16px;
          display: block;

          @media ${device.mobile} {
            /* margin-bottom: 12px; */
          }
        }
        .error {
          font-size: 0.75rem;
          color: red;
        }
        &:first-of-type {
          padding-right: 1rem;

          @media ${device.mobile} {
            padding-right: 0;
          }
        }
        @media ${device.mobile} {
          max-width: none;
        }
      }
    }

    &.single-field {
      padding-bottom: 1.875rem;
      label {
        font-size: 16px;
      }
      input {
        max-width: 818px;
      }
    }

    &.question-radio-group {
      padding: 0;
      margin-bottom: 3.75rem;

      .question {
        font-size: 16px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.brand_orange_00};
        margin-bottom: 1.25rem;
      }
    }

    &.single-field-checkbox {
      padding: 0 0 1.25rem 0;
    }

    &.form-group-md {
      max-width: 350px;
      text-align: left;

      .submit-button {
        a.btn-1 {
          max-width: fit-content;
          margin: 1rem auto;
        }
      }
    }

    &.submit-button {
      max-width: 200px;
      margin-top: 0.55rem;
      margin-left: 0 !important;
      margin-bottom: 7.5rem;
      a.btn-1 {
        max-width: fit-content;
      }
    }

    &.email-container {
      width: 100%;
    }

    .error {
      font-size: 0.75rem;
      color: red;
    }

    &.disclaimer {
      padding: 0 1.75rem;
      > p {
        margin: 0;
      }
    }

    &.privacy-terms-and-conditions {
      > a {
        font-weight: bold;
        text-decoration: underline;

        &:first-of-type {
          padding-right: 2rem;
        }
      }
    }
  }
`;

export const Input = styled.input`
  font-size: 16px !important;
  margin: 1.25rem 0 0.25rem 0;
  font-size: 1rem;
  width: 100%;
  max-width: 550px;
  height: 2.5rem;
  padding: 0 0.8rem;
  border-radius: 0.5rem;
  border: 2px solid
    ${(props) => (props.error ? "red" : props.theme.colors.brand_purple_00)};

  &.zip-code {
    width: 22rem;
    height: 2.5rem;
    max-width: 345px;
    /* margin-top:0;
    padding-top:0; */
    @media ${device.mobile} {
      max-width: none;
    }
  }

  @media ${device.mobile} {
    max-width: none;
    margin-top: 12px;
  }
`;

export const Selector = styled.select`
  font-size: 16px !important;
  margin: 1.25rem 0 0.25rem 0;
  font-size: 1rem;
  width: 100%;
  max-width: 550px;
  height: 2.5rem;
  padding: 0 0.8rem;
  border-radius: 0.5rem;
  border: 2px solid
    ${(props) => (props.error ? props.theme.colors.brand_purple_00 : "red")};
  background: url(${caret}) no-repeat right white;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  @media ${device.mobile} {
    margin-top: 12px;
  }

  option {
    line-height: 2rem;
  }

  &.state-picker {
    max-width: 250px;
  }

  @media ${device.mobile} {
    max-width: none;
  }
  &::-ms-expand {
    display: none;
  }
`;

export const RadioButton = styled.div`
  margin-bottom: 3.75rem;

  .radio-button-container {
    display: flex;
    padding-top: 5px;
    input[type="radio"] {
      width: 0.688rem;
      height: 0.688rem;
      background-color: white;
      font-size: 1rem;
      border-radius: 50%;
      vertical-align: 0;
      border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
      &:checked {
        background-color: ${(props) => props.theme.colors.brand_purple_00};
      }
    }

    @media ${device.mobile} {
      padding-top: 6px;
    }
  }

  .label-container {
    display: flex;
    label {
      font-size: 16px;
      padding: 0 2.5rem 0 0.5rem;
    }
  }

  div.flow-direction {
    display: ${(props) => (props.stackDirection ? "block" : "inline-block")};
    margin-bottom: 0.75rem;

    .innerWrap {
      display: flex;
      flex-direction: row;
    }

    @media ${device.mobile} {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const CheckButton = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;

  .checkbox-field-container,
  .checkbox-label-container {
    display: flex;
    flex-direction: column;
  }

  .checkbox-field-container {
    padding-top: 0.4rem;

    @media ${device.mobile} {
      padding-top: 0.5rem;
    }
  }

  .checkbox-label-container {
    font-size: 16px;
    line-height: 19px;
    padding-left: ${(props) => (props.hang ? "0" : ".5rem")};
    .error {
      padding-left: ${(props) => (props.hang ? "1rem" : ".5rem")};
    }
  }
  input[type="checkbox"] {
    width: 0.688rem;
    height: 0.688rem;
    background-color: white;
    border-radius: 50%;
    vertical-align: 0;
    border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &:checked {
      background-color: ${(props) => props.theme.colors.brand_purple_00};
    }
  }

  label {
    font-size: 16px;
    padding-left: 0.5rem;

    &.strong {
      font-weight: bold;
    }
  }

  .error {
    font-size: 0.75rem;
    color: red;
    padding-left: 1.6rem;
  }
`;

export const ActionButton = styled.div`
  a {
    color: ${(props) => props.theme.colors.brand_purple_00};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.btn-1 {
      font-size: 16px;
      color: white;
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      font-weight: bold;
      line-height: 2rem;
      height: 2rem;
      padding: 0 2rem 0 1rem;
      border-radius: 0.35rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none;
      max-width: 200px;

      & > img {
        height: 100%;
        width: 0.5rem;
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 0.75rem;
      }
    }
  }
`;
