/**
 * 1. Create your component's style in `src/styles`
 * 2. Export your component's style into `src/styles/index.js`
 * 3. Create your code component in `src/components`
 * 4. Export your component into `src/components/index.js`
 */

import styled from "styled-components";

export const SampleComponentWrapper = styled.div`

`;
