import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { interaction, createMarkup } from "./Helpers";
import { IsiStyle } from "../styles";

export const Isi = ({ id, isiSnippet }) => {
  const [isiSnippetLoaded, setIsiSnippetLoad] = useState(0);
  const [isiPageLoad, setIsiPageLoad] = useState(1);


  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "isi" } } }
      ) {
        nodes {
          frontmatter {
            markdown_id
          }
          html
        }
      }
    }
    `);
    
  isiSnippet = Array.isArray(isiSnippet) ? isiSnippet : [];
  
  useEffect(() => {
    const isiSnippetController = (snippets) => {
      setIsiSnippetLoad(1);
      const isiTriggers = document.querySelectorAll('[data-isi]');
      const closeModal = document.querySelectorAll('.close-button');
      const domSnippets = document.querySelectorAll('[data-isi-snippet]');
      // domSnippets = Array.isArray(domSnippets) ? domSnippets : [];

      const isiToggle = (isiSnippets) => {
        Array.from(domSnippets).forEach((el) => {
          el.classList.remove('show');
        });

        Array.from(isiSnippets).forEach((isiSnippet) => {
          let snippit = document.querySelector('[data-isi-snippet="' + isiSnippet + '"]');
          if (snippit) {
            snippit.classList.add('show');
          }
        });
      };

      const setIsiSnippet = (isiTrigger) => {
        let snippetObj = [];    
        snippets = isiTrigger.dataset.isi.split(",");
        Array.from(snippets).forEach((snippet) => { snippetObj.push(snippet.trim()); });    
        isiToggle(snippetObj);
      };

      if (isiSnippetLoaded) {
        Array.from(isiTriggers).forEach((isiTrigger) => {
          isiTrigger.addEventListener(`click`, (e) => {
            setIsiSnippet(isiTrigger);
          });
        });

        Array.from(closeModal).forEach((el) => {
          el.addEventListener(interaction, (e) => {
            isiToggle(isiSnippet);
          });
        });

        if (isiPageLoad) {
          isiToggle(isiSnippet);
          setIsiPageLoad(false);
        }
      }
    };

    isiSnippetController(isiSnippet);
  });

  let mkHTML = "";

  mkHTML = data.allMarkdownRemark.nodes[0].html;

  return (
    <>
      <IsiStyle id={id} className="isi-style-container">
        <div
          id="important-safety-information"
          data-component="isi"
          className="wrapper"
          dangerouslySetInnerHTML={createMarkup(mkHTML)}
        />
      </IsiStyle>
    </>
  );
};
