import styled from "styled-components";
import { device } from "../components/Helpers";

export const TabbingThroughStyles = styled.div`
	left: 0;
	margin: 0 auto;
	max-width: 1170px!important;
	min-width: 300px!important;
	padding: 0 1.25rem;
	position: absolute;
	right: 0;
	top: 0;	
	width: 90%!important;
	z-index: 2;

	@media ${device.mobile} {
		margin-top: 7px;
		padding: 0;
		width: 86% !important;
		z-index: 2;
	}

	@media ${device.tablet} {
		margin-top: 0;
	}

	&.active > ul {
		&> button {
			@media ${device.mobile} {
				background-image: url(../images/back-arrow.svg);
			}
		}
		&> li {
			@media ${device.mobile} {
				display: block;
			}
		}
	}

	ul {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-left: 0;
		position: relative;

		@media ${device.mobile} {
			background-color: ${(props) => (props.theme.colors.brand_purple_01)};
			border-radius: 5px;
			flex-direction: column;
		}

		&.open {
			&> li {
				@media ${device.mobile} {
					display: block;
				}
			}
		}

		&> button {
			display: none;
			@media ${device.mobile} {
				background-color: transparent;
				background-image: url(../images/next-arrow.svg);
				background-repeat: no-repeat;
				border: 0;
				cursor: pointer;
				display: block;
				margin: 1.25rem;
				outline: 0;
				padding: 1rem;
				position: absolute;
				right: 0;
				top: 0;
				transform: rotate(90deg);
				z-index: 1;
			}

			&> span {
				display: none;
			}
		}

		li {
			padding 0;
			border-right: 2px solid #440099;
			border-bottom: 2px solid #440099;
			position: relative;
			background-color: #fafafa;
			
			@media (max-width: 1280px) {
				padding 8px 16px;
			}
			@media (max-width: 767px) {
				padding 6px 12px;
			}
			@media (max-width: 500px) {
				padding 4px 8px;
			}
			@media ${device.mobile} {
				background-color: transparent;
				border: 0;
				display: none;
				font-weight: bold;
				margin-bottom: 0;
			}
			&.active {
				color: #440099;
				@media ${device.mobile} {
					display: block;
				}
				&:before {
					content: '';
					display: block;
					width: 100%;
					border-top: 4px solid #e35205;
					margin-left: 0;
					top: 0;

					@media ${device.mobile} {
						content: none;
					}
				}
				button {
				color: #440099;
				font-weight:bold;
				}

				&:nth-child(1) {
					border-left: 2px solid #440099;	
				}
			}
			&:nth-child(1) {
				border-left: 2px solid #440099;
				border-bottom-left-radius: 5px;
				
				@media ${device.mobile} {
					display: none
					border-left: 0;
				}
			}
			&:nth-child(2) {
				border-left: 2px solid #440099;
				border-bottom-left-radius: 5px;
				@media ${device.mobile} {
					border-left: 0;
				}
			}
			&:nth-last-child(1) {
				border-bottom-right-radius: 5px;
			}
			&:before {
				content: '';
				display: none;
			}
			button {
				background: none;
				border: 0;
				outline: 0;
				font-family: ${(props) => (props.theme.fonts.main)};
				font-size: 16px;
				padding 10px 20px;
				cursor: pointer;
				@media (max-width: 1280px) {
				font-size: 14px;
				}
				@media (max-width: 767px) {
				font-size: 12px;
				}
				@media (max-width: 500px) {
				font-size: 10px;
				}
				@media ${device.mobile} {
					font-family: inherit;
					font-weight: bold;
					font-size: 1rem !important;
				}
			}
		}
	}
`;
