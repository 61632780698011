import styled from "styled-components";

export const P = styled.p`
  margin: ${(props) => (props.margin ? props.margin : 0)};
  font-size: ${(props) => {
    switch (props.size) {
      case "medium":
        return "1.125rem";
      case "small":
        return "1rem";
      case "xSmall":
        return ".875rem";
      default:
        return "1.125rem";
    }
  }};

  line-height: ${(props) => {
    switch (props.size) {
      case "medium":
        return "1.4375rem";
      case "small":
        return "1.375rem";
      case "xSmall":
        return "1.125rem";
      default:
        return "1.4375";
    }
  }};

  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : "none"};

  font-weight: ${(props) => {
    switch (props.weight) {
      case "normal":
        return 400;
      case "bold":
        return 700;
      default:
        return 400;
    }
  }};

  color: ${(props) => {
    switch (props.color) {
      case "blue":
        return props.theme.colors.main_1;
      case "green":
        return props.theme.colors.main_2;
      case "purple":
        return props.theme.colors.dark_1;
      case "red":
        return props.theme.colors.dark_2;
      case "white":
        return props.theme.colors.light_1;
      case "pink":
        return props.theme.colors.light_2;
      default:
        return props.theme.colors.main_1;
    }
  }};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

export const H1 = styled.h1`
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  color: ${(props) => {
    switch (props.color) {
      case "blue":
        return props.theme.colors.main_1;
      case "green":
        return props.theme.colors.main_2;
      case "purple":
        return props.theme.colors.dark_1;
      case "red":
        return props.theme.colors.dark_2;
      case "white":
        return props.theme.colors.light_1;
      case "pink":
        return props.theme.colors.light_2;
      default:
        return props.theme.colors.main_1;
    }
  }};
  font-weight: 400;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

export const H2 = styled.h2`
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  color: ${(props) => {
    switch (props.color) {
      case "blue":
        return props.theme.colors.main_1;
      case "green":
        return props.theme.colors.main_2;
      case "purple":
        return props.theme.colors.dark_1;
      case "red":
        return props.theme.colors.dark_2;
      case "white":
        return props.theme.colors.light_1;
      case "pink":
        return props.theme.colors.light_2;
      default:
        return props.theme.colors.main_1;
    }
  }};
  font-weight: 400;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
