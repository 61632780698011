import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const SeeMacInsideStyles = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2.75rem;
  @media ${device.mobile} {
    margin-top: 1.1667rem;
  }

  #instruction-container {
    margin-left: 0.45rem;
    margin-top: -2rem;


	@media ${device.mobile} {
		display: none;
	  }
    & > div {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      ol {
        margin-left: 2.5rem;

        @media ${device.mobile} {
          margin-left: 1.25rem;
        }
      }

      ol > li {
        margin-bottom: 0.75rem;

        @media ${device.mobile} {
          font-size: 1.33333rem;
        }

        &::marker {
          font-weight: bold;
        }
      }
    }

    & > h2 {
      margin-bottom: 1rem;
    }
  }

  #lung-purple {
    margin: -0.25rem -1rem;
    margin-right: 2.25rem;
    width: 15.5rem;

    @media ${device.mobile} {
      width: 13.5rem;
    }
  }
`;
