import { Link } from "gatsby";
import React from "react";
import { TableOfContentsStyle } from "../styles";

export const TableOfContents = () => {

  return(
    <>
      <TableOfContentsStyle className={`wrapper`}>
        <div className={`row`}>
          <Link data-gtm-event-label={`Patient Profiles`} className={`btn-4 gtm-click`} to={`/patient-profiles`}>Patient Profiles</Link>
          <Link data-gtm-event-label={`Why Treat Refractory MAC`} className={`btn-4 gtm-click`} to={`/why-treat-refractory-mac`}>Why Treat <br className={`mobileHide`}/>Refractory MAC</Link>
          <Link data-gtm-event-label={`Culture conversion`} className={`btn-4 gtm-click`} to={`/culture-conversion`}>Culture <br className={`mobileHide`}/>Conversion</Link>
        </div>
        <div className={`row`}>
          <Link data-gtm-event-label={`Target MAC`} className={`btn-4 gtm-click`} to={`/target-mac`}>Target MAC</Link>
          <Link data-gtm-event-label={`Dosing`} className={`btn-4 gtm-click`} to={`/dosing`}>Dosing</Link>
          <Link data-gtm-event-label={`Safety`} className={`btn-4 gtm-click`} to={`/safety`}>Safety</Link>
        </div>
        <div className={`row`}>
          <Link data-gtm-event-label={`Guidelines`} className={`btn-4 gtm-click`} to={`/guidelines`}>Guidelines</Link>
          <Link data-gtm-event-label={`Coverage`} className={`btn-4 gtm-click`} to={`/coverage`}>Coverage</Link>
          <Link data-gtm-event-label={`Summary`} className={`btn-4 gtm-click`} to={`/summary`}>Summary</Link>
        </div>
      </TableOfContentsStyle>
    </>
  );
};