import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailDosingStyles = styled.main`
position: relative;
    margin: 12.5rem auto 0;

  section {
    margin-top:0;
  }

  h1 {
    @media ${device.tablet} {
      line-height: 1.7rem;
      margin-bottom: 1rem !important;
    }
    @media ${device.mobile} {
      line-height: 1.91667rem;
      margin-bottom: 1rem !important;
    }
  }
  
  #nebulizer-system .list-container {
    align-items: center;
    margin: 1rem 0 2rem 3rem;
    margin-bottom: 10.5rem;
    flex-direction: row;

    @media ${device.mobile} {
      flex-direction: column;
      margin-left: 0;
      margin-bottom: 0;
    }

    & > div {
      /* width: 50%; */
      @media ${device.mobile} {
        width: unset;
      }
      @media ${device.ie11} {
        width: 50%;
      }
    }

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media ${device.mobile} {
        margin-bottom: 2.333rem;
      }
      h2 {
        margin-left: -5rem;
        margin-top: 1rem;

        @media ${device.tablet} {
          margin-left: -2.5rem;
        }

        @media ${device.mobile} {
          margin-left: 0;
        }
      }
    }

    & > div:last-of-type {
      position: relative;
      top: 3.5rem;

      [data-component="callout"] {
        display: flex;
        flex-direction: column;
        height: 7.5rem;
        align-items: center;
        justify-content: center;
        margin: 0 0rem 1rem 2.5rem;
        padding: 1.25rem 1rem 1.25rem 3.25rem;
        position: relative;
        width: 29.5rem;


        @media ${device.tablet} {
         width:25.5rem;
        }

        @media ${device.mobile} {
          width: 100%;
          height: 107px;
          margin-left: 0;
          margin-bottom: 6rem;
          padding: 1.5rem 1.25rem;
        }
        & > p {
          @media ${device.ie11} {
            width: 100%;
          }
          @media ${device.mobile} {
            line-height:1.6rem;
          }
        }
        &.minutes {
          @media ${device.mobile} {
            height: 13.6rem;
          }
          &::after {
            @media ${device.mobile} {
              bottom: 10.5rem;
              height: 76px;
              left:12rem;
            }
          }
        }
        &.bronchodilator {
          @media ${device.mobile} {
            height: 200px;
          }
        }
        &.minutes:after {
          background-image: url("../images/icon-14-mins.svg");
          background-size: 59px 64px;
          background-position: center;
        }

        &.bronchodilator:after {
          background-image: url("../images/icon-bronchodilator.svg");
          background-position: center;
          background-size: 41px 52px;
          bottom: 13.5rem;
          @media ${device.mobile} {
            bottom: 14rem;
            left:12rem;
          }
        }

        &.home {
          margin-bottom:0;
        }

        &.home:after {
          background-image: url("../images/icon-dosing-home.svg");
          background-position: center;
          @media ${device.mobile} {
            bottom: 6.5rem;
            left:12rem;
          }
        }

        &:after {
          content: "";
          border-bottom: 0.5rem solid ${(props) => props.theme.colors.white};
          border-top: 0.5rem solid ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.white};
          background-repeat: no-repeat;
          left: 0;
          margin-left: -2rem;
          margin-top: 1rem;
          padding: 2rem;
          position: absolute;
          top: 0;

          @media ${device.mobile} {
            top: unset;
            bottom: 10.5rem;
            left: 10.5rem;
            width: 79px;
            height: 68px;
          }
        }
      }
    }
  }

  #nebulizer-image {
    @media ${device.mobile} {
      width: 100%;
      margin-bottom: 4rem;
    }

    @media ${device.ie11} {
      width: 100%;
    }

    & > img {
      left: -1.75rem;
      position: relative;
      top: 2.5rem;
      width: 100%;

      @media ${device.mobile} {
        left: 0;
      }
    }

    & > p {
      padding-right: 2rem;
      position: relative;
      top: 3.75rem;
      z-index: 1;
      @media ${device.mobile} {
        padding-right: 0;
      }
      & > br {
        @media ${device.mobile} {
          display: none;
        }
      }
    }
  }

  #next-link {
    width: 23.5rem;
    @media ${device.mobile} {
      width: 16rem;
    }
    & > a {
      padding: 0 10% 0 5%;
    }
  }

  .list-container {
    display: flex;
    margin: 2rem 0 0;
  }


  #how-to-use {
    margin-bottom: 0;
    h1 {
      margin-bottom: 1.875rem;
      @media ${device.mobile} {
        line-height: 2.25rem;
      }
    }
  }

  .video-details {
    .bullet-list {
      .list-title {
        @media ${device.mobile} {
          padding-bottom: 1.6667rem;
        }
      }
      ul {
        @media ${device.mobile} {
          margin-bottom: 2.5rem;
        }
      }
    }
  }

  #video-player-container {
    iframe {
      @media ${device.mobile} {
        height: auto;
      }
    }

    @media ${device.mobile} {
      max-width: 380px;
      width: 100%;
    }
  }

  .footnote{
    margin-top: -1rem;
    margin-bottom: 2.688rem;
    @media ${device.tablet} {
      margin-top:0;
      margin-bottom:1rem;
    }
    @media ${device.mobile} {
      margin-top:0;
      margin-bottom:1rem;
    }
  }
  .transcript{
    margin-top:1.875rem;
    .header {
      @media ${device.mobile} {
        margin-top:2.5rem;
      }
    }
  }
`;
