import React from "react";
import { createMarkup } from "./Helpers";
import { ReferencesContainer, ReferencesHeader } from "../styles";

export const References = ({ references, className, markdownID }) => {
  const isMore = () => {
    if (references.length > 1) {
      return true;
    }
  };

  return (
    <>
      <ReferencesContainer id={"references"} className={className}>
        <ReferencesHeader>
          {isMore() ? "References:" : "Reference:"}
        </ReferencesHeader>
        <div className="references">
          {references.map((reference, i) => {
            return (
              <span key={`reference-${i}`}>
                <b>{i + 1}.</b>{" "}
                <span dangerouslySetInnerHTML={createMarkup(reference)} />{" "}
              </span>
            );
          })}
        </div>
        <p id={`pi-link`} className={(markdownID === `home-content`) ? `home-pi-link` : ``}>Please see full <a href={`https://www.arikayce.com/pdf/full-prescribing-information.pdf`} target={`_blank`} rel={`noreferrer`}>Prescribing Information</a>.</p>
      </ReferencesContainer>
    </>
  );
};
