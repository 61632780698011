import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const PageUnsubscribeStyles = styled.main`
  margin-top: 4rem !important;
  padding-bottom: 7.5rem !important;
  @media ${device.mobile} {
    padding-top: 8rem !important;
    padding-bottom: 4rem !important;
  }
  .md-container {
    padding-top: 2.5rem;
    @media ${device.mobile} {
      padding-top: 0rem !important;
    }
  }
  .container {
    text-align: center;

    h1,
    h2,
    p {
      margin-bottom: 2.5rem;
    }
  }
`;
