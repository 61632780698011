import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const SecondaryNavControlStyling = styled.div`
    margin-right: -.25rem;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;

    @media ${device.desktop} {
        margin-right: 0;
        transform: scale(91%) translate(3.25rem,5.25rem);
    }

    @media ${device.tablet} {
        top: 3rem;
        width: 100%;
    }

    @media ${device.mobile}{
        top: 13.5rem; 
        width: 100%;
    }

    @media only screen and (min-width: 1190px) and (max-width: 1199px) {
        transform: scale(91%) translate(4.75rem,-2.75rem); 
    }

    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
        transform: scale(91%) translate(5em,-2.75rem);
    }

    @media only screen and (min-width: 1281px) {
        transform: scale(91%) translate(4rem,.65rem);
    }

    &> a {
        background-color: #fff;
        color: ${props => props.theme.colors.brand_purple_00};
        display: block;
        font-size: .75rem !important;
        font-weight: bold;
        height: 6.25rem;
        line-height: 1rem;
        padding: 3rem .25rem .5rem .25rem;
        position: absolute;
        text-align: center;
        top: -7px;
        width: 5.625rem;
        
        &.disabled {
            cursor: not-allowed;
            opacity: .5;
        }

        &:hover {
            text-decoration: none;
        }

        @media ${device.mobile} {
            align-items: center;
            display: flex;
            font-size: 1.05rem !important;
            line-height: 1.25rem;
            padding: 0 2.5rem;
            width: 44.5%;
            justify-content: center;
        }

        @media ${device.tablet} {
            font-size: 1.1rem !important;
            width: 44.5%;
        }

        @media screen and (min-width: 991px) and (max-width: 1189px) {
            padding-top: 3rem !important;
        }

        @media screen and (max-width: 991px) {
            width: 44.5%;
        }
    }

    .nextNavBtn {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        padding-left:.75rem;
        padding-right:.75rem;
        padding-top: 3rem;
        right: 6.25rem;
        
        &::before {
            content: url("../images/next-arrow.svg");
            width:90px;
            position: absolute;
            top: .75rem;
            left: 0;
            @media ${device.desktop_toMobile}{
                left: auto;
                right: 0;
            }
            @media ${device.mobile}{
                width: 30px;
                top: 1rem;
            }
        }

        @media ${device.desktop_xlgmx}{
            right: 10.25rem;
            top: 39px;
        }
        @media ${device.desktop_toMobile}{
            right: 5%;
            //z-index: 100000;
            height: 3rem;
            font-size: 1rem;
        }
        @media ${device.tablet} {
            padding-top:1rem;
            padding-left: 2rem;
            padding-right: 2rem;
      }
        @media ${device.mobile}{
            height: 4rem;
            padding-top:0;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media screen and (min-width: 992px) and (max-width: 1189px) {
            font-size: .75rem;
            height: 6.25rem;
            left: unset;
            margin-right: 6rem;
            padding-top: unset;
            top: -5.5rem;
        }

        
    }

    .prevNavBtn {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        padding-left: .75rem;
        padding-right: .75rem;
        right: 12.5rem;

        &::before{
            content: url("../images/back-arrow.svg");
            width:90px;
            position: absolute;
            top: .75rem;
            left: 0;
            @media ${device.mobile}{
                width: 30px;
                top: 1rem;
            }
        }

        @media ${device.desktop_xlgmx}{
            right: 16.5rem;
            top: 39px;
        }

        @media ${device.desktop_toMobile}{
            left: 5%;
            height: 3rem;
            font-size: 1rem;
        }
        @media ${device.tablet} {
            padding-top: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
            width: 44.5%;
        }
        @media screen and (min-width: 992px) and (max-width: 1189px) {
            font-size: .75rem;
            height: 6.25rem;
            left: unset;
            margin-right: -1rem;
            padding-top: unset;
            top: -5.5rem;
        }
        
        @media ${device.mobile}{
            height: 4rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
`;
