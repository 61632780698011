import React, { useEffect } from "react";

export const Injector = ({ container, content }) => {
  const manipulateDOM = () => {
    const _container = document.getElementById(container);
    const _content = document.getElementById(`${container}-content`);
	if (_container && _content) {
		_container.appendChild(_content);
		let DOMContentLoaded = new Event('DOMContentLoaded');
		document.dispatchEvent(DOMContentLoaded);
	}
  };

  useEffect(manipulateDOM);

  return <div id={`${container}-content`}>{content}</div>;
};
