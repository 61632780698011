import React, { useState, useEffect } from "react";
import { handleLock } from "./utils";
import { interaction, isWindow } from "../Helpers";
import {
  ModalContainer,
  ModalPopup,
  ModalContentWrap,
  ModalContent,
} from "../../styles";

export const LandingModal = ({ markdownID, initialAudioEngagement, setInitialAudioEngagement }) => {
  const [open, setOpen] = useState("true");

  const handleClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setOpen(!open);
    handleLock(open);
    if (isWindow) {
      window.sessionStorage.setItem(`hcp`, true);
    }
    document.querySelector(`html`).classList.remove(`locked`);
  };

  useEffect(() => {
    const sessionAutoplayState = window.sessionStorage.getItem(`autoplay`);

    if (sessionAutoplayState === `false` || sessionAutoplayState === null) {      
      let modalInitiator = ``;

      if (isWindow) {
        
        setTimeout(() => {
          modalInitiator = document.querySelector(`.are-you-hcp-modal-buttons > span`);

          const initiatingAutoplay = (setting) => {
            window.sessionStorage.setItem(`audio-init`, `true`);
			let _audioPlayer = document.querySelector('.audio-player > audio');
            let _pauseBtn = document.querySelector('.pause');
            let _playBtn = document.querySelector('.play');
            let _replayBtn = document.querySelector('.replay');

            if (setting === `silent`) {
              const ctx = new (window.AudioContext || window.webkitAudioContext)();
              const osc = ctx.createOscillator();

              osc.connect(ctx.destination);
              osc.start(0);
              osc.stop(.000025);
            }
            else {
              setTimeout(() => {
                _audioPlayer.play();
                _pauseBtn.classList.remove('hidden');
                _playBtn.classList.add('hidden');
                _replayBtn.classList.add('active');
              }, 50);
            }
          };

          modalInitiator.addEventListener(interaction, () => {
            initiatingAutoplay(`silent`);
          });
          
          modalInitiator.removeEventListener(interaction, [], false);
        }, 100);
      }
    }

    if (isWindow) {
      const _areYouHCPModal = document.getElementById(`are-you-hcp-modal`);

      if (window.sessionStorage.getItem(`hcp`) === null && window.sessionStorage.getItem(`hcp`) !== `true`) {
        window.sessionStorage.setItem(`hcp`, false);
        document.querySelector(`html`).classList.add(`locked`);
      }

      setTimeout(() => {
        if (window.sessionStorage.getItem(`hcp`) !== `true`) {
          if (_areYouHCPModal !== null) {
            _areYouHCPModal.classList.remove(`hidden`);
            document.querySelector(`html`).classList.add(`locked`);
          }
        }
      }, 500);
    }
  }, [initialAudioEngagement, setInitialAudioEngagement]);

  return (
    <>
      <ModalContainer id={`are-you-hcp-modal`} className={(markdownID === `ed-why-treat-mac`) ? `unbranded hidden` : `hidden`}>
        <div id={`are-you-hcp-trigger`} className={`modal-trigger`} />
        <ModalPopup isOpen={open} className={open ? `locked landing-popup` : `landing-popup`}>
          <ModalContentWrap isOpen={open} className={`landing-content-wrap`}>
            <ModalContent>
              <div className={`landing-modal-container`}>
                <div className={`modal-header`}>
                  <h1>Are you a healthcare professional?</h1>
                </div>
                <div className={`copy`}>
                  <p>
                    If you are not, please click “No” and you will be
                    redirected to our patient site, ARIKAYCE.com.
                  </p>
                </div>
                <div className={`are-you-hcp-modal-buttons`}>
                  <span
                    tabIndex={0}
                    aria-label={`Yes`}
                    role={`button`}
                    onClick={handleClick}
                    onKeyPress={handleClick}
                    className={`btn-1  gtm-cta`}
                    data-gtm-event-label={`yes`}
                  >
                    <span className={`label`}>Yes</span>
                    {
                      (markdownID !== `ed-why-treat-mac`) ? <img src={`/images/caret-white.svg`} alt={`White button caret.`} /> : null
                    }
                  </span>
                  <a
                    href={`https://www.arikayce.com/`}
                    className={`btn-1  gtm-cta`}
                    data-gtm-event-label={`no`}
                  >
                    <span className={`label`}>No</span>
                    {
                      (markdownID !== `ed-why-treat-mac`) ? <img src={`/images/caret-white.svg`} alt={`White button caret.`} /> : null
                    }
                  </a>
                </div>
              </div>
            </ModalContent>
          </ModalContentWrap>
        </ModalPopup>
      </ModalContainer>
    </>
  )
};
