import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const FootnotesContainer = styled.div`
  margin: 0 0 3rem 0;

  @media ${device.mobile} {
    margin: -3rem 0 0 0;
  }

  &.bottom-footnotes {
    margin-top: 4rem !important;

    @media ${device.mobile} {
      margin-top: 12rem !important;
    }
  }

  .footnotesAccordion {
    position: relative;

    .footnotes {
      background-color: ${(props) => props.theme.colors.white};
      border-bottom: ${(props) => props.isOpen ? ".5px solid" + props.theme.colors.brand_gray_02 : "none"};
      border-top: .5px solid ${(props) => props.theme.colors.brand_gray_02};
      font-size: .75rem;
      line-height: .875rem;
      margin-top: 1rem;
      max-height: ${(props) => (props.isOpen ? "52rem" : 0)};
      overflow: hidden;
      padding: ${(props) => (props.isOpen ? "1rem" : 0)} 0;
      transition: all 0.2s ease-out;

      @media ${device.mobile} {
        padding: ${(props) => props.isOpen ? "2.5rem 0 1rem 0" : "2.5rem 0 0 0"};
      }

      p {
        padding-left: .55rem;
        position: relative;
        margin-bottom: 0.438rem;
        line-height: 0.875rem;

        @media ${device.mobile} {
          font-size: 1rem;
          line-height: 1rem;
          margin-bottom: .5rem;
          padding-left: .75rem;
        }

        &> span {
          left: 0;
          margin-left: -.25rem;
          position: absolute;
          text-align: right;
          width: .75rem;

          @media ${device.mobile} {
            font-size: .725rem;
            margin-left: 0;
          }

          &> sup {
            @media ${device.mobile} {
              font-size: inherit !important;
            }
          }
        }

        a {
          font-weight: bold;
          text-decoration: underline;
        }

        sup {
          font-size: 8px !important;
        }
      }
      
    }

    .trigger {
      ${(props) => (props.isOpen ? "padding: 0" : "padding-left: 0")};
      background-color: ${(props) => props.theme.colors.brand_orange_02 };
      border-radius: 100%;
      color: white;
      cursor: pointer;
      font-size: 2rem;
      font-weight: bold;
      height: 1.25rem;
      left: 49.35%;
      line-height: .8rem;
      position: absolute;
      text-align: center;
      top: -0.6rem;
      width: 1.25rem;

      @media ${device.mobile} {
         font-size: 2rem;
         height: 1.5rem;
         left: 47.35%;
         line-height: 1.15rem;
         width: 1.5rem;
      }
    }
  }
`;

export const FootnotesHeader = styled.div`
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.brand_orange_00};

  @media ${device.mobile} {
    font-size: 1rem;
    margin-bottom: 1.75rem;
  }
`;
