import React, { useState, useEffect } from "react";
import { ImageZoom, List } from "../Partials";
import { AudioTranscript } from "../AudioTranscript";
import {
	ModalContainer,
	ModalPopup,
	ModalContentWrap,
	ModalContent,
	ModalClose,
} from "../../styles";
import { createMarkup } from "../Helpers";
import { ModalImages, vimeoPlayerHandler, handleLock } from "./utils";
import { PatientScanImages } from "./utils/fetchScanImages";
import { MobileScanModal } from "./MobileScanModal";
import { Link } from "gatsby";

const isWindow = typeof window !== `undefined`;

export const PatientModal = ({
	modalName,
	setModalState,
	isiSnippet,
	patientID,
	patientName,
	patientInfo,
	patientImage,
	modalOpen,
	gtmclose,
	trackWProps,
	setTrackWProps,
	transcriptState,
	setTranscriptState,
	closeClick,
	zoom,
	setZoom,
	videoProfileTranscript,
}) => {
	const [showVideo, setVideo] = useState(false);

	const resetVimeoVideo = () => {
		let iframe = document.querySelectorAll('iframe');

		Array.from(iframe).forEach((el) => {
			let originalSrc = el.src;
			el.src = originalSrc;
		});
	}

	const handleClick = () => {
		resetVimeoVideo();
		setModalState(null);
		setVideo(false);
		setTrackWProps("default");
		setZoom(false);
		if (modalOpen !== null) {
			handleLock(modalOpen);
		} else {
			handleLock(false);
		}
	};

	const closegtm = gtmclose;

	const handleVideo = () => {
		setVideo(!showVideo);
		setTrackWProps(trackWProps.trackID + "Video");
		if (modalOpen !== null) {
			setTimeout(() => {
				handleLock(false);
			}, 100);
		}
	};

	const buildHistory = (history) => {
		return history.map(({ title, list }, i) => {
			return (
				<List
					key={`${i}-${title}-${patientName}-list-container`}
					title={title}
					items={list}
				/>
			);
		});
	};

	const half = Math.ceil(patientInfo.history.length / 2);

	const buildScans = () => {
		if (isWindow) {
			if (window.innerWidth > 480) {
				return patientInfo.scans.map(
					({ key, title, img, gtmlabel }, i) => {
						return (
							<div key={`${i}-${patientName}-scan-set`}>
								<div
									className={"scan-title"}
									dangerouslySetInnerHTML={createMarkup(
										title
									)}
								/>
								<ImageZoom
									img={PatientScanImages(
										`${patientName} ${key}`
									)}
									alt={title}
									gtmlabel={gtmlabel}
									zoom={zoom}
									setZoom={setZoom}
								/>
							</div>
						);
					}
				);
			} else if (window.innerWidth <= 480) {
				return patientInfo.scans.map(({ key, title, img }, i) => {
					return (
						<div key={`${i}-${patientName}-scan-set`}>
							<div
								className={"scan-title"}
								dangerouslySetInnerHTML={createMarkup(title)}
							/>
							<MobileScanModal
								modalName={`${patientName}-${key}-mobile-modal`}
								trigger={
									<img
										src={PatientScanImages(
											`${patientName} ${key}`
										)}
										alt={title}
									/>
								}
								image={PatientScanImages(
									`${patientName} ${key}`
								)}
							/>
						</div>
					);
				});
			}
		}
	};

	const buildButton = (seeStory, cta, gtmlabel) => {
		if (seeStory) {
			return (
				<Link
					to={`#`}
					onClick={(e) => {
						e.preventDefault();
					}}
					className={`btn-2 tabletHide gtm-cta`}
					data-gtm-event-label={gtmlabel}
				>
					<span>{cta}</span>
				</Link>
			);
		}
	};

	useEffect(() => {
		if (patientInfo.seeStory.isStory && window.innerWidth > 769) {
			vimeoPlayerHandler(
				`vimeo-player-${patientName}-container`,
				patientInfo.video,
				false,
				false,
				640
			);
		} else if (patientInfo.seeStory.isStory && window.innerWidth < 769) {
			vimeoPlayerHandler(
				`vimeo-player-${patientName}-mobile-container`,
				patientInfo.video,
				false,
				false,
				300
			);
		}
	}, [patientInfo.seeStory.isStory, patientInfo.video, patientName]);

	return (
		<ModalContainer id={`${modalName}-modal-container`}>
			<ModalPopup className="modal-content-wrap-wrap" isOpen={modalOpen}>
				<ModalContentWrap
					className="modal-content-wrap scrollable-parent"
					isOpen={modalOpen}
				>
					<ModalClose
						IconClose={ModalImages()}
						onClick={handleClick}
						onKeyDown={handleClick}
						className={`close-button gtm-click`}
						data-gtm-event-label={`${gtmclose}`}
					/>
					<ModalContent showVideo={showVideo}>
						<div className="modal-popup-content overflow">
							<div className="patient-info-container">
								<div className="mobile-show">
									<h2>{patientInfo.title}</h2>
									<div
										className="copy mobile-show"
										dangerouslySetInnerHTML={createMarkup(
											patientInfo.copy
										)}
									/>
								</div>
								<div className="img-column">
									<img
										src={patientImage}
										alt={`${modalName}`}
									/>
									{buildScans()}
								</div>
								<div className="info-column">
									<div className="desktoponly">
										<h2>{patientInfo.title}</h2>
										<div
											className="copy"
											dangerouslySetInnerHTML={createMarkup(
												patientInfo.copy
											)}
										/>
									</div>
									<div className="history-list">
										<div className="history-col">
											{buildHistory(
												patientInfo.history.slice(
													0,
													half
												)
											)}
										</div>
										<div className="history-col">
											{buildHistory(
												patientInfo.history.slice(-half)
											)}
										</div>
										<AudioTranscript
											className={"mobileonly"}
											id={
												`audio-transcript-` +
												trackWProps.trackID
											}
											transcriptState={transcriptState}
											setTranscriptState={
												setTranscriptState
											}
											transcriptCopy={
												trackWProps.transcript
											}
										/>
									</div>
									<div className="bottom-row">
										<div
											id="vimeo-player-mobile-wrapper"
											className="patient-info-video tabletShow"
										>
											<div className="patient-info-video-innerwrap">
												<div className="patient-info-video-header">
													{patientInfo.seeStory.cta}
												</div>
												<div
													id={`vimeo-player-${patientName}-mobile-container`}
													data-video-id={
														patientInfo.video
													}
													className="video-player"
												>
													<div
														className={`disclaimer`}
													>
														Individual results may
														vary.
													</div>
												</div>
												<AudioTranscript
													className={`patient-video-transcript`}
													transcriptState={
														transcriptState
													}
													setTranscriptState={
														setTranscriptState
													}
													transcriptCopy={
														videoProfileTranscript
													}
												/>
											</div>
										</div>
										{patientInfo.realPatient ? (
											<div className={`disclaimer`}>
												Individual results may vary.
											</div>
										) : null}
										<div
											role={`button`}
											tabIndex={0}
											onClick={handleVideo}
											onKeyDown={handleVideo}
											className="callout"
										>
											{buildButton(
												patientInfo.seeStory.isStory,
												patientInfo.seeStory.cta,
												patientInfo.seeStory.gtmlabel
											)}
										</div>
									</div>
								</div>
							</div>
							<div
								id="vimeo-player-wrapper"
								className="patient-info-video desktoponly"
							>
								<div className="patient-info-video-innerwrap">
									<div className="patient-info-video-header">
										{patientInfo.seeStory.cta}
									</div>
									<ModalClose
										IconClose={ModalImages()}
										onClick={() => {
											setVideo(false);
											closeClick(
												patientID + 1,
												patientName.toLowerCase()
											);
										}}
										onKeyDown={() => setVideo(false)}
										className={`gtm-cta modal-close`}
										data-gtm-event-label={`${closegtm}`}
										data-isi={isiSnippet}
									/>
									<div
										className="video-player"
										id={`vimeo-player-${patientName}-container`}
										data-video-id={patientInfo.video}
									></div>
								</div>
							</div>
							<AudioTranscript
								className={"desktoponly"}
								id={`audio-transcript-` + trackWProps.trackID}
								transcriptState={transcriptState}
								setTranscriptState={setTranscriptState}
								transcriptCopy={trackWProps.transcript}
							/>
						</div>
					</ModalContent>
				</ModalContentWrap>
			</ModalPopup>
		</ModalContainer>
	);
};
