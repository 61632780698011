import styled from "styled-components/macro";
import { fonts } from "../../static/fonts/";

export const ARStyles = styled.main`
  background-color: #440099;
  ${fonts}

  &>h1 {
    display: none;
  }
  & > a.btn-1 {
    display: none;
  }

  #purplevideocaption {
    color: #fff;
    font-size: 1.4rem;
  }

  #scrollIndication {
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    & > p {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .slick-slide {
    padding: 2px;
  }

  .slick-list {
  }

  .carousel-item,
  .carousel-body {
    img {
      width: 100%;
    }
    video {
      width: 100%;
    }
  }

  .carousel-body {
    background-color: #fff;
    padding: 1em 1em 3em;
    border-radius: 5px;
    margin-top: 1.5em;
    & > h3 {
      color: #440099;
      font-weight: bold;
      font-size: 1.3rem;
      line-height: 22px;
    }
    & > p {
      color: #262626;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    & > img {
      margin: 0 auto;
      display: block;
      margin-bottom: 2em;
    }
  }

  & > .footnote {
    display: none;
  }

  .carousel-footnote {
    font-size: 12px;
  }

  .carousel-item {
    padding-top: 1rem;
    & > h3 {
      color: #fff;
      font-weight: 300;
      font-size: 20px;
    }

    & > p {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 15px;
      strong {
        font-weight: bold;
      }
    }

    .whitevideowrapper {
      width: 100%;
      height: 20rem;
      overflow: hidden;

      video {
        object-fit: cover;
        width: 100%;
        top: 0;
        left: 0;
        margin-top: -8.5rem;
      }
    }
  }

  #screencancel {
    h3 {
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin-top: 2rem;
    }
    p {
      margin-bottom: 7rem;
    }
  }

  .ar-viewer {
    border: 0;
    background-image: url("/images/arexperience/opening.png");
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 101vh;
    top: 0;
    left: 0%;
    z-index: 100;
    background-color: #fff;
  }

  .ar-button-container {
    position: relative;
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 50px;
    line-height: 5px;
    background-color: rgba(100, 188, 95, 0.6);
    &.disabled {
      background-color: transparent;
      border: none;
      opacity: 0.5;
    }
  }

  .ar-popup-button {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border: 1px solid white;
    color: white;
    font-size: 1rem;
    margin: 1px 0 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    position: relative;
    top: 4px;
    left: 4px;
    font-size: 1.3rem;
    &.arrow {
      &:after {
        position: absolute;
        content: url("/images/arexperience/ar-arrow-btn.svg");
        width: 4px;
        height: 4px;
        right: 25px;
        top: 5px;
      }
      &.replaybtn:after {
        position: absolute;
        content: url("/images/arexperience/repeat.svg");
        width: 4px;
        height: 4px;
        right: 25px;
        top: 3px;
      }
    }
    &#ar-start {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  .content-wrapper {
    h3 {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }

    p {
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 1.7rem;

      &.footnote {
        font-size: 1.25rem;
        position: absolute;
        font-weight: 300;
      }
    }

    h3 {
      margin-bottom: 1.5rem;
    }

    p {
      margin-bottom: 4rem;
    }
  }

  #screen1,
  #screen2,
  #screencancel {
    z-index: 1000;
    color: #fff;
    position: fixed;
    width: 100vw;
    height: 101vh;
    left: 0%;
    top: 0%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 2em;

    .ar-button-container {
      margin-bottom: 6rem;
    }
    // if needed to toggle in/out
    &.ar-hide {
      display: none;
    }
  }

  .ar-popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    width: 100%;
    p {
      font-family: Montserrat;
      strong {
        font-family: Montserrat;
        font-weight: bold;
      }
    }
    // general popup styling
    .ar-popup,
    .ar-popup-arrow {
      position: relative;
      width: 100%;
      height: auto;
      border: 0;
      // background-image: url("/images/arexperience/ar-popup.svg");
      // background-size: cover;
      // background-repeat: no-repeat;
      // opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:before {
        content: "";
        background-image: url("/images/arexperience/ar-popup.svg");
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.7;
      }
      .ar-button-container {
        margin: 14px 0 !important;
      }

      &#ar-ref {
        .ar-popup-content p {
          text-align: center;
          font-family: Montserrat;
          color: #fff;
          strong {
            font-family: Montserrat;
            font-weight: bold;
          }
        }
        & > div {
          &:after {
            position: absolute;
            content: url("/images/arexperience/ar-right-bracket.svg");
            right: 2%;
            top: 30%;
          }

          &:before {
            position: absolute;
            content: url("/images/arexperience/ar-left-bracket.svg");
            left: 2%;
            top: 30%;
          }
        }
      }
      &.topalign {
        justify-content: flex-start;
        // padding-top: 1.5em;
      }
    }
    // this popup has the arrow beneath it
    .ar-popup-arrow {
      &-content {
        p {
          font-size: 1.4rem;
          margin-bottom: 0;
        }
        padding: 1rem 1.5rem;
      }

      &:after {
        position: absolute;
        content: url("/images/arexperience/ar-popup-arrow-down.png");
        transform: translateX(-50%) scale(0.5);
        left: 50%;
        bottom: -30px;
      }
    }
    // this popup has no arrow beneath it
    .ar-popup {
      &-content {
        height: 100%;
        text-align: center;
        padding: 1.5rem;
        z-index: 100;
        p {
          font-size: 1.4rem;
          margin-bottom: 0;
          text-align: left;
          color: #fff;
        }
        &.largebox {
          padding: 2rem 1.5rem;
        }
      }
    }
  }

  #screencancel {
    border: 0;
    background-image: url(/images/arexperience/opening.png);
    background-size: cover;
    .content-wrapper {
      padding-top: 5rem;
      .ar-button-container {
        margin-bottom: 2rem;
      }
    }
    &.hideAR {
      display: none !important;
    }
  }

  #screencancel {
    display: none;
  }

  #screen2 {
    .ar-button-container {
      margin-bottom: 2rem;
    }
  }

  section.container {
    color: white;
    padding-bottom: 1em;
    &.purplebg {
      background-color: #440099;
    }
  }

  #ar-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    padding: 0.6em 0.5em 0.6em 15px;
    z-index: 10000;
    a {
      color: #333132;
      border: 1px solid #333132;
      padding: 0.3em;
      font-weight: 700;
      font-size: 0.8em;
      display: flex;
      align-items: center;
      &.noborder {
        border: none;
      }
      &:first-child {
        border: none;
      }
    }
    #references-ar-content {
      border: 1px solid #333132;
      font-size: 1rem;
      a span {
        font-size: 1rem;
      }
      ol {
        font-weight: 100;
        padding-left: 1rem;
        li {
          font-weight: 100;
          &::marker {
            color: #262626;
          }
        }
      }
    }
  }

  .ar-hide {
    display: none;
  }

  #screen3 {
    position: absolute;
    bottom: 10em;
  }

  .box-lrg-ar,
  .box-med-ar {
    position: fixed;
    bottom: 1em;
    left: 0%;
    z-index: 10000;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .box-med-ar {
    .minSizeBox {
      min-height: 9em;
    }
  }

  // .box-lrg-ar {
  //   .ar-popup {
  //     // min-height: 17em;
  //   }
  // }

  .disease-picker {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    /* The container */
    .disease-picker-container {
      display: block;
      position: relative;
      padding-left: 29px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 0.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-align: left;
      font-family: Montserrat;
      font-weight: bold;
      color: #fff;
      font-size: 1rem;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark:after {
          display: block;
        }
      }

      &:hover input ~ .checkmark {
        background-color: #ccc;
      }

      &:checked ~ .checkmark {
        background-color: #2196f3;
      }
      /* Create a custom checkbox */
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid #fff;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    /* Style the checkmark/indicator */
    .disease-picker-container .checkmark:after {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: #fff;
    }
  }

  .screen9turn {
    &.hide {
      display: none;
    }
  }

  .screen10turn {
    &.hide {
      display: none;
    }
  }

  #ref {
    ol {
      font-weight: 100;

      li {
        font-weight: 100;
      }
    }
  }

  .modal-dialog {
    width: 90%;
  }

  .modal .modal-content {
    border: none;
    border-radius: 5px;

    h3 {
      color: blue;
    }
  }

  .modal .modal-content::before {
    background: none;
  }

  .modal .modal-content .modal-mod-container {
    padding: 1em;
  }

  .hide {
    display: none;
  }

  .purplevidwrapper {
    width: 100%;
    height: 24rem;
    overflow: hidden;
    position: relative;
    video {
      object-fit: cover;
      width: 100%;
      left: 0;
      position: absolute;
      top: -8rem;
    }
  }

  .carousel-control-prev-icon {
    background-image: url("/images/arexperience/back-btn.svg");
    position: absolute;
    top: 300px;
    width: 10px;
    height: 15px;
  }

  .carousel-control-next-icon {
    background-image: url("/images/arexperience/next-btn.svg");
    position: absolute;
    top: 300px;
    width: 10px;
    height: 15px;
    left: 0;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 4%;
    opacity: 1;
  }

  .purplemodals {
    h2 {
      color: #440099 !important;
      // font-family: 'Montserrat';
      font-weight: bold;
      font-size: 20px;
    }
    h3 {
      color: #440099 !important;
      // font-family: 'Montserrat';
      font-weight: bold;
      font-size: 20px;
    }
    p {
      // font-family: 'Montserrat';
      font-size: 16px;
    }
    .specialchar {
      position: relative;
      left: -8px;
      margin-right: -7px;
      font-family: "Helvetica Neue";
    }

    .specialchar2 {
      font-family: "Helvetica Neue";
    }

    ol {
      // margin: 0 0 1.5em;
      padding: 0;
      counter-reset: item;
      // padding-left: 23px;
    }

    ol > li {
      margin: 0;
      padding: 0 0 0 18px;
      text-indent: -1rem;
      list-style-type: none;
      counter-increment: item;
      font-size: 16px;
    }

    ol > li:before {
      display: inline-block;
      width: 1em;
      padding-right: 0.5em;
      font-weight: bold;
      text-align: right;
      content: counter(item) ".";
    }
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .whitebtnleftside {
    border-right: 2px solid #440099;
    padding: 8px 12px;
    font-weight: bold;
  }

  .whitebtnrightside {
    padding: 0 10px;
    display: flex;

    img {
      width: 12px;
    }
  }

  .white-btn {
    background-color: #fff;
    color: #440099;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    border-radius: 5px;
    padding: 0;
    margin-right: 2em;
    cursor: pointer;
    width: 18.75rem;
    margin-top: 4rem;
  }

  .whitebtnleftside {
    border-right: 2px solid #440099;
    padding: 8px 12px;
    font-weight: bold;
    font-size: 1.25rem;
  }

  .whitebtnrightside {
    padding: 0 10px;
    display: flex;

    img {
      width: 12px;
    }
  }

  .orangetxt {
    color: #e35205;
    text-decoration: underline;
  }

  #ar-viewer-wrapper {
    &.hideAR {
      display: none !important;
    }
    &.fade-out {
      animation: fadeOut ease 1.5s;
      -webkit-animation: fadeOut ease 1.5s;
      -moz-animation: fadeOut ease 1.5s;
      -o-animation: fadeOut ease 1.5s;
      -ms-animation: fadeOut ease 1.5s;
      animation-fill-mode: forwards;
      // display: none;
    }
  }

  .carousel-control-prev {
    display: none;
  }

  button.arbtn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    /* background-color: white; */
    opacity: 1;
  }

  button.arbtn2 {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 100;
    /* background-color: white; */
    opacity: 1;
  }

  .fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
  }

  .fade-in2 {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }

  .fade-out {
    animation: fadeOut ease 2s;
    -webkit-animation: fadeOut ease 2s;
    -moz-animation: fadeOut ease 2s;
    -o-animation: fadeOut ease 2s;
    -ms-animation: fadeOut ease 2s;
    // display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .whitetext {
    color: #000;
    text-align: center;
  }

  .percentage {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 1em;
  }

  .people-outline {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    img {
      height: 100px;
      margin: 0 0.2em;
      position: relative;
    }
    #personhidden {
      display: none;
    }
    & > div {
      position: absolute;
      & > div {
        display: flex;
      }
    }
  }

  .slide-int {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .footnote {
    font-size: 12px;
  }

  .carousel-indicators li {
    border: 1px solid #e35205;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    margin-right: 7px;
    margin-left: 7px;
  }

  .carousel-indicators .active {
    background-color: #e35205;
  }

  .videocaption {
    margin-bottom: 2em;
  }

  .personhidden {
    display: none;
  }

  #ref {
    h3 {
      font-family: "Montserrat";
      font-weight: bold;
      color: #098ec9 !important;
      font-size: 20px;
    }
    ol li {
      font-family: "Montserrat";
      font-size: 16px;
      &::marker {
        color: #262626;
      }
    }

    ol {
      padding-left: 23px;
    }
  }
  .text-secondary,
  .timeline-container .timeline .tm-milestone {
    color: #440099 !important;
  }
  .purpleunderline {
    text-decoration: underline;
    color: #440099;
  }

  .prompt-box-8w-iframe {
    background-color: #000;
  }

  .prompt-button-8w-iframe {
    background-color: #000;
    color: #006ee6;
  }

  .button-primary-8w-iframe {
    background-color: #000;
    color: #006ee6;
  }

  #secondaryText {
    visibility: hidden;
    &.fade-in {
      animation: fadeIn ease 2s;
      -webkit-animation: fadeIn ease 2s;
      -moz-animation: fadeIn ease 2s;
      -o-animation: fadeIn ease 2s;
      -ms-animation: fadeIn ease 2s;
    }
  }

  @media (max-width: 325px) {
    .content-wrapper h3 {
      font-size: 1.75rem;
    }
    .content-wrapper p,
    #ref h3 {
      font-size: 1rem;
    }
    .ar-popup-button#ar-start,
    .ar-popup-wrapper .ar-popup-content p,
    .content-wrapper p.footnote,
    #ref ol li {
      font-size: 0.75rem;
    }
    .disease-picker .disease-picker-container {
      font-size: 0.65rem;
    }
    .disease-picker > div {
      width: 50%;
    }
    .ar-popup-button {
      font-size: 0.7rem;
    }
    .purplevidwrapper video,
    #secondTime .ar-slider-container .carousel-item .whitevideowrapper video {
      top: -4rem;
    }
    #ar-header,
    #screen1,
    #screen2,
    #screencancel,
    .ar-viewer {
      left: -29px;
    }
  }
  @media (min-width: 401px) {
    #secondTime .ar-slider-container .carousel-item .whitevideowrapper {
      height: 17rem;
    }
  }

  @media (max-width: 360px) {
    .purplevidwrapper video {
      top: -7em;
    }
    #secondTime .ar-slider-container .carousel-item .whitevideowrapper video {
      margin-top: -6.5em;
    }
  }

  @media (max-width: 320px) {
    .purplevidwrapper video {
      top: -6em;
    }
    #secondTime .ar-slider-container .carousel-item .whitevideowrapper video {
      margin-top: -5.5em;
    }
  }

  .slick-disabled div.backward {
    position: relative;
    right: -14px;
  }

  div.forward {
    right: 6px;
  }

  .slick-next {
    right: -1.5rem;
  }

  .slick-prev {
    left: -1rem;
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .indication {
    background-color: rgba(255, 255, 255, 0.99);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;

    .content {
      height: 300px;
      text-align: left;
      & > div {
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 1rem;
        padding: 0 1rem;
        height: 250px;
      }
      h3 {
        text-transform: uppercase;
        color: #e35205;
        font-weight: bold;
        padding-left: 15px;
      }

      p {
        color: #262626;
      }
    }
  }

  .indication-button-container {
    // width: 55%;
    height: 50px;
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 112px;
    .indication-button {
      position: relative;
      width: auto;
      /* height: 50px; */
      /* line-height: 50px; */
      background-color: #e35205;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 8px 10px 8px 12px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 12px;
      }
    }
  }

  #references {
    display: none;
  }

  #secondIndication {
    display: none;
  }

  .slick-slider {
    display: none;
  }

  .headerrightbox {
    position: fixed;
    top: 4rem;
    right: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    background-color: #440099;
    &.showup {
      z-index: 1000;
    }
    & > div {
      font-size: 14px;
      display: block;
      border: 1px solid #fff;
      padding: 0.4em;
      border-radius: 3px;
      margin-bottom: 0.5em;
      span {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        &.specialchar {
          color: #000;
        }
      }
      ol {
        font-weight: 100;
        padding-left: 1rem;
        li {
          font-weight: 100;
        }
      }
    }
  }

  .specialchar {
    position: relative;
    left: -8px;
    margin-right: -7px;
    font-family: "Helvetica Neue";
  }

  .specialchar2 {
    font-family: "Helvetica Neue";
  }

  #convertbtn-content {
    .whitebtnleftside {
      font-size: 1.25rem;
      border-right: none;
      padding: 0;
    }
  }

  #convertbtnwrapper {
    visibility: hidden;
  }

  #convertbtn-content {
    .modal-popup-content {
      & > div {
        p {
          color: #505254;
          text-align: left;
          font-weight: 100;
        }
        h4 {
          color: #409;
          font-size: 18px;
          font-weight: 600;
          text-align: left;
        }
      }
    }
  }

  .modal-popup-content .modal-header .warnings-header {
    color: #098ec9 !important;
    font-weight: 600;
  }

  .modal-popup-content .modal-header footnotes-header {
    color: #440099;
    font-weight: 600;
  }

  .slick-dots {
    bottom: 10px !important;
  }

  #references-ar-content > div > div:last-child,
  #footnotes-ar-info-modal > div:last-child,
  #references2-ar-info-modal > div:last-child,
  #convertbtn-info-modal > div:last-child {
    background-color: rgba(0, 0, 0, 0.35);
  }

  #references-ar-info-modal > div > div > div > div:first-child {
    background-image: url(/images/arexperience/ref-close.svg);
  }

  .convertbtnheader {
    text-align: left;
  }
`;
