import { Link } from "gatsby";
import React, { useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import { ConvertedMarkdown } from "..";
import { AudioNavigatorStyles } from "../../styles";
import { isWindow } from "../Helpers";
import Player from "@vimeo/player";

/******************************/
/* start - audio control vars */
let _audioPlayer = ``;
let _pauseBtn = ``;
let _playBtn = ``;
let _replayBtn = ``;
let _muteBtn = ``;
/* end - audio control vars */
/****************************/

/***************************/
/* start - animation vars */
let _onBtn = ``;
let _offBtn = ``;
let _circle = ``;
/* end - animation vars */
/************************/

/*************************************/
/* start - AudioNavigation Component */
const AudioNavigator = ({ track, showISITrack, trackWProps, markdownID, setTranscriptState }) => {
	/*********************************************/
	/* start - Controller based off user control */
	const userControlled = (e, action) => {
		if (e !== null) {
			playHandler(e.target.classList[0], true);
		} else {
			playHandler(action);
		}
	};
	/* end - Controller based off user control */
	/*******************************************/

	/********************************************/
	/* start 
		- Controller for different player actions
		- (play, pause, reset, etc) 
	*/
	const audioController = (action) => {
		if (action === "play") {
			if (window.sessionStorage.getItem(`autoplay`) === `false`) {
				_audioPlayer.play();
				_playBtn.classList.add("hidden");
				_pauseBtn.classList.remove("hidden");
				_replayBtn.classList.add("active");
			} else {
				_audioPlayer.play();
				_pauseBtn.classList.remove("hidden");
				_playBtn.classList.add("hidden");
				_replayBtn.classList.add("active");
			}
			pauseVideos();
		} else if (action === "pause") {
			_audioPlayer.pause();
			_pauseBtn.classList.add("hidden");
			_playBtn.classList.remove("hidden");
			_replayBtn.classList.add("active");
		} else if (action === "mute") {
			if (_audioPlayer.muted === true) {
				_audioPlayer.muted = false;
			} else {
				_audioPlayer.muted = true;
			}
			_muteBtn.classList.toggle(`active`);
		} else if (action === "replay") {
			_audioPlayer.load();
			_audioPlayer.play();

			if (!_playBtn.classList.contains(`hidden`)) {
				_playBtn.classList.add(`hidden`);
			}

			if (_pauseBtn.classList.contains(`hidden`)) {
				_pauseBtn.classList.remove(`hidden`);
			}
		} else if (action === `reset`) {
			_audioPlayer.load();
			_playBtn.classList.remove("hidden");
			_pauseBtn.classList.add("hidden");
			_replayBtn.classList.remove("active");
		} else return;
	};
	/* end */
	/********/

	/********************************************/
	/* start 
		- Controller for different player 
		  animation states
		- (play, pause, reset, etc) 
	*/
	const animationController = (action, userTriggered) => {
		const startAnimation = () => {
			_circle.style.animationDuration = _audioPlayer.duration + `s`;
			_circle.classList.add("animate");

			if (_circle.style.animationPlayState === "paused") {
				_circle.style.animationPlayState = "running";
			}
		};

		const stopAnimation = () => {
			_circle.style.animationPlayState = "paused";
		};

		const restartAnimation = () => {
			_circle.classList.remove("animate");
			_circle.style.animationPlayState = "running";
			setTimeout(() => {
				_circle.classList.add("animate");
			}, 1000);
		};

		const resetAnimation = () => {
			_circle.classList.remove("animate");
			_circle.style.animationPlayState = "running";
		};

		if (action === `play`) {
			if (userTriggered) {
				startAnimation();
			} else {
				restartAnimation();
				setTimeout(startAnimation, 100);
			}
		} else if (action === `pause`) {
			stopAnimation();
		} else if (action === `replay`) {
			restartAnimation();
		} else if (action === `reset`) {
			resetAnimation();
		} else return;
	};
	/* end */
	/********/

	/***********************************************************/
	/* start - Player handler for animation and audio controls */
	const playHandler = (action, userTriggered) => {
		animationController(action, userTriggered);
		audioController(action);

		_audioPlayer.addEventListener(`ended`, () => {
			playHandler(`reset`);
		});
	};
	/* end - Player handler for animation and audio controls */
	/*********************************************************/

	/*************************************/
	/* start - Checks the autoplay state */
	const checkOnAutoplay = (evt, setting) => {
		if (isWindow) {
			if (setting === `on`) {
				window.sessionStorage.setItem(`autoplay`, `true`);
				_onBtn.classList.add(`active`);
				_offBtn.classList.remove(`active`);
				userControlled(null, `play`);
			} else if (setting === `disable`) {
				window.sessionStorage.setItem(`autoplay`, `false`);
				_offBtn.classList.add(`active`);
				_onBtn.classList.remove(`active`);
			} else {
				window.sessionStorage.setItem(`autoplay`, `false`);
				_offBtn.classList.add(`active`);
				_onBtn.classList.remove(`active`);
				playHandler(`reset`);
			}
		}
	};
	/* end - Checks the autoplay state */
	/***********************************/

	/****************************************/
	/* start - Handler for autoplay setting */
	const autoplayHandler = () => {
		if (isWindow) {
			const sessionAutoplayState =
				window.sessionStorage.getItem(`autoplay`);

			if (
				sessionAutoplayState === `false` ||
				sessionAutoplayState === null
			) {
				checkOnAutoplay();
				return;
			} else {
				checkOnAutoplay(``, `on`);
			}
		}
	};
	/* end - Handler for autoplay setting */
	/**************************************/

	/*****************************/
	/* start - pause vimeo video */
	const pauseVideos = () => {
		let iframes = document.querySelectorAll(".video-player iframe");
		if (iframes) {
			Array.from(iframes).forEach((iframe) => {
				if (iframe) {
					let player = new Player(iframe);
					player.pause();
					player.on(`play`, () => {
						playHandler(`pause`);
					});
				}
			});
		}
	};
	/* end - pause vimeo video */
	/***************************/

	/******************************/
	/* start - Handler for anchor 
			   linking to transcript 
			   boxes 
	*/
	const handleAnchorLink = (e, trackID) => {
		e.preventDefault(); console.log(trackID);
		const locateAudioTranscript = () => {

			const scrollToTranscript = (
				scrollableParent,
				transcriptDistanceFromTop
			) => {

				setTimeout(() => {
					let offSet = 0;
					let windoInnerHeight = window.innerHeight;
					let header = document.querySelector(`header`);
					let isiTray = document.querySelector(`#isi-tray`);
					offSet = windoInnerHeight > 700 ? (windoInnerHeight - (header ? header.offsetHeight : 0) - (isiTray? isiTray.offsetHeight : 0)) / 5 : 0;
					scrollableParent.scrollTo({
						top: transcriptDistanceFromTop - offSet,
						behavior: `smooth`,
					});
				}, 250);
			};
			const getScrollParent = (node) => {
				if (node == null) {
					return null;
				}
				// if (node.scrollHeight > node.clientHeight) {
				if (node.classList.contains("scrollable-parent")) {
					return node;
				} else {
					return getScrollParent(node.parentNode);
				}
			}
			let mainTranscripts = [
				'default',
				'defaultMobile',
				'slide2',
				'slide3',
				'slide3Mobile',
				'vivo',
				'systemic',
				'mic',
				'works',
				'detected',
				'adverse',
				'commercial',
				'government',
				'patient',
				'baseline',
				'standard',
				'time',
				'management',
				'adverse',
				'welcome-to-the-arikayce-story'
			];
			let idSuffix = mainTranscripts.includes(trackID) ? 'main' : trackID;
			let transcript = null;
			let transcriptUncut = null;
			let transcriptAll = document.querySelectorAll(`#audio-transcript-` + idSuffix);
			let transcriptAllUncut = document.querySelectorAll(`#audio-transcript-` + trackID);
			Array.from(transcriptAll).forEach((el) => {
				if (el.offsetTop > 0) {
					transcript = el;
				}
			});
			Array.from(transcriptAllUncut).forEach((el) => {
				if (el.offsetTop > 0) {
					transcriptUncut = el;
				}
			});
			transcript = transcriptUncut ? transcriptUncut : transcript;
			let transcriptDistanceFromTop = 0;
			if (transcript) {
				setTranscriptState(true);
				transcript.classList.add(`audiotrayopen`);
				let windowInnerHeight = window.innerHeight;
				let windowInnerWidth = window.innerWidth;
				let scrollableParent = getScrollParent(transcript.parentNode);
				let scrollTop = scrollableParent.scrollTop;
				let clientTop = scrollableParent.clientTop;
				let transcriptRect = transcript.getBoundingClientRect();
				let transcriptClientTop = transcriptRect.top;
				let top = transcriptClientTop + scrollTop - clientTop;
				transcriptDistanceFromTop = top - document.querySelector(`header`).offsetHeight;
				if (windowInnerHeight < 550 && windowInnerWidth > 767) {
					transcriptDistanceFromTop = transcriptDistanceFromTop * 1.3333;
				}
				scrollToTranscript(scrollableParent, transcriptDistanceFromTop);
			}

		};

		locateAudioTranscript();
	};
	/* end */
	/*******/

	/*********************/
	/* start - useEffect */
	useEffect(() => {
		_audioPlayer = document.querySelector(".audio-player > audio");
		_pauseBtn = document.querySelector(".pause");
		_playBtn = document.querySelector(".play");
		_replayBtn = document.querySelector(".replay");
		_muteBtn = document.querySelector(".mute");

		_onBtn = document.querySelector(".on");
		_offBtn = document.querySelector(".off");
		_circle = document.getElementById("circle");

		autoplayHandler();
	});
	/* end - useEffect */
	/*********************/

	return (
		<AudioNavigatorStyles
			className={
				track.title.indexOf("(VIDEO PLAYING)") >= 0
					? `wrapper audio-player-wrapper disabled`
					: `wrapper audio-player-wrapper`
			}
		>
			<AudioPlayer
				className={`audio-player`}
				autoPlayAfterSrcChange={false}
				src={isWindow ? window.location.origin + track.src : null}
				onPlay={(e) => e.preventDefault()}
			/>

			<div
				tabIndex={0}
				className={`pause hidden`}
				onClick={(e) => {
					userControlled(e, `pause`);
					e.preventDefault();
				}}
				onKeyPress={(e) =>
					e.key === "Enter" ? userControlled(e, `pause`) : false
				}
				role={`button`}
				aria-label={`pause`}
			></div>
			<div
				tabIndex={0}
				className={`play`}
				onClick={(e) => {
					userControlled(e, `play`);
					e.preventDefault();
				}}
				onKeyPress={(e) =>
					e.key === "Enter" ? userControlled(e, `play`) : false
				}
				role={`button`}
				aria-label={`play`}
			></div>
			<div
				tabIndex={0}
				className={`replay`}
				onClick={(e) => {
					userControlled(e, `replay`);
					e.preventDefault();
				}}
				onKeyPress={(e) =>
					e.key === "Enter" ? userControlled(e, `replay`) : false
				}
				role={`button`}
				aria-label={`replay`}
			></div>
			<div
				tabIndex={0}
				className={`mute`}
				onClick={(e) => {
					userControlled(e, `mute`);
				}}
				onKeyPress={(e) =>
					e.key === "Enter" ? userControlled(e, `mute`) : false
				}
				role={`button`}
				aria-label={`mute`}
			></div>

			<svg>
				{isWindow && window.screen.width > 767 ? (
					<circle id="circle" r="33" cx="45" cy="45"></circle>
				) : (
					<circle id="circle" r="26" cx="40" cy="40"></circle>
				)}
			</svg>

			<div id="audio-title">
				<ConvertedMarkdown mdhtml={track.title} />
			</div>

			<div className={`autoplay-box`}>
				<p>Autoplay audio</p>
				<div className={`btn-toggle`}>
					<button
						className={`on`}
						onClick={(e) => {
							checkOnAutoplay(e, `on`);
						}}
					>
						On
					</button>
					<button
						className={`off active`}
						onClick={(e) => {
							checkOnAutoplay(e, `disable`);
						}}
					>
						Off
					</button>
				</div>
				{showISITrack === false ? (
					<Link
						className={`transcript gtm-click`}
						data-gtm-event-label={`Audio Read Transcript`}
						onClick={(e) => {
							// testHandleAnchorLink(e, track.trackID);
							handleAnchorLink(e, track.trackID);
						}}
						to={`#`}
					>
						<p>Read transcript</p>
					</Link>
				) : null}
			</div>
		</AudioNavigatorStyles>
	);
};

export default AudioNavigator;
/* end - AudioNavigation Component */
/***********************************/
