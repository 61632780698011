import React from "react";
import { createMarkup } from "../../Helpers";

export const ISIToggle = ({ trigger, handler }) => {
  return (
    <>
      <div
        role={`none`}
        className={`ISI-toggle gtm-accordian`}
        onClick={handler}
        dangerouslySetInnerHTML={createMarkup(trigger)}
        data-gtm-event-label={`ISI - expand`}
      />
    </>
  );
};
