import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const ResourceItemStyles = styled.div`
  /* margin: 0 -5.5rem 0 4rem; */
  padding: 0 6rem;
  position: relative;
  width: 50%;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 3rem;
    margin-left: 1.25rem;
    margin-bottom: 3rem;
  }

  .resource-img-container {
    position: relative;
    .download-arrow {
      position: absolute;
      background-image: url("../images/icon-button-download.svg");
      background-repeat: no-repeat;
      background-size: contain;
      left: 0;
      margin-left: -3.65rem;
      margin-top: 5rem;
      padding: 1rem;
      top: 0;

      &.long {
        margin-left: -3.65rem;
        margin-top: 5rem;
      }

      &.short {
        margin-top: 2.2rem;
      }

      &.x-short {
        margin-top: 2.5rem;
      }
    }
  }

  & > a {
    display: block;
    font-size: 1.12rem;
    font-weight: bolder;
    padding-bottom: 0.75rem;
    position: relative;
    text-decoration: underline;
    @media ${device.mobile} {
      font-size: 1.3333rem;
    }
    &:first-child {
      padding-bottom: 0;
    }
  }
  & > a {
    img {
      left: 0;
      margin-left: -7.25rem;
      margin-top: 0rem;
      position: absolute;
      top: 0;
      width: 6rem;
    }
  }
  & > img {
    left: 0;
    margin-left: -1.25rem;
    margin-top: 0rem;
    position: absolute;
    top: 0;
    width: 6rem;
  }

  & > p {
    margin-bottom: 1rem;
  }

  & > table {
    margin: 0 0 0.75rem 0;
    padding: 0;
    width: 100%;

    tr:nth-of-type(2) td {
      height: 0.5rem;
    }

    td {
      & > a {
        font-weight: bold;
        text-decoration: underline;
      }

      & > a:hover {
        text-decoration: none;
      }
    }
  }
`;
