import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const IsiTrayStyle = styled.div`
    bottom: 0;
    height: 14.395rem;
    position: fixed;
    transition: height .2s 0s ease-out;
    width: 100%;
    z-index: 101;

    @media ${device.desktop_isi} {
        min-width: 300px !important;
        width: 100% !important;
        z-index: 999999;
    }

    @media ${device.mobile} {
        height: 8.5rem;
        left:0;
        width:100% !important;
    }

    &.open [data-component="isi"] {
        align-items: unset;
    }

    [data-component="isi"] {
        align-items: center;
        display: flex;
        padding: 0;
        width: 100%!important;
        z-index: 2;
    }

    [data-component="isi"] > div:nth-of-type(2) {
        overflow-y: hidden;
        padding-top: 3.5rem;
        padding-left: 1rem;
        padding-right: 1rem;

        @media ${device.mobile} {
            height: inherit;
            overflow-y: scroll;
            padding-top: 3.5rem;
        }
    }

    .isi-title-bar {
        background-color: ${(props) => props.theme.colors.brand_gray_01};
        left: 0;
        padding: 0 1.5rem;
        position: absolute;
        top: 0;
        width: 100%;

        #isi-tray-toggler-content {
          .ISI-inner-toggle {
              background-image: url(${(props) => props.Expander});
              background-position: center center;
              background-repeat: no-repeat;
              background-color: ${(props) => props.theme.colors.brand_gray_01};
              background-size: 50%;
              border: 0!important;
              cursor: pointer;
              height: auto;
              margin: .5rem;
              margin-right: 1.5rem;
              outline: 0!important;
              padding: 0.6rem;
              position: absolute;
              right: -.75rem;
              top: 0;
              transform: rotate(${(props) =>
                props.open ? "90deg" : "-90deg"});
              width: auto;
              z-index: 1;
              transition: ${(props) => props.theme.animations.all};
            }
        }

        &> h3 {
            color: ${(props) => props.theme.colors.brand_purple_00};
            line-height: 1rem !important;
            margin-bottom: .15rem !important;
            margin: 0;
            padding-bottom: 1rem;
            padding-top: 1rem;
            position: relative;
            text-transform: uppercase;
            font-size:1.25rem;
                
            &.opened {
                padding-bottom: .25rem;
                padding-top: .5rem;
            }

            @media ${device.mobile} {
                font-size:1rem;
            }

            &> span {
                color: ${(props) => props.theme.colors.brand_black_01};
                display: block !important;
                font-size: .92rem;
                @media ${device.mobile} {
                    font-size:.833rem;
                }
            }
        }

            @media ${device.mobile} {
                line-height: 1.5rem;
                padding: 0 1rem;
            }
        }
    }
`;
