import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const IsiStyle = styled.div`
  height: 100%;
//   left: -.65%;
  left: ${typeof navigator !== "undefined" && navigator.platform.indexOf('Win') > -1 ? '-8.5px' : '0'};
  overflow: hidden;
  padding: 0;
  position: relative;
  border-radius: 0!important;
  margin: 0 auto;
max-width: 1170px!important;
min-width: 300px!important;
width: 90%!important;

  @media screen and (max-width: 991px) {
    left: 0;
    width: 100% !important;
  }

  [data-component="isi"] {
    background-color: ${(props) => props.theme.colors.white};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-bottom: 100px;
    padding: 0 1rem;
    position: relative;

    & > p {
      @media ${device.mobile} {
        display: none;
      }
    }

    div {
      br {
        height: 11px;
      }
    }

    @media ${device.mobile} {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .isi-title-bar > h3 > span {
      display: none;
    }

    h3 {
      color: ${(props) => props.theme.colors.brand_purple_00};
      line-height: 3rem;
      margin-bottom: -0.25rem;
      text-transform: uppercase;

      @media ${device.mobile} {
        line-height: 1.5rem;
        padding: 1rem 0 1.25rem 0;
        font-size: 1.3rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 19px;

      @media ${device.mobile} {
        font-size: 1.333rem;
      }

      a {
        @media ${device.mobile} {
          text-decoration: underline;
        }
      }
    }

    & > div:nth-of-type(2) {
      background-color: ${(props) => props.theme.colors.white};
    }

    & > p:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    .blackbox {
      border: 2px solid ${(props) => props.theme.colors.brand_black_00};
      padding: 0.75rem 0.75rem 0.5rem;
      font-weight: 600;
      margin-bottom: 1rem;

      p {
        letter-spacing: -0.0095rem;
        line-height: 1.25rem;

        @media ${device.mobile} {
          font-size: 1.15rem;
        }

        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }

        > strong > a {
          text-decoration: underline;
        }
      }
    }
  }

  [data-isi-snippet] {
    display: none;

    &.show {
      display: block;
    }
  }

  [data-isi-snippet="indication"] {
    padding-bottom: 5rem;

	@media ${device.mobile} {
		padding-bottom: 11rem;
	}
  }

  #important-safety-information {
    height: inherit;
  }

  #isi-tray {
    z-index: 2 !important;

    [data-component="isi"] {
      .isi-title-bar > h3 {
        font-size: 1.25rem !important;
      }
    }

    & > .isi-tray-bg {
      position: relative;
      opacity: 0;
      transition: opacity 2s ease-out;
      z-index: 0;

      &::after {
        position: absolute;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 20%;
      }
    }
  }
`;
