import styled from "styled-components";
import { device } from "../components/Helpers";

export const TableOfContentsStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media ${device.mobile} {
	  margin-top: 16% !important;
  }

//   @media screen and (min-width: 375px) and (max-width: 429px) {
// 	  margin-top: 22vw;
//   }

  .mobileHide {
	@media ${device.mobile} {
		display:none
	}
  }

  .row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	width: 100%;
	margin-top: 35px;

	@media ${device.mobile} {
		justify-content: center;
		width: 99%;
	}

	.btn-4 {
		width: 30.5%;
		padding: 0 35px 0 20px;
		font-size: 24px ! important;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1.1;
		min-height: 95px;
		align-items: self-start;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		border: 1px solid #b99fd9;
		&::after {
			padding: .65rem;
			right: 1rem;
			top: calc(50% - 20px);
			transition: .05s 0s linear;
		}
		&:hover {
			text-decoration: none;
			&::after {
				right: .75rem;
			}
		}
		@media ${device.mobile} {
			width: 105%;
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 0;
			margin-bottom: 15px;
			min-height: 50px;
			font-size: 14px ! important;
			&:nth-child(1) {
				margin-top: 15px;
			}
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
			&::after {
				padding: 0.5em;
			}
		}
	}
	&:nth-last-child(1) {
		margin-bottom: 25px;
	}
	@media ${device.mobile} {
		margin-top: 0;
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
	}
  }
`;
