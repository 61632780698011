import { useEffect } from "react";

export const Analytics = () => {
  useEffect(() => {
    //check session var, set if none
    // sessionStorage.clear();
    var dataLayer;
    const entry_time = sessionStorage.getItem("entry_time");
    const entry_url = sessionStorage.getItem("entry_url")
      ? sessionStorage.getItem("entry_url")
      : "";

    const timerfunc = () => {
      setTimeout(() => {
        sessionStorage.setItem("entry30", "true");
        var currenturl = sessionStorage.getItem("entry_url");
        if (currenturl.includes("utm_medium")) {
          sessionStorage.setItem("conditionsmet", "true");
        }
      }, 30000);
    };

    if (entry_time && entry_url !== null) {
      const past30 = sessionStorage.getItem("entry30");

      if (past30 !== "true") {
        timerfunc();
      }
    } else {
      sessionStorage.setItem("entry_url", window.location.href);
      sessionStorage.setItem("entry_time", Date.now());

      timerfunc();
    }

    // var dataLayer;

    // const getQueryVariable = function(variable) {
    //     var query = window.location.search.substring(1);
    //     var vars = query.split("&");
    //     for (var i=0;i<vars.length;i++) {
    //       var pair = vars[i].split("=");
    //       if(pair[0]===variable){return pair[1];}
    //     }
    //     return(false);
    //   }

    // const URLtracking = window.location.href;

    // // SET referral ID from media in sessionStorage
    // // USE: from Good Apple Media drivers
    // const getReferralID = function() {
    // let field = 'ref1_id';

    // if (URLtracking.indexOf('?' + field + '=') != -1) {

    //     let referralIDVal = getQueryVariable("ref1_id");
    //     sessionStorage.setItem("ref1_id", referralIDVal);

    // } else if (URLtracking.indexOf('&' + field + '=') != -1) {

    //     let referralIDVal = getQueryVariable("ref1_id");
    //     sessionStorage.setItem("ref1_id", referralIDVal);

    // } else {
    //     return false
    // }

    // }

    const kpiTracker = () => {
      Array.from(document.querySelectorAll(".gtm-download-kpi")).forEach(
        (el) => {
          el.addEventListener("click", function (e) {
            if (sessionStorage.getItem("conditionsmet") === "true") {
              var label = el.getAttribute("data-galabel");
              dataLayer.push({
                "event-category": "pdf",
                "event-action": "download",
                "event-label": label,
              });
            }
          });
        }
      );
    };

    //   const initTracking = function() {

    //     getReferralID();
    //     getSubscriberKey();
    //     getVeevaId();
    //     pushGTMdataLayers();
    //   }

    //   initTracking();
    kpiTracker();
  });

  return null;
};
