import React, { useState } from "react";
import {
	ModalContainer,
	ModalPopup,
	ModalContentWrap,
	ModalContent,
} from "../../styles";
import { createMarkup } from "../Helpers";
import { handleLock } from "./utils";
import { Injector } from "../Injector/partials";

export const InterstitialModal = ({
	modalName,
	trigger,
	externalURL,
	disclaimer,
	setInner,
	gtmLabel,
	injectorContainer
}) => {
	const [open, setOpen] = useState(false);

	const handleClick = (e) => {
		e.preventDefault();
		setOpen(!open);
		handleLock(open);
	};

	const handleOutgoing = () => {
		setOpen(!open);
		handleLock(open);
	};

	const buildDisclaimer = () => {
		if (!!disclaimer) {
			return (
				<div className={`copy`}>
					<p>
						The site you are linking to is not controlled or
						endorsed by Insmed, and we are not responsible for the
						content.
					</p>
				</div>
			);
		}
	};

	setTimeout(() => {
		if (
			typeof window != "undefined" &&
			window.location.pathname.includes("why-treat")
		) {
			let num0 = document.querySelector("#num0-interstitial-injector-modal-content-wrap");
			if (num0) {
				num0.classList.add("unbrandedStyling");
			}
			}
	}, 800);

	return (
		<ModalContainer id={modalName} className={"interstitial-modal"}>
			<div
				role={`none`}
				id={`${modalName}-trigger`}
				className={`modal-trigger gtm-link-external`}
				onClick={handleClick}
				onKeyDown={handleClick}
				data-gtm-event-label={gtmLabel}
			>
				{setInner ? (
					<span
						dangerouslySetInnerHTML={createMarkup(trigger)}
						className={`gtm-link-external`}
						data-gtm-event-label={gtmLabel}
					/>
				) : (
					trigger
				)}
			</div>

			{injectorContainer ? <Injector
				container={injectorContainer}
				content={
					<ModalPopup className={`interstitial-popup` + (typeof window != "undefined" && window.location.pathname.includes("why-treat") ? " unbranded" : "")} isOpen={open}>
						<ModalContentWrap
							isOpen={open}
							id={`${modalName}-content-wrap`}
							className={"interstitial-content-wrap"}
						>
							<ModalContent>
								<div className="interstitial-modal-container">
									<div className="interstitial-modal-header">
										<h1>You are now leaving this website</h1>
									</div>
									{buildDisclaimer()}
									<div className="button-title">
										<h2>Thank you for visiting.</h2>
									</div>
									<div className="interstitial-modal-buttons">
										<a
											href={`/#`}
											onClick={handleClick}
											onKeyDown={handleClick}
											className="btn-1 gtm-cta"
											data-gtm-event-label="cancel"
										>
											<span
												className="label gtm-cta"
												data-gtm-event-label="cancel"
											>
												Cancel
											</span>
										</a>
										<a
											href={externalURL}
											target="_blank"
											rel="noreferrer"
											onClick={handleOutgoing}
											className="btn-1 gtm-cta"
											data-gtm-event-label="ok"
										>
											<span
												className="label gtm-cta"
												data-gtm-event-label="ok"
											>
												OK
											</span>
										</a>
									</div>
								</div>
							</ModalContent>
						</ModalContentWrap>
					</ModalPopup>
				}
			/> : null}
		</ModalContainer>
	);
};
