import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const HeaderStyle = styled.header`
  background-color: ${(props) => props.theme.colors.brand_purple_00};
  color: ${(props) => props.theme.colors.white};
  height: 16rem;
  left: 0;
  padding-bottom: 3rem;
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;

  @media ${device.desktop_xlg}{
    ${'' /* max-width: 1170px!important; */}
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    height: 14.5rem;
  }

  @media ${device.mobile}{
    height: 26.25rem !important;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 992px) {
    height: 12rem;
  }

  &::before,
  &::after {
    content: "";
    bottom: 0;
    height: 10.5vw;
    margin-bottom: -2%;
    position: absolute;
    width: 20vw;

    @media ${device.desktop_xlg} {
      content: none;
    }
  }

  &::before {
    // @media ${device.mobile} { margin-left:-19vw; } 
    left: 0;
    background: radial-gradient(
      circle at bottom right,
      transparent 2vw,
      ${(props) => props.theme.colors.brand_purple_00} 2.1vw
    );
    margin-left: -14vw;
  }

  &::after {
    // @media ${device.mobile} { margin-right:-19vw ;} 
    /* @media ${device.tablet} { display: none; } */

    background: radial-gradient(
      circle at bottom left,
      transparent 2vw,
      ${(props) => props.theme.colors.brand_purple_00} 2.1vw
    );
    right: 0;
    margin-right: -14vw;
  }

  height: 13.5rem;
  min-width: 320px !important;
  padding-bottom: 12.5rem;

  &:not(.secondary) {
    #mobile-subnav {
      display: none !important;
    }
  }

  #mobile-menu {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 0.55rem;
    border: none;
    cursor: pointer;
    height: 100px;
    margin-right: 0;
    margin-top: 4.5rem;
    padding: 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 86px;
    z-index: 3 !important;

    @media ${device.tablet} {
      margin-top: 4.25rem !important;
      transform: scale(81%) translate(1rem, -.5rem);
    }

    @media ${device.desktop} {
      margin-right: 5.25%;
      transform: scale(91%) translate(1.5rem, .25rem);
    }

    @media ${device.desktop_lg} {
      transform: scale(93%) translate(1.5rem, .25rem);
    }

    @media ${device.desktop_xlgmx} {
      margin-top: 4rem;
    }

    @media screen and (min-width: 991px) and (max-width: 1189px) {
      margin-top: 4rem;
    }

    @media only screen and (min-width: 1190px) {
      transform: scale(93%) translate(2.25rem, .35rem);
    }

    @media only screen and (min-width: 1281px) {
      transform: scale(93%) translate(.25rem, .35rem);
    }

    &.open {
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      height: 160px;

      @media ${device.mobile} {
        margin-right: 5%;
      }

      @media ${device.tablet} {
        margin-top: 4rem !important;
      }

      & > span{
        color: ${(props) => props.theme.colors.white};
        font-weight: bold;
      }
    }

    @media ${device.desktop_max} {
      margin-right: 6.25%;
    }
    @media ${device.desktop} {
      margin-right: 6.5vw;
    }
    @media ${device.tablet} {
      margin-right: 5%;
      margin-top: 4.5rem;
    }
    @media ${device.mobile} {
      background-color: transparent;
      margin-right: 5vw;
      margin-top: 4.5rem;
    }

    & > img {
      // margin: 1rem 1.25rem;
      // position: absolute;
      // right: 0;
      // top: 0;
      /* transition: 0.2s 0s ease-in; */
      margin-bottom: 1rem;
      @media ${device.mobile} {
        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(214deg) brightness(110%) contrast(102%);
        transition: none;
      }
      &:hover {
        transform-origin: center center;
        transform: scale(1.1);
      }
    }
    &.open {
      & > img {
        filter:none;  
        margin-bottom: -.75rem;
      }
    }
    & > span{
      color: ${(props) => props.theme.colors.brand_purple_00};
      font-weight: bold;
      @media ${device.mobile} {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  #mobile-subnav {
    bottom: 0;
    display: none !important;
    height: auto;
    margin-bottom: 1rem;
    position: absolute;
    width: 100% !important;

    & > div {
      background: ${(props) => props.theme.colors.brand_purple_01};
      line-height: 3rem;
      margin: 0 auto;
      position: relative;
    }

    & > div > button {
      background-color: transparent;
      background-image: url("../images/caret-01.svg");
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      margin-top: 0.75rem;
      padding: 0.75rem;
      position: absolute;
      top: 0;

      &.inactive {
        cursor: default;
        filter: grayscale(1);
        opacity: 0.25;
      }

      &.next {
        right: 0;
      }

      &.prev {
        left: 0;
        transform: rotate(180deg);
      }
    }

    & > div > p {
      color: ${(props) => props.theme.colors.brand_purple_00};
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }
  }

  & > div {
    padding: 0;
    position: relative;
  }

  a {
    font-size: 0.625rem;
  }

  li {
    line-height: 4rem;
    text-transform: uppercase;

    &:before {
      content: none;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
  }

  #arikayce-logo {
    left: 0;
    margin-top: 4.5rem;
    position: absolute;
    top: 0;

    @media ${device.desktop_xlgmx} {
      margin-left: 2rem;
      margin-top: 4.5rem;
    }

    @media ${device.desktop} {
      margin-left: 1rem;
      margin-top: 4.5rem;
    }

    @media ${device.mobile} {
      margin-left: 4.5vw;
      margin-top: 4.5rem;
    }

    @media ${device.tablet} {
      margin-top: 3.15rem;
    }

    &> img {
      height: auto;
      max-width: 240px;
      width: 15.25rem;

      @media ${device.desktop_xlgmx} {
        margin-left: 0.5rem;
        width: 14.5rem;
      }

      @media ${device.betweenTabletAndDesktop} {
        margin-left: 2rem;
        width: 100%;
      }

      @media ${device.mobile} {
        width: 14.75rem;
      }

      @media ${device.tablet} {
        margin-left: 1rem;
        margin-top: 1.5rem;
      }
    }
  }

  #mobile-menu,
  #mobile-subnav {
    // display: block !important;
    z-index: 1;
    display: flex!important;
    flex-direction: column;
    align-items: center;
  }

  #patient-link {
    position: relative;
    text-transform: uppercase;

    @media ${device.desktop_xlgmx} {
      background-color: unset !important;
      background-color: unset !important;
      border-radius: unset !important;
      color: unset !important;
      display: unset !important;
      font-size: 0.6666665rem !important;
      font-weight: unset !important;
      line-height: unset !important;
      padding: 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &::after {
      @media ${device.desktop_xlgmx} {
        content: none;
      }
    }
  }

  [data-component="header"] {
    margin: 0 auto;
    padding: 0;
    text-align: center;
    width: 90%;

    @media ${device.desktop} {
      padding: 0;
    }

    @media ${device.desktop_max} {
      width: 98% !important;
    }

    @media ${device.desktop_xlg} {
      width: 100% !important;
    }

    &> ul {
      justify-content: flex-start;
      padding-left: 0.25rem;

      @media ${device.desktop_max} {
        margin-left: .75rem;
      }

      @media ${device.desktop_xlgmx} {
        margin-top: 0.5rem;
        padding-left: 0;
      }

      @media ${device.mobile} {
        align-items: center;
        display: flex;
      }

      @media ${device.tablet} {
        display: inline-block;
        width: 100%;
      }

      &> li {
        position: relative;
        text-transform: initial;

        &:nth-of-type(1) {
          margin-left: -2rem;
          @media ${device.desktop} {
            display: none;
          }
          @media ${device.desktop_max} {
            display: none;
          }
          @media ${device.desktop_xlg} {
            display: none;
          }
          @media ${device.tablet} {
            float: left;
            width: auto;
          }
        }

        ${'' /* &:nth-of-type(3) {
          text-align: right;
        } */}

        @media ${device.desktop} {
          margin-right: 1rem;
        }

        @media ${device.desktop_max} {
          margin-right: 1rem;
        }

        @media ${device.desktop_xlg} {
          margin-right: 1rem;
        }

        @media ${device.mobile} {
          width: 50%;
        }

        @media ${device.tablet} {
          float: left;
          margin-right: 1rem;
        }

        @media screen and (max-width: 767px) {
          &:nth-of-type(1),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6),
          &:nth-of-type(7) {
            display: none;
          }
        }

        &::after {
          @media ${device.desktop_xlgmx} {
            content: none;
          }
        }

        ${'' /* &:not(:last-of-type) {
          margin-right: 2rem;
          @media ${device.desktop_xlgmx} {
            margin-right:0;
          }
        } */}

        &:last-of-type {
          position: absolute;
          right: 0;
        }

        &:nth-of-type(1) {
          padding-left: 0;

          @media ${device.mobile} {
            margin: 0;
            float: left;
            text-align: left;
            width: 50%;
          }
        }

        &:nth-of-type(2) {
          @media ${device.mobile} {
            text-align: left;
          }

          @media ${device.tablet} {
            width: auto;
          }
        }

        &:nth-of-type(3) {
          text-align: right;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &:nth-of-type(7)::after,
        &:last-of-type::after {
          content: none;
        }
      }

      & > li a {
        @media ${device.desktop_xlgmx} {
          font-size: 0.6666665rem;
        }

        font-size: 0.6725rem;
      }
    }

    a {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .divider {
    content: "|";
    margin-right: -0.75rem;
    margin-top: 0.05rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > .wrapper {
    width: 100%!important;
    @media ${device.desktop_xlgmx} {
      z-index: 2;
    }
  }

  .audio-player-wrapper {
    border-radius: 5.33px;
    border: 1.07px solid ${(props) => (props.theme.colors.brand_purple_01)};
    height: 98px;
    left: 148px;
    position:relative;
    top: 2.5rem;
    width: 440px;
    z-index:0;

    @media only screen and (min-width: 992px) {
      left: unset;
      max-width: 440px !important;
      right: 35%;
      transform: scale(90%) translateY(-10rem);
    }

    @media only screen and (min-width: 1281px) {
      right: 0;
      top: 0;
      transform: unset !important;
    }

    @media ${device.desktop_xlgmx} {
      height: 6.25rem;
      position: absolute;
      top: 4.55rem;
      transform: scale(90%) translate(2rem,.5rem);
    }

    @media ${device.mobile}{
      height: 7.85rem;
      left: 0;
      margin-top: 7.5rem;
      max-width: 767px !important;
      padding: .5rem;
      position: relative;
      right: unset;
      top: 0;
      width: 100%;
      transform: scale(90%) translateY(0);
    }

    @media ${device.tablet} {
      left: unset;
      max-width: 440px !important;
      right: 15%;
      transform: scale(83%) translate(50px, -10px);
    }

    ${'' /* @media screen (min-width: 1160px) and (max-width: 1190px) {
      right: 32vw !important;
    } */}

    ${'' /* @media screen (min-width: 991px) and (max-width: 1189px) {
      right: 34.5vw !important;
    } */}
  }
`;