import React from "react";
import { SlickCarousel } from "./partials";
import { createMarkup } from "../Helpers";

// const isBrowser = (typeof window !== "undefined");

export const MobileChartCarousel = ({ defaultSlide, slides, markdownID }) => {
	const buildSlides = () => {
		return slides.map(({ container, content, img }, i) => {
			if (i !== 3) {
				return (
					<div
						data-index={i}
						key={`${i}-mobile-chart-slide-for-${container}`}
					>
						<div
							className="content"
							dangerouslySetInnerHTML={createMarkup(content)}
						/>
						<img src={img} alt={`${i}-mobile-chart`} />
					</div>
				);
			} else return null;
		});
	};

	return (
		<>
			<SlickCarousel
				desktopSlides={1}
				desktopToScroll={1}
				mobileSlides={1}
				mobileToScroll={1}
				centerMode={false}
				centerPadding={0}
				variableWidth={false}
				infinite={false}
				initialSlide={defaultSlide}
				className={markdownID}
			>
				{buildSlides()}
			</SlickCarousel>
		</>
	);
};
