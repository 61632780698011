import React, { useEffect, useState } from "react";
import { Isi } from "./Isi";
import { useStaticQuery, graphql } from "gatsby";
import { globalHistory } from '@reach/router'
import { IsiTrayStyle } from "../styles";
import { isiAnchorThreshold } from "./Helpers";
import { ISIToggleInjector, ISITrayToggleInjector } from "./Injector";
import unbranded from "./Data/unbranded";
import { isWindow } from "./Helpers";
const isDocument = typeof document !== "undefined";
const fullTitle = "Important Safety Information and Indication";
const selectTitle = "SELECT IMPORTANT SAFETY INFORMATION";

export const IsiTrayImages = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "caret.svg" }, publicURL: {}) {
        publicURL
      }
    }
  `);

  return data.file.publicURL;
};

export const IsiTray = ({markdownID, isiSnippet, setShowISITrack }) => {

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpen(false);
        isiExpandHandler(true);
        setShowISITrack(false);
      }
    });
}, [setShowISITrack, setOpen])
  /****************************/
  /*
        start - isiExpandHandler()
        notes: sets and tracks the isi tray opening
    */
  const isiExpandHandler = (o) => {
    if (isDocument) {
      const _title = document.querySelector('.isi-title-bar > h3');
      const _html = document.querySelector("html");

      if (!o) {
        _html.classList.add("isi-tray-open", "locked");
        _title.innerHTML = fullTitle;
        _title.classList.add('opened');
      }
      else {  
        _html.classList.remove("isi-tray-open", "locked");
        if (_title !== null) {
          _title.innerHTML = selectTitle;
          _title.classList.remove('opened');
        }
      } 
      
      if (
        _html.classList.contains("isi-tray-collapsed") &&
        !_html.classList.contains("mini-isi")
      ) {
        _html.classList.add("mini-isi");
      } else {
        _html.classList.remove("mini-isi");
      }
    }
  };
  /* end - isiExpandHandler() */
  /****************************/

  const handleClick = () => {
    setOpen(!open);
	isiExpandHandler(open);
	setShowISITrack(!open);
  };

	const handleUnbrandedClick = () => {
    if (isWindow) {
      window.open('https://www.arikaycehcp.com/important-safety-information/', '_blank');
    }
  };

  const buildISI = () => {
    return (
      <>
        {/* <Isi id={`isi-outside-tray`} /> */}
        { isWindow && !unbranded.includes(window.location.pathname) &&  <IsiTrayStyle
          id={`isi-tray`}
          className={open ? `open` : ``}
          Expander={IsiTrayImages()}
          open={open}
        >
          <Isi id={`isi-inside-tray`} isiSnippet={isiSnippet} />
          {loaded ? (
            <ISITrayToggleInjector trigger={""} handler={handleClick} />
          ) : null}
        </IsiTrayStyle> }
        <ISIToggleInjector
          query={[
            {
              container: "important-safety-information-toggle-container",
              trigger: "IMPORTANT SAFETY INFORMATION",
            },
          ]}
          handler={ isWindow && !unbranded.includes(window.location.pathname) ? handleClick : handleUnbrandedClick }
        />
      </>
    );
  };

  useEffect(() => {
    setLoaded(true);
    setTimeout(() => {
    }, isiAnchorThreshold); //This timeout should match was is in the adjustScrollPosition() in Helpers.js
  }, [setLoaded]);


  return <>{markdownID !== 'ed-why-treat-mac' ? buildISI() : ``}</>;
};
