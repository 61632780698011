import styled from "styled-components/macro"
import { device } from "../components/Helpers"

export const EdetailTargetMacStyle = styled.main`
	position: relative;
	margin: 12.5rem auto 0;

	#tabbing-through {
		@media ${device.mobile} {
			width: 96% !important;
			z-index: ${(props) => (props.isZoomed ? "-1" : "3")} !important;
		}
	}
	#see-how {
		width: 100%;
	}
	section {
		margin-top: 0 !important;
		padding-top: 0;
		.slick-arrow,
		.slick-next,
		.slick-prev {
			@media ${device.mobile} {
				bottom: -5px;
			}
		}
	}

	section[data-tab-index="1"],
	section[data-tab-index="2"] {
		padding-top: 2rem;
		@media ${device.desktop} {
			padding-top: 4rem;
		}
		@media ${device.desktop_lg} {
			padding-top: 3.5rem;
		}
		@media ${device.desktop_xlg} {
			padding-top: 1.5rem;
		}
		@media ${device.tablet} {
			padding-top: 3.25rem;
		}
	}

	#in-vivo,
	#lower-levels {
		@media ${device.mobile} {
			padding: 0;
			min-width: 100% !important;
		}
	}
	.moamobile {
		width: 100%;
		@media ${device.mobile} {
			margin-top: 1.5rem;
		}
	}
	#amikacin-serum-levels-chart-mobile-div,
	#in-vivo-chart-mobile-div {
		width: 100%;
		overflow-x: scroll;

		@media ${device.mobile} {
			margin-bottom: 1.6667rem;
		}
	}

	.mobileHide {
		display: block;
		@media ${device.mobile} {
			display: none !important;
		}
	}

	.mobileShow {
		display: none;
		@media ${device.mobile} {
			display: block;
		}
	}

	#depiction-of-arikayce {
		&
			> .list-container
			> div:last-of-type
			> [data-component="callout"]
			> h2
			> span {
			font-size: 1.25rem;
			line-height: 0;
			top: 0rem;
			position: relative;
			font-family: arial;
			left: -0.15rem;
		}
		h1 {
			margin-bottom: 3.5rem;

			@media ${device.mobile} {
				font-size: 1.916rem;
				line-height: 27px;
				margin-bottom: 0;
			}
		}
		.scrolltext {
			margin-bottom: 1rem;
			font-size: 1.3333rem;
		}
		h2 {
			@media ${device.mobile} {
				font-size: 1.583rem;
			}
		}

		& > p {
			@media ${device.mobile} {
				font-size: 1.333rem;
			}
		}

		.list-container {
			align-items: center;
			margin: 2rem 0;
			flex-direction: row;

			@media ${device.mobile} {
				margin-bottom: 0rem;
				flex-direction: column;
			}
			& > div {
				& > br {
					@media ${device.mobile} {
						display: none;
					}
				}
			}
			h2 {
				@media ${device.mobile} {
					font-size: 1.5rem;
				}
			}

			& > div {
				width: 50%;
				@media ${device.mobile} {
					width: 100%;
					margin-bottom: 2rem;
				}
				& > h3 {
					@media ${device.mobile} {
						font-size: 1.333rem;
						margin-bottom: 0.25rem;
					}
				}
			}

			& > div:nth-of-type(1) {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			& > div:last-of-type {
				padding-left: 1rem;

				@media ${device.mobile} {
					padding: 0;
					margin-bottom: 3.3333rem;
				}

				ul {
					position: relative;
					top: 0.5rem;

					& > li {
						margin-bottom: 0.75rem;
						@media ${device.mobile} {
							font-size: 1.333rem;
							margin-bottom: 1.75rem;
						}
					}
				}
				p {
					@media ${device.mobile} {
						font-size: 1.333rem;
					}
				}
			}

			[data-component="callout"] {
				margin: -0.5rem 1rem 0.75rem 0;
				padding-bottom: 0.75rem;
				padding-left: 1.75rem;

				@media ${device.mobile} {
					margin: 0;
					margin-bottom: 2.5rem;
					padding-bottom: 1rem;
					padding-top: 1rem;
				}

				& > p {
					position: relative;
					font-size: 1rem !important;
					top: -0.5rem;
					font-size: 1.092rem;
					// line-height: 1.5rem;

					@media ${device.mobile} {
						font-size: 1.08333rem !important;
						line-height: 1.25rem;
					}
				}

				h2 {
					position: relative;
					top: -0.85rem;

					@media ${device.mobile} {
						font-size: 1.33rem;
						margin-bottom: 0.25rem;
						top: 0;
					}
				}

				h3 {
					line-height: 3rem;
					position: relative;
					top: 0.5rem;
				}
			}
		}
	}

	#macrophages,
	#limited-exposure,
	#clsi-guidelines {
		h2:nth-of-type(1) {
			font-weight: 500;
			font-size: 1.75rem;
			font-family: inherit;
		}
	}

	#clsi-guidelines {
		h2 {
			line-height: 1.75rem;
			//width:46.4rem;
			@media ${device.mobile} {
				width: 100%;
			}
		}
	}

	#in-vivo,
	#lower-levels {
		margin-bottom: 3.5rem;
		width: 100% !important;

		& > img {
			display: block;
			margin: 0 auto 0.75rem;
		}

		& > p {
			@media ${device.mobile} {
				font-size: 1.333rem;
			}
		}

		& > p {
			@media ${device.mobile} {
				font-size: 1.333rem;
			}
		}
	}

	#in-vivo {
		margin-top: 2.5rem;
		max-width: 789px !important;
		p {
			@media ${device.mobile} {
				font-size: 1.333rem;
			}
		}
	}

	#lower-levels {
		max-width: 712px !important;
	}

	#moa-image {
		display: inline-block;
		height: auto;
		margin-top: 20rem;
		position: relative;
		width: 15rem;
		z-index: 2;

		@media ${device.mobile} {
			max-width: 192px;
			width: 100%;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			background: ${(props) => props.theme.colors.white};
			width: 100%;
			height: 0.5rem;
			margin-bottom: 1.75rem;
			z-index: 2;
		}

		&::after {
			content: "";
			border-bottom: 2px solid
				${(props) => props.theme.colors.brand_black_01};
			border-left: 2px solid
				${(props) => props.theme.colors.brand_black_01};
			border-radius: 0.5rem;
			border-right: 2px solid
				${(props) => props.theme.colors.brand_black_01};
			bottom: 0;
			height: 1.2rem;
			left: 0;
			margin-bottom: 0.8rem;
			position: absolute;
			width: 95%;
			z-index: 1;
		}

		& > img {
			bottom: 0;
			left: 0;
			position: absolute;
			width: 236px;
			margin-bottom: 2.1rem;

			@media ${device.mobile} {
				width: 100%;
			}
		}

		& > p {
			position: absolute;
			top: 0;
			@media ${device.mobile} {
				font-size: 0.75rem;
				line-height: 0.75rem;
			}

			&:nth-of-type(1) {
				left: 0;
				margin-left: -7.5rem;
				margin-top: -15.75rem;
				text-align: right;
				@media ${device.mobile} {
					margin-left: -5.5rem;
				}
				&::after {
					content: "";
					border-top: 2px solid
						${(props) => props.theme.colors.brand_black_01};
					left: 0;
					margin-left: 6.75rem;
					margin-top: 0.65rem;
					position: absolute;
					top: 0;
					width: 3rem;
					content: "";
					@media ${device.mobile} {
						margin-left: 5.5rem;
					}
				}
			}

			&:nth-of-type(2) {
				margin-right: -6.75rem;
				margin-top: -9.5rem;
				right: 0;
				text-align: left;
				@media ${device.mobile} {
					margin-right: -4.75rem;
				}

				&::after {
					content: "";
					border-top: 2px solid
						${(props) => props.theme.colors.brand_black_01};
					margin-right: 4.75rem;
					margin-top: 0.5rem;
					position: absolute;
					right: 0;
					top: 0;
					width: 5.75rem;
					box-shadow: 50px 50px 100px rgb(255 255 255);

					@media ${device.mobile} {
						margin-right: 3.75rem;
					}
				}
			}

			&:last-of-type {
				margin-left: -0.25rem;
				margin-top: -0.75rem;
				text-align: center;
				width: 100%;
			}
		}
	}

	#next-link {
		width: 17rem;
		@media ${device.ie11} {
			width: 17.5rem;
		}
	}

	#see-how {
		& > img {
			display: block;
			margin: 0 auto;
			@media ${device.mobile} {
				width: 100%;
			}
		}
	}

	.list-container {
		display: flex;
		margin: 2rem 2rem 3.75rem 2rem;

		ul {
			li {
				margin-bottom: 0.75rem;
			}
		}
	}

	#MOA-video-container {
		margin-bottom: 3.75rem;
		@media ${device.mobile} {
			margin-bottom: 2.5rem;
		}
		iframe {
			@media ${device.mobile} {
				width: 100%;
				height: auto;
			}
		}
	}

	#MOA-video-injector-container {
		@media ${device.mobile} {
			margin-top: 1.667rem;
		}
	}

	.slick-dots {
		margin: 0 0 3.75rem 0;
		bottom: 0;
		position: inherit;
	}

	.slick-arrow {
		bottom: -0.375rem;
	}

	.transcript {
		margin-top: 3.563rem;
	}

	.definitions-section,
	.definitions {
		@media ${device.mobile} {
			margin-top: 0;
		}
	}

	.slick-track .slick-slide {
		@media ${device.mobile} {
			padding: 0 0.25rem;
		}
	}
`
