import React from "react";
import { Video } from "./partials";

export const VideoInjector = ({ video }) => {
	const buildInjectorVideo = () => {
		if (video) {
			const { id, vimeoID } = video;
			return (
				<Video id={id} videoID={vimeoID} size={640} />
			);
		}
	};

	return <>{buildInjectorVideo()}</>;
};
