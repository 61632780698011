import React, { useState, useEffect } from "react";
import {
  ModalPopup,
  ModalContentWrap,
  ModalContent,
  ModalLeaving,
} from "../../styles";
import { interaction } from "../Helpers";

export const LeavingModal = ({ externalURL }) => {
  const [open, setOpen] = useState(false);

  const stay = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const leave = (e) => {
    setOpen(!open);
  };

  const termsAndConditionHandler = () => {
    const _externalLinks = document.querySelectorAll(`.external-link`);
    
    Array.from(_externalLinks).forEach((el) => {
      el.addEventListener(interaction, (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        const _leavingModalOK = document.querySelector(`#leaving-modal .interstitial-modal-buttons > a:last-of-type`);
        _leavingModalOK.href = el.href;
        setOpen(true);
      });
    });
  };

  useEffect(() => {
    // const _html = document.querySelector(`html`);
    termsAndConditionHandler();
  }, [setOpen]);


  return (
    <ModalLeaving id={`leaving-modal`}>
      <div id={`are-you-hcp-trigger`} className={`modal-trigger`} />
      <ModalPopup isOpen={open} className={open ? `locked` : ``}>
        <ModalContentWrap isOpen={open} className={`leaving-content-wrap`}>
          <ModalContent>
            <div className={`landing-modal-container`}>
              <div className={`modal-header`}>
                <h1>You are now leaving this website</h1>
              </div>
              <div className="button-title">
                <h2>Thank you for visiting.</h2>
              </div>
              <div className="interstitial-modal-buttons">
                <a
                  href={`/#`}
                  onClick={stay}
                  onKeyDown={stay}
                  className="btn-1 gtm-cta"
                  data-gtm-event-label="cancel"
                >
                  <span className="label gtm-cta" data-gtm-event-label="cancel">
                    Cancel
                  </span>
                </a>
                <a
                  href={externalURL}
                  target="_blank"
                  rel="noreferrer"
                  onClick={leave}
                  className="btn-1 gtm-cta"
                  data-gtm-event-label="ok"
                >
                  <span className="label gtm-cta" data-gtm-event-label="ok">
                    OK
                  </span>
                </a>
              </div>
            </div>
          </ModalContent>
        </ModalContentWrap>
      </ModalPopup>
    </ModalLeaving>
  );
};
