import styled from "styled-components/macro";
import { device } from "../components/Helpers";
// import thumbnail from "../images/video-thumbnail.png";

export const VideoLibraryContainer = styled.div`
  border: 2px solid ${(props) => props.theme.colors.brand_purple_00};
  border-radius: .25rem;
  margin: 2.5rem 0;
  padding-bottom: 1.5rem;


  @media ${device.mobile} {
    border:none;
    margin-bottom: 2.5rem;
  }

  &.chapterized {
    display: block;
    margin-top: 0;
  }
  ul.slick-dots li button:before{
    @media ${device.mobile} {
      border: 2px solid #e35205;
    }
  }
  .video-carousel-strip, .video-carousel {
    margin: 2.5rem auto;
    padding: 1.25rem 2rem 1.25rem 2rem;
    background-color: ${(props) => props.theme.colors.brand_gray_04};

    .slick-prev, .slick-next {
      top: 127%;

      @media ${device.mobile} {
        top: 114%;
      }

      &:before {
        opacity: 0;
        bottom: -1px;
        position: absolute;
        width: 20px;
        height: 20px;
      }
    }

    .slick-prev {
      padding-left: 1rem;
    }

    .slick-next {
      /* padding-right: 2.5rem; */
    }

    .slick-dots {
      bottom: -63px;

      @media ${device.mobile}{
        bottom: -45px;
      }
    }
  }

  .video-carousel {
    background-color: white;

    .slick-prev, .slick-next {
      top: 114%;

      @media ${device.mobile} {
        top: 114%;
      }
    }

    .slick-list{
      /* padding:0 5% 0 1% !important; */

      @media ${device.mobile} {
        padding: 0 !important;
      }
      .slick-track {
        margin: 0 auto;
        /* .slick-slide {
          margin-right: 3rem;
          &.slick-active {
            margin-left: -6rem;
            &:nth-of-type(7) {
              margin-right: 0 auto !important;
            }
          } */
        }

        @media ${device.mobile} {
          .slick-slide {
            margin: 0 auto !important;
            /* max-width: 245px; */
          }
        }
      }

      .video-card-container {
        max-width: 450px;
        margin: 0 auto;

        &.infinite {
          margin: 0;
          @media ${device.mobile} {
            margin: 0 auto;
          }
        }

        @media ${device.mobile} {
          max-width: 280px;
          margin: 0 auto;
        }

        .video-card {
          text-align: left;
        }

        .video-title {
          margin: 1rem 0;
          @media ${device.mobile} {
            margin-left: .8rem;
          }
        }
      }
    }

    .slick-current {
      .video-card-container {
        width: 400px;
        height: 225px;

        @media ${device.mobile} {
          width: 280px;
        }

        iframe {
          width: 450px;
          /* max-height: 281px; */
          /* border: 5px solid ${(props) =>
            props.theme.colors.brand_orange_00}; */

          @media ${device.mobile} {
            max-width: 280px;
            width: 100%;
          }
        }
      }
    }

    .slick-prev, .slick-next {
      top: 110%;

      @media ${device.mobile}{
        top: 102%
      }
    }

    &.video-library {

      @media ${device.mobile} {
        .slick-dots {
          bottom: -19px !important;
        }
      }
    }

  }


  .video-carousel-strip {
    @media ${device.mobile} {
      background-color: white;
      margin: 2rem auto;
      padding: 0 .9rem;
    }

    .slick-next, .slick-prev {
      @media ${device.mobile} {
        top: 130%;
      }
    }

    .slick-next {
      /* padding-right: 2rem; */
    }
  }

  .video-card-container {
    @media ${device.mobile} {
      flex-direction: reverse-column;
    }
  }
`;

export const VideoPlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 2rem 2rem 1.875rem 2rem;

  @media ${device.mobile} {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0.6667rem;
  }

  .video-player {
    display: flex;
    @media ${device.mobile} {
      order: 2;
    }
  }

  .video-details {
    display: flex;
    padding-left: 2rem;
    @media ${device.mobile} {
      padding: 0;
    }
    .list-title {
      font-weight: bold;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.brand_purple_00};
      @media ${device.mobile} {
        font-size: 1.5833rem;
      }
    }
    .bullet-list > ul {
      li {
        @media ${device.mobile} {
          font-size: 1.3333rem;
        }
      }
    }
  }
`;

export const VideoTile = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 240px;
  width: 100%;

  @media ${device.mobile} {
    max-width: 95px;
  }

  .thumbnail {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border: ${(props) => (props.isSelected ? "5px" : 0)} solid
      ${(props) => props.theme.colors.brand_orange_00};
    border-radius: 0.25rem;

    @media ${device.mobile} {
      border: ${(props) => (props.isSelected ? "3px" : 0)} solid
        ${(props) => props.theme.colors.brand_orange_00};
    }

    img {
      width: 100%;
      height: auto;
    }

    .timestamp {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      height: 18px;
      line-height: 18px;
      padding: 0.1rem 0.5rem;
      border-radius: 0.5rem;
      background-color: white;
      color: ${(props) => props.theme.colors.brand_purple_00};
    }
  }

  .details {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
  }
`;

export const VideoWrapper = styled.div`
  .video-player {
    margin: 0 auto;
    text-align: center;

    iframe {
      width: 100%;
    }

    @media ${device.mobile} {
      width: 100% !important;
      margin: 0;
    }
  }
`;
