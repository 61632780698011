import React, { useState, useEffect } from "react";
import { globalHistory } from '@reach/router'
import { CssBaseline } from "@material-ui/core";
import { Footer } from "./Footer";
import { LandingModal, ThirdPartyLinkModals } from "./Modals";
import { LeavingModal } from "./Modals";
import { IsiTray } from "./IsiTray";
import { Header } from "./Header";
import { BackToTop } from "./Partials";
import { isWindow } from "./Helpers";
import { PopupsInjector } from "./Injector/PopupsInjector";
import { MobileImageInjector } from "./Injector/MobileImageInjector";
import { PatientCarousel } from "./";
import { ContainerStyle } from "../styles/ContainerElements";
import { Injector } from "./Injector/partials";

export const Container = (props) => {
	if (typeof window !== "undefined") {
		require("smooth-scroll")('a[href*="#"]');
	}
	const [loaded, setLoaded] = useState(false);
	const [showISITrack, setShowISITrack] = useState(false);

	const data = props.query,
		markdownID = props.markdownID,
		children = props.children,
		noSubNav = props.noSubNav,
		componentID = props.componentID,
		isiSnippet = props.isiSnippet,
		arrowISI = props.arrowISI,
		track = props.track,
		trackWProps = props.trackWProps,
		setTrackWProps = props.setTrackWProps,
		ccModalSetTrackWProps = props.ccModalSetTrackWProps,
		transcriptState = props.transcriptState,
		setTranscriptState = props.setTranscriptState,
		zoom = props.zoom,
		setZoom = props.setZoom,
		scanZoom = props.scanZoom,
		setScanZoom = props.setScanZoom,
		ccModalTrackKey = props.ccModalTrackKey,
		currentslug = props.query.frontmatter.slug;

	useEffect(() => {
		if (isWindow) {
			if (markdownID === `ed-why-treat-mac`) {
				document.querySelector(`html`).classList.add(`unbranded-page`);
			} else {
				document
					.querySelector(`html`)
					.classList.remove(`unbranded-page`);
			}
		}
		setLoaded(true);
	}, [setLoaded, markdownID]);

	useEffect(() => {
		return globalHistory.listen(({ action }) => {
		  if (action === 'PUSH') {
			  setTranscriptState(false);
			  document.querySelector('body').scrollTop = 0;
		  }
		});
	}, [setTranscriptState])

	return (loaded ? 
		<div id={`container`}>
			{markdownID !== `home-content` ? <Injector
				container={`main`}
				content={
					<ContainerStyle id={`main-wrap`}></ContainerStyle>
				}
			/> : null}
			
			<CssBaseline />
			<Header
				seoDesc={data.frontmatter.description}
				seoTitle={data.frontmatter.title}
				markdownID={markdownID}
				componentID={componentID}
				noSubNav={noSubNav}
				track={track}
				trackWProps={trackWProps}
				setTrackWProps={setTrackWProps}
				currentslug={currentslug}
				setTranscriptState={setTranscriptState}
				showISITrack={showISITrack}
			/>
			{children}
			<IsiTray
				markdownID={markdownID}
				isiSnippet={isiSnippet}
				showISITrack={showISITrack}
				setShowISITrack={setShowISITrack}
			/>
			<BackToTop />
			<Footer markdownID={markdownID} />
			<LandingModal markdownID={markdownID} />
			<LeavingModal />
			<ThirdPartyLinkModals markdownID={markdownID} />
			{ccModalSetTrackWProps ? (
				<PopupsInjector
					componentID={componentID}
					setTrackWProps={ccModalSetTrackWProps}
				/>
			) : null}
			{data.frontmatter.mobileCharts ? (
				<MobileImageInjector
					zoom={zoom}
					setZoom={setZoom}
					query={data.frontmatter.mobileCharts}
					trackWProps={trackWProps}
					setTrackWProps={ccModalTrackKey ? setTrackWProps : ccModalSetTrackWProps}
					transcriptState={transcriptState}
					setTranscriptState={setTranscriptState}
					markdownID={markdownID}
					trackKey={ccModalTrackKey}
				/>
			) : null}
			{arrowISI ? (
				<PatientCarousel
					zoom={scanZoom}
					setZoom={setScanZoom}
					arrowISI={arrowISI}
					trackWProps={trackWProps}
					setTrackWProps={setTrackWProps}
					isiSnippet={isiSnippet}
					transcriptState={transcriptState}
					setTranscriptState={setTranscriptState}
				/>
			) : null}
		</div>
	: null);
};
