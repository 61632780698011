import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Arrow } from "../partials";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { interaction, isWindow, scrollToTop } from "../../Helpers";

export const SlickCarousel = ({
	className,
	arrowText,
	desktopSlides,
	desktopToScroll,
	slider,
	centerMode,
	children,
	centerPadding,
	mobileSlides,
	mobileToScroll,
	infinite,
	variableWidth,
	initialSlide,
	adaptiveHeight,
	dataOptions,
	classOptions,
	setTrackWProps,
	arrowTextISI,
}) => {
	if (typeof arrowText === "undefined") {
		arrowText = [
			["", ""],
			["", ""],
			["", ""],
			["", ""],
		];
	}
	if (typeof arrowTextISI === "undefined") {
		arrowTextISI = [
			["", ""],
			["", ""],
			["", ""],
			["", ""],
		];
	}
	useEffect(() => {
		Array.from(
			document.querySelectorAll(`.slick-dots.inactive > li`)
		).forEach((el) => {
			el.addEventListener(interaction, (e) => {
				e.stopImmediatePropagation();
				return false;
			});
		});
	});

	const [arrowTextIndex, setArrowTextIndex] = useState(0);
	const settings = {
		infinite,
		dotsClass: `slick-dots inactive`,
		dots: true,
		prevArrow: (
			<Arrow
				isiSnippet={arrowTextISI[arrowTextIndex][0]}
				arrowText={arrowText[arrowTextIndex][0]}
				className="gtm-accordian"
				type="backward"
				gtmlabel="Left Carousel Arrow"
			/>
		),
		nextArrow: (
			<Arrow
				isiSnippet={arrowTextISI[arrowTextIndex][1]}
				arrowText={arrowText[arrowTextIndex][1]}
				className="gtm-accordian"
				type="forward"
				gtmlabel="Right Carousel Arrow"
			/>
		),
		slidesToShow: desktopSlides,
		slidesToScroll: desktopToScroll,
		swipe: false,
		centerMode,
		centerPadding,
		initialSlide,
		variableWidth,
		adaptiveHeight,
		beforeChange: (current, next) => {
			setArrowTextIndex(next);
			if (isWindow && window.location.pathname !== "/patient-profiles") {
				setTrackWProps(next);
			}
			scrollToTop();
		},
		afterChange: (current, next) => {
			if (isWindow) {
				setTimeout(() => {
					let currentSlide = document.querySelector(
						".slick-slide.slick-active.slick-current div section"
					);

					let adjustment = ``;

					if (window.innerWidth < 768) {
						adjustment = 0;
					}
					else {
						adjustment = .35;
					}

					if (currentSlide) {
						let styles = isWindow
							? window.getComputedStyle(currentSlide)
							: null;
						let margin =
							parseFloat(styles["marginTop"]) +
							parseFloat(styles["marginBottom"]);
						let absoluteHeight = Math.ceil(
							currentSlide.offsetHeight + margin
						);
						absoluteHeight = absoluteHeight + (absoluteHeight * adjustment);

						document.querySelector(
							"." + className.replace(/\s/g, ".") + " .slick-list"
						).style.cssText = "height: " + absoluteHeight + "px;";
					}
					scrollToTop();
				}, 25);
			}
		},
		responsive: [
			{
				breakpoint: 480,
				settings: {
					centerPadding: 0,
					centerMode: false,
					variableWidth: false,
					slidesToShow: mobileSlides,
					slidesToScroll: mobileToScroll,
				},
			},
		],
	};

	return (
		<section
			className={classOptions + " " + className}
			data-tab-index={dataOptions}
		>
			<Slider ref={slider} {...settings}>
				{children}
			</Slider>
		</section>
	);
};
