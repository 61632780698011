import { graphql, useStaticQuery } from "gatsby";

export const FetchComponents = (componentID) => {
  let components = "";

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            components_id
            markdown_id
            chartKeys
            popups {
              container
              audioTrack
              trigger
              setInner
              header
              altText
              copy
              closegtm
              img {
                altText
                url
              }
              isiSnippets
            }
          }
        }
      }
    }
  `);

  data.allMarkdownRemark.nodes.forEach((node) => {
    if (node.frontmatter.markdown_id === componentID) {
      components = node.frontmatter;
    }
  });

  return components;
};
