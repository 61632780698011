import { useStaticQuery, graphql } from "gatsby";

export const goToPrevSlide = () => {
  let index = this.state.activeIndex;
  let length = this.state.length;
  if (index < 1) {
    index = length - 1;
  } else {
    index--;
  }
  this.setState({
    activeIndex: index,
  });
};

export const goToNextSlide = () => {
  let index = this.state.activeIndex;
  let length = this.state.length;
  if (index === length - 1) {
    index = 0;
  } else {
    index++;
  }
  this.setState({
    activeIndex: index,
  });
};

export const PatientImage = (patientName) => {
  const data = useStaticQuery(graphql`
    query {
      elisse: file(relativePath: { eq: "patient-profile-elisse.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      william: file(relativePath: { eq: "patient-profile-william.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      judy: file(relativePath: { eq: "patient-profile-judy.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      joan: file(relativePath: { eq: "patient-profile-joan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      karen: file(relativePath: { eq: "patient-profile-karen.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      daniela: file(relativePath: { eq: "patient-profile-daniela.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  switch (patientName) {
    case "Elisse":
      return data.elisse.childImageSharp.fluid.src;
    case "William":
      return data.william.childImageSharp.fluid.src;
    case "Judy":
      return data.judy.childImageSharp.fluid.src;
    case "Joan":
      return data.joan.childImageSharp.fluid.src;
    case "Karen":
      return data.karen.childImageSharp.fluid.src;
    case "Daniela":
      return data.daniela.childImageSharp.fluid.src;
    default:
      return data.elisse.childImageSharp.fluid.src;
  }
};
