import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SeeMacInsideStyles } from "../styles";

export const SeeMacInside = (props) => {
  let lungImg = "";
  let lungImgSrcSet = "";

  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        nodes {
          fluid {
            srcSet
            originalImg
            originalName
            src
          }
        }
      }
      allMarkdownRemark(
        filter: {
          frontmatter: { markdown_id: { eq: "culture-conversion-components" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              scanInstructions
            }
          }
        }
      }
    }
  `);

  data.allImageSharp.nodes.forEach((node) => {
    if (node.fluid.originalName === "lung-purp@2x.png") {
      lungImgSrcSet = node.fluid.srcSet;
      lungImg = node.fluid.src;
    }
  });

  const manipulateDOM = () => {
    const _seeMacContainer = document.querySelector(
      '[data-component="see-mac-inside"]'
    );
    const _seeMacContent = document.getElementById("see-mac-container");
	  if (_seeMacContainer && _seeMacContent) {
		_seeMacContainer.appendChild(_seeMacContent);
	  }
  };

  useEffect(manipulateDOM);

  const InstrunctionList =
    data.allMarkdownRemark.edges[0].node.frontmatter.scanInstructions.map(
      (instruction) => <li key={Math.random()}>{instruction}</li>
    );

  return (
    <>
      <SeeMacInsideStyles id={`see-mac-container`}>
        <img
          id="lung-purple"
          src={lungImg}
          srcSet={lungImgSrcSet}
          alt={`Purple Lung`}
        />

        <div id="instruction-container">
          <h2>To scan QR Code:</h2>
          <div>
            <img src={`/images/qr-code.svg`} alt={`QR Code`} />
            <ol>{InstrunctionList}</ol>
          </div>
        </div>
      </SeeMacInsideStyles>
    </>
  );
};
