import Player from "@vimeo/player";
import { useStaticQuery, graphql } from "gatsby";
import { interaction } from "../../Helpers";

const isDocument = typeof document !== "undefined";

export const vimeoPlayerHandler = (
  videoOuterID,
  vidID,
  autoplay,
  changeVideo,
  size
) => {
  let options = {
    id: vidID,
    width: size,
    loop: false,
    controls: true,
    autoplay,
  };

  let player = new Player(videoOuterID, options);

  // if this trigger is passed, then it will force the video to load
  if (changeVideo) {
    player.loadVideo(vidID);
  }

  player.setVolume(0.5);

  player.on("play", function () {
    // console.log("played the video!");
  });
};

// not needed. recommended to have a thumbnail in the hero prior to initiating vimeo video play
export const vimeoPlayAction = () => {
  let _player = "",
    data = "";

  if (isDocument) {
    _player = document.getElementById("hero-video-player").firstChild;
    data = { method: "play" };
  }
  _player.contentWindow.postMessage(JSON.stringify(data), "*");
};

export const vimeoPlayButtonHandler = () => {
  let _playButton = "";

  if (isDocument) {
    _playButton = document.querySelector("button.play-video-container");
  }

  _playButton.addEventListener(interaction, (e) => {
    e.preventDefault();
    // hard coded for the hero
    vimeoPlayerHandler("hero-video-player", 295466028, true);
    _playButton.style.display = "none";
  });
};

export const ModalImages = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "icon-close.svg" }, publicURL: {}) {
        publicURL
      }
    }
  `);

  return data.file.publicURL;
};

export const pauseVimeoPlayer = (videoOuterID, vidID) => {
  let _player = "";

  if (isDocument) {
    _player = document.querySelector("#" + videoOuterID + ">iframe");
  }

  _player.api("pause");
};

export const handleLock = (open) => {
  if (isDocument) {
    const _html = document.querySelector("html");

    if (open) {
      _html.classList.remove("locked");
    } else {
      _html.classList.add("locked");
    }
  }
};
