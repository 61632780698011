import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
	ModalContainer,
	FooterStyle,
} from "../styles";
import { InterstitialModal } from "./Modals";
import unbranded from "./Data/unbranded";
import { isWindow } from "./Helpers";

const InsmedLogo = ({ logo }) => {
	let src = ``;
	src =
		isWindow && unbranded.includes(window.location.pathname)
			? "/images/insmed-logo-purple.svg"
			: "/images/insmed-logo-white.svg";
	return <img src={src} alt={`Insmed Logo`} />;
};

setTimeout(() => {
	if (isWindow) {
		if (window.location.pathname.includes("why-treat")) {
			document
				.querySelectorAll("#footer-ul > li")
				.forEach((li) => (li.style.borderRightColor = "#440099"));
		}
	}
}, 800);

export const Footer = ({ markdownID }) => {
	const data = useStaticQuery(graphql`
		{
			file(
				relativePath: { eq: "insmed-logo-purple.svg" }
				publicURL: {}
			) {
				publicURL
			}
		}
	`);

	const insmedLogo = data.file.publicURL;

	const orientationDetection = () => {
		if (isWindow) {
			const _html = document.querySelector("html");

			if (window.innerWidth < 768) {
				if (window.matchMedia("(orientation: portrait)").matches) {
					_html.classList.add("portrait");

					if (_html.classList.contains("landscape")) {
						_html.classList.remove("landscape");
						_html.classList.add("portrait");
					}
				} else {
					_html.classList.add("landscape");

					if (_html.classList.contains("portrait")) {
						_html.classList.remove("portrait");
						_html.classList.add("landscape");
					}
				}
			} else {
				_html.classList.remove("portrait", "landscape");
			}
		}
	};

	useEffect(() => {
		if (isWindow) {
			orientationDetection();

			window.addEventListener("resize", orientationDetection);

			// setTimeout(() => {
			// 	document.querySelector("body").classList.add("loaded");
			// }, 800);

			window.onbeforeunload = () => {
				// document.querySelector("body").classList.remove("loaded");
				window.sessionStorage.setItem(`autoplay`, `false`);
				window.sessionStorage.setItem(`audio-init`, `false`);
			};

			if (window.location.href.indexOf("#isi") > -1) {
				window.location.replace("/important-safety-information");
			}
		}
	});

	return (
		<>
			{/* <RemoveStaticQueryWarning /> */}
			<FooterStyle
				className={markdownID === `ed-why-treat-mac` ? `unbranded` : ``}
			>
				<div id={`insmed-logo-mobile`} className={`mobile-logo`}>
					<InterstitialModal
						modalName={`insmed-logo-mobile-interstitial-modal`}
						trigger={<InsmedLogo logo={insmedLogo} />}
						disclaimer={false}
						setInner={false}
						externalURL={`https://insmed.com/`}
						injectorContainer={`interstitial-modal-wrap`}
					/>
				</div>
				<ul className={`wrapper`} id={`footer-ul`}>
					<li>
						<InterstitialModal
							className={`footer-links`}
							gtmClass={`gtm-footer`}
							modalName={`terms-of-use-interstitial-modal`}
							trigger={`Terms of use`}
							disclaimer={false}
							setInner={true}
							externalURL={`https://insmed.com/terms-of-use`}
							gtmLabel={`Terms of use`}
							injectorContainer={`interstitial-modal-wrap`}
						/>
					</li>
					<li>
						<InterstitialModal
							className={`footer-links`}
							gtmClass={`gtm-footer`}
							modalName={`privacy-policy-interstitial-modal`}
							trigger={`Privacy policy`}
							disclaimer={false}
							setInner={true}
							externalURL={`https://insmed.com/privacy-policy`}
							gtmLabel={`Privacy policy`}
							injectorContainer={`interstitial-modal-wrap`}
						/>
					</li>
					<li>
						<InterstitialModal
							className={`footer-links`}
							gtmClass={`gtm-footer`}
							modalName={`privacy-policy-interstitial-modal`}
							trigger={`Stay informed`}
							disclaimer={false}
							setInner={true}
							externalURL={`https://www.arikaycehcp.com/stay-informed`}
							gtmLabel={`Stay informed`}
							injectorContainer={`interstitial-modal-wrap`}
						/>
					</li>
					<li>
						<InterstitialModal
							className={`footer-links`}
							gtmClass={`gtm-footer`}
							modalName={`privacy-policy-interstitial-modal`}
							trigger={`Contact us`}
							disclaimer={false}
							setInner={true}
							externalURL={`https://www.arikaycehcp.com/contact-us`}
							gtmLabel={`Contact us`}
							injectorContainer={`interstitial-modal-wrap`}
						/>
					</li>
				</ul>

				<div className={`wrapper`}>
					<div id={`insmed-logo`}>
						<InterstitialModal
							modalName={`insmed-logo-interstitial-modal`}
							trigger={<InsmedLogo logo={insmedLogo} />}
							disclaimer={false}
							setInner={false}
							externalURL={`https://insmed.com/`}
							injectorContainer={`interstitial-modal-wrap`}
						/>
					</div>
					<p className={`tiny`}>
						This site is for US audiences only. © 2022 Insmed
						Incorporated. All Rights Reserved. Insmed, ARIKAYCE,
						Arikares, and PULMOVANCE are trademarks of Insmed
						Incorporated. All other trademarks are property of their
						respective owner. PP-ARIK-US-01563
					</p>
				</div>
			</FooterStyle>
			<ModalContainer id={`interstitial-modal-wrap`} className={"interstitial-modal"}></ModalContainer>
		</>
	);
};
