import React from "react";
import styled from "styled-components/macro";
import { device } from "../components/Helpers";
import { Link } from "gatsby";

export const ButtonWrapper = styled((props) => <Link {...props} />)`
  position: relative;
  padding: 0.5rem 0.75rem;
  background-color: ${(props) => props.theme.colors.brand_orange_00};
  border-radius: 0.35rem;
  line-height: 50px;
  color: ${(props) => props.theme.colors.light_1};
  font-weight: 700;
  font-size: 1rem;
  width: fit-content;
  transition: filter 0.3s ease;
  text-decoration: none !important;
  /* filter: brightness(70%); */

  &:hover,
  &:focus {
    /* filter: brightness(110%); */
  }

  .label {
    padding-right: 2rem;
  }

  &::after {
    position: absolute;
    border-left: 2px solid white;
    padding-left: 0.5rem;
    right: 0.5rem;
    top: -0.6rem;
    font-size: 1.5rem;
    content: "+";
  }
`;

export const BackToTopContainer = styled.div`
  position: relative;
  z-index: 99999;
`;

export const BackToTopButton = styled.div`
  background-color: white;
  border-radius: 0.25rem;
  bottom: 15rem;
  box-shadow: 0.1rem 0.1rem 0.5rem ${(props) => props.theme.colors.brand_black_00};
  display: inline;
  height: 50px;
  line-height: 55px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  position: fixed;
  right: 1rem;
  text-align: center;
  transition: 0.2s 0.2s ease-in;
  width: 50px;

  &:hover {
    cursor: pointer;
    transform-origin: center center;
    transform: scale(1.05);
  }

  transition: all 0.1s;
  > img {
    transform: rotate(270deg);
  }

  @media ${device.mobile} {
    bottom: 9.5rem;
    right: 1rem;
  }
`;
