import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const PatientProfileCardContainer = styled.div`
  position: relative;
  display: flex;
  margin: 2rem auto 0rem auto;
  background-image: url(${(props) => props.backgroundImage});
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 315px;
  height: 440px;
  border-radius: 0.5rem;
  text-align: left;

  @media ${device.betweenTabletAndDesktop} {
    width: 90%;
    height: 30rem;
    background-size: 100%;
  }

  @media ${device.mobile} {
    height: 30rem;
    width: 90%;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PatientProfileInnerWrap = styled.div`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  bottom: 1rem;
  left: 0;
  height: 6.1rem;
  margin-bottom: 0.75rem;
  position: absolute;
  opacity: 0.9;
  padding: 0.625rem 0rem 0.875rem 0.5rem;
  text-align: left;
  border-left: 0.8rem solid ${(props) => props.theme.colors.brand_orange_00};
  width: 103%;
  border-radius: 0.2rem;

  /* @media ${device.betweenTabletAndDesktop} {
    height: 11rem;
  } */

  display: flex;
  align-items: flex-start;

  @media ${device.mobile} {
    bottom: 0;
    height: 10rem;
    margin-bottom: 0;
  }
`;

export const PatientProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
  @media ${device.ie11} {
    width: 100%;
  }
  .header {
    font-size: 1.25rem;
    color: ${(props) => props.theme.colors.brand_purple_00};
    margin-bottom: 0.375rem;

    @media ${device.mobile} {
      font-size: 1.58333rem;
    }
  }

  .desc {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: ${(props) => props.theme.colors.brand_black_01};

    @media ${device.mobile} {
      font-size: 1.33333rem;
      line-height: 1.5rem;
    }
  }
`;
