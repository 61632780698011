import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const SitemapStyles = styled.main`
  margin-top: 2.5rem !important;
  @media ${device.mobile} {
    padding-top: 11rem !important;
    padding-bottom: 4rem !important;
  }
  .md-container {
    padding-top: 3rem;
    @media ${device.mobile} {
      padding-top: 0rem !important;
    }
  }

  h1 {
    margin-bottom: 3.5rem;
    margin-top: 2rem;
    text-align: center;
    @media ${device.mobile} {
      margin-top: 0;
      text-align: left;
      padding-left: 1rem;
    }
  }

  ul {
    margin-top: -1.1rem;

    & > li {
      margin-bottom: -0.5rem;
      @media ${device.mobile} {
        margin-bottom: 0.75rem;
      }
      &::before {
        content: none;
      }

      & > a {
        font-size: 1.15rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 3rem;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .list-container {
    display: flex;
    justify-content: space-around;
    @media ${device.mobile} {
      flex-direction: column;
    }
    & > div {
      &:last-child {
        @media ${device.mobile} {
          margin-top: 2.083rem;
        }
        & > ul {
          @media ${device.mobile} {
            margin-top: 0;
          }
        }
      }
    }
    & > div:first-child {
      ul > li {
        margin-bottom: 1.25rem;
        & > a {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
    }
    & > div:last-child {
      ul > li {
        margin-bottom: 1.25rem;
        & > a {
          font-size: 1.125rem;
          line-height: 1.3125rem;
        }
      }
    }
    ul {
      li {
        a {
          @media ${device.mobile} {
            font-size: 1.5833rem;
            &#terms-of-use-interstitial-container,
            &#for-patients-sitemap-interstitial-container {
              line-height: 19px;
            }

            &#for-patients-sitemap-interstitial-container {
              #for-patients-sitemap-interstitial-container-content {
                margin-bottom: 0.75rem;
              }
            }
            &.sm-buffer-top {
              margin-top: 0.75rem;
            }
          }
        }

        @media ${device.mobile} {
          margin-bottom: 0;
        }
      }
    }
  }
`;
