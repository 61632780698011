import React, { useState, useEffect } from "react";
import {
  ModalPopup,
  ModalContentWrap,
  ModalContent,
  ModalThirdParty,
} from "../../styles";
import { interaction } from "../Helpers";

export const ThirdPartyLinkModals = ({ externalURL }) => {
  const [open, setOpen] = useState(false);

  const stay = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const leave = (e) => {
    setOpen(!open);
  };

  const termsAndConditionHandler = () => {
    const _thirdPartyLinks = document.querySelectorAll(`.third-party-link`);
    
    Array.from(_thirdPartyLinks).forEach((el) => {
      el.addEventListener(interaction, (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        const _thirdPartyOK = document.querySelector(`#third-party-modal .interstitial-modal-buttons > a:last-of-type`);
        _thirdPartyOK.href = el.href;
        setOpen(true);
      });
    });
  };

  useEffect(() => {
    // const _html = document.querySelector(`html`);
    termsAndConditionHandler();
  }, [setOpen]);


  return (
    <ModalThirdParty id={`third-party-modal`} className={`interstitial-modal`}>
      <ModalPopup isOpen={open} className={open ? `locked` : ``}>
        <ModalContentWrap isOpen={open} className={"interstitial-content-wrap"}>
          <ModalContent>
            <div className="interstitial-modal-container">
              <div className="interstitial-modal-header">
                <h1>You are now leaving this website</h1>
                <br />
                
                  <p>
                    The site you are linking to is not controlled or endorsed by Insmed,
                    and we are not responsible for the content.
                  </p>
              </div>
              <div className="button-title">
                <h2>Thank you for visiting.</h2>
              </div>
              <div className="interstitial-modal-buttons">
                <a
                  href={`/#`}
                  onClick={stay}
                  onKeyDown={stay}
                  className="btn-1 gtm-cta"
                  data-gtm-event-label="cancel"
                >
                  <span className="label gtm-cta" data-gtm-event-label="cancel">
                    Cancel
                  </span>
                </a>
                <a
                  href={externalURL}
                  target="_blank"
                  rel="noreferrer"
                  onClick={leave}
                  className="btn-1 gtm-cta"
                  data-gtm-event-label="ok"
                >
                  <span className="label gtm-cta" data-gtm-event-label="ok">
                    OK
                  </span>
                </a>
              </div>
            </div>
          </ModalContent>
        </ModalContentWrap>
      </ModalPopup>
    </ModalThirdParty>
  );
};
