import React, { useEffect } from "react";

export const QueryInjector = ({ container, tag, content }) => {
  const manipulateDOM = () => {
    const _container = document.querySelector(container);
    const _content = document.getElementById(tag);

    _container.appendChild(_content);
  };

  useEffect(manipulateDOM);

  return <div id={tag}>{content}</div>;
};
