import React from "react";

export const ISITrayToggle = ({ trigger, handler }) => {
  return (
    <>
      <div role={`none`} className={`ISI-inner-toggle`} onClick={handler}>
        {trigger}
      </div>
    </>
  );
};
