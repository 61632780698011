import React, { useState } from "react";
import {
	ModalContainer,
	ModalPopup,
	ModalContentWrap,
	ModalContent,
	ModalClose,
} from "../../styles";
import { ModalImages, handleLock } from "./utils";
import { Injector } from "../Injector/partials";
import { AudioTranscript } from "../AudioTranscript";

export const ImageModal = ({
	zoom,
	setZoom,
	modalName,
	trigger,
	content,
	trackWProps,
	setTrackWProps,
	transcriptState,
	setTranscriptState,
	isiSnippets,
	container,
	trackKey
}) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
		handleLock(open);
		setZoom(!open);
		if (trackKey) {
			setTrackWProps(trackKey[parseInt(modalName.replace("-mobile-chart", ""))]);
		} else {
			setTrackWProps(parseInt(modalName.replace("-mobile-chart", "")));
		}
	};

	return (
		<>
			<Injector
				container={container}
				content={
					<ModalContainer id={modalName}>
						<div
							role={"button"}
							tabIndex={modalName}
							id={`${modalName}-trigger`}
							className="modal-trigger"
							onClick={handleClick}
							onKeyDown={handleClick}
							data-isi={isiSnippets}
						>
							{trigger}
						</div>
					</ModalContainer>
				}
			/>

			<ModalPopup isOpen={open} className="modal-content-wrap-wrap">
				<ModalContentWrap className={"image-modal scrollable-parent x"} isOpen={open}>
					<ModalContent>
						<span className={open ? "pinch-and-zoom" : ""} />
						<ModalClose
							IconClose={ModalImages()}
							setZoom={setZoom}
							onClick={handleClick}
							data-isi={"neuromuscular"}
						/>
						{content}
					<AudioTranscript id={`audio-transcript-${trackKey ? trackKey[parseInt(modalName.replace("-mobile-chart", ""))] : parseInt(modalName.replace("-mobile-chart", ""))}`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={trackWProps.transcript} />
					</ModalContent>
				</ModalContentWrap>
				{/* <div className="rotate-device">Please rotate device for best view.</div> */}
			</ModalPopup>
		</>
	);
};
