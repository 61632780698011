import React, { useEffect, useState, useRef } from "react";

export const MobileMenu = () => {
  let _navigation = useRef("");
  let _html = useRef("");

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    _navigation.current = document.getElementById("navigation");
    _html.current = document.querySelector("html");

    if (isOpen) {
      _navigation.current.classList.add("mobile-menu");
      _html.current.classList.add("menu-locked");
    } else {
      _navigation.current.classList.remove("mobile-menu");
      _html.current.classList.remove("menu-locked");
    }
  });

  return (
    <button
      id="mobile-menu"
      onClick={handleClick}
      data-gtm-event-label={`Menu`}
      className={isOpen ? `open gtm-click` : `gtm-click`}
    >
      <img src={isOpen ? `../images/icon-close.svg` : `../images/icon-hamburger-orange.svg`} alt={`Hamburger Menu`} />
      <span>{isOpen ? `CLOSE` : `MENU`} </span>
    </button>
  );
};
