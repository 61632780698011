import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const FooterStyle = styled.footer`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => (props.theme.colors.brand_purple_00)};
  padding: 5rem 0 20vw 0;
  z-index: 1;
  position: relative;
}

  @media ${device.mobile} {
    padding-top: 19.125rem;
    padding-bottom: 0rem;
  }

  #insmed-logo {
    bottom: 0;
    left: 0;
    position: absolute;
    @media ${device.mobile} {
      display: none;
    }
  }

  li {
    @media ${device.mobile} {
      font-size: 1.15rem;
      border-right: 2px solid ${(props) => props.theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 1.75rem;
    }
    &:before {
      content: none;
    }

    a {
      color: ${(props) => props.theme.colors.white};
      font-weight: bold;
    }
    &:last-child {
      @media ${device.mobile} {
        border-right: none;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 0 10rem 3rem;
    @media ${device.mobile} {
      flex-wrap: wrap;
      padding: 0;
      justify-content: center;
    }
    @media ${device.ie11} {
      justify-content: space-between;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    position: relative;

    & > p {
      margin-left: 9rem;
      width: 75%;
      @media ${device.mobile} {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-bottom: 9rem;
        font-size: 0.833rem;
        line-height: 1.15rem;
      }
    }
  }

  #insmed-logo-mobile {
    display: none;
    @media ${device.mobile} {
      display: block;
      text-align: center;
      width: 100%;
      justify-content: center;
      margin-bottom: 2.5rem;
    }
  }

  &.unbranded {
    .interstitial-content-wrap .interstitial-modal-buttons > a > span {
      color: ${(props) => props.theme.colors.white} !important;
  }
`;
