import React from "react";
import { ImageKitContainer } from "../../styles";
import { createMarkup } from "../Helpers";

export const ImageParser = ({ images, className }) => {
  const buildImages = () => {
    return images.map((image, i) => {
      return (
        <div key={`${i}-${image.altText}-${image.url}`} className="kit-image">
          <img className={className} src={image.url} alt={image.altText} />
          {images.length > 1 ? (
            <div
              className="alt-text"
              dangerouslySetInnerHTML={createMarkup(image.altText)}
            />
          ) : null}
        </div>
      );
    });
  };

  return <ImageKitContainer>{buildImages()}</ImageKitContainer>;
};
