import React, { useEffect } from "react";
import { adjustScrollPosition, interaction } from "./Helpers";

export const MobileSubNav = () => {
  const isWindow = typeof window !== `undefined`;

  const MobileSubNavSetup = () => {
    /**************************/
    /*
        start - mobile sub nav
        notes:
            sub navigation
            element to display
            scrolled sections
    */
    const _mobileSubDirection = document.querySelectorAll(
      "#mobile-subnav button"
    );

    let nextSection = "";
    let prevSection = "";

    ////////////////////////////////////////////////////////////////////////////////////////////////

    _mobileSubDirection.forEach((el) => {
      if (isWindow) {
        let _activeLink = "";
        let _lastItem = "";
        // let _firstItem = '';

        el.addEventListener(interaction, (e) => {
          _activeLink = document.querySelector(
            "#navigation > ul > li[data-link].active"
          );
          // _firstItem = document.querySelector('#navigation > ul > li[data-link]:nth-of-type(1)');
          _lastItem = document.querySelector(
            "#navigation > ul > li[data-link]:last-of-type"
          );

          el.classList.contains("next")
            ? document.querySelector("button.prev").classList.remove("inactive")
            : document
                .querySelector("button.next")
                .classList.remove("inactive");

          if (el.classList.contains("inactive")) {
            e.preventDefault();
          } else if (
            el.classList.contains("next") &&
            _activeLink.nextElementSibling !== null
          ) {
            nextSection = _activeLink.nextSibling.dataset.link;

            if (_lastItem.dataset.link === nextSection) {
              el.classList.add("inactive");
            }

            if (nextSection) {
              adjustScrollPosition(null, nextSection);
            }
          } else if (
            el.classList.contains("prev") &&
            _activeLink.previousElementSibling !== null
          ) {
            prevSection = _activeLink.previousElementSibling.dataset.link;

            if (_lastItem.dataset.link === prevSection) {
              el.classList.add("inactive");
            }

            if (prevSection) {
              adjustScrollPosition(null, prevSection);
            }
          } else return false;
        });
      }
    });

    /* end - mobile sub nav */
    /************************/
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  const initSubNav = () => {
    const _mobileSubNavIndicator = document.querySelector(
      "#mobile-subnav > div > p"
    );
    const _currentSection = document.querySelector(
      "#navigation > ul > [data-link]:nth-of-type(1)"
    );
    _mobileSubNavIndicator.innerHTML = _currentSection.innerText;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    MobileSubNavSetup();

    setTimeout(() => {
      if (
        document.querySelector("#navigation > ul > li") &&
        document.querySelector("button.prev")
      ) {
        document.querySelector("#navigation > ul > li").classList.add("active");
        document.querySelector("button.prev").classList.add("inactive");
      }

      if (document.querySelector("header").classList.contains("secondary")) {
        initSubNav();
      }

      if (isWindow) {
        window.addEventListener("scroll", () => {
          if (
            document.querySelector("#navigation > ul > li:last-of-type") &&
            document.querySelector("#navigation > ul > [data-link].active")
          ) {
            if (
              document.querySelector("#navigation > ul > li:last-of-type").dataset
                .link ===
              document.querySelector("#navigation > ul > [data-link].active")
                .dataset.link
            ) {
              document.querySelector("button.next").classList.add("inactive");
            } else {
              document.querySelector("button.next").classList.remove("inactive");
            }
          }
        });
      }

      setTimeout(adjustScrollPosition(), 500);
    }, 100);
  });

  ////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div id={`mobile-subnav`}>
      <div className={`wrapper`}>
        <button className={`prev`}>
          <span className={`seo`}>previous</span>
        </button>
        <p data-next-link={``}></p>
        <button className={`next`}>
          <span className={`seo`}>next</span>
        </button>
      </div>
    </div>
  );
};
