import React, { useEffect } from "react";
import { VideoWrapper } from "../../../styles";
import Player from "@vimeo/player";

const isBrowser = typeof window !== "undefined";

export const Video = ({ id, videoID, size }) => {
  useEffect(() => {
    if (isBrowser) {
      let options = {
        id: videoID,
        width: size,
        loop: false,
        controls: true,
      };

      let player = new Player(`${id}-container`, options);

      player.setVolume(0.5);
    }
  }, [id, videoID, size]);

  return (
    <VideoWrapper>
      <div id={`${id}-container`} className="video-player"></div>
    </VideoWrapper>
  );
};
