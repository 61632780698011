import styled from "styled-components";
import { device } from "../components/Helpers";

export const HomeCTAStyle = styled.div`
  background: ${(props) => props.theme.colors.brand_purple_01};
  border-radius: 0.35rem;
  padding: 3rem 1.5rem 0 1.5rem;
  margin: 0 1rem;
  width: 100%;
  position: relative;

  @media ${device.mobile} {
    padding: 3.5rem 1.5rem 0 1.5rem;
  }

  &::before {
    content: "";
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 4.65rem;
    left: 0;
    margin-top: -2.5rem;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.checkbox::before {
    background-image: url("../images/mac-negative-purple.svg");
  }

  &.group::before {
    background-image: url("../images/icon-patients-purple.svg");
  }

  &.clipboard::before {
    background-image: url("../images/clipboard.svg");
  }

  & > div {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin-bottom: 2.25rem;
    position: absolute;
    position: relative;
    width: 100%;
  }

  & > p {
    color: ${(props) => props.theme.colors.brand_purple_00};
    padding-bottom: 2rem;

    sup {
      position: relative;
    }
  }
`;
