import React from "react";
import { ImageModal } from "../Modals";
import { MobileChartCarousel } from "../Carousels";
import { ImageZoomMobile } from "../Partials";
import { createMarkup } from "../Helpers";

export const MobileImageInjector = ({
	zoom,
	setZoom,
	query,
	trackWProps,
	setTrackWProps,
	transcriptState,
	setTranscriptState,
	markdownID,
	trackKey
}) => {
	const buildImageType = (i, content, img) => {
		if (i !== 3) {
			return (
				<div className="image-modal-content">
					<div className="img-container">
						<MobileChartCarousel defaultSlide={i} slides={query} markdownID={markdownID} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="image-modal-content">
					<div
						className="content"
						dangerouslySetInnerHTML={createMarkup(content)}
					/>
					<div className="img-container">
						<img src={img} alt={``} />
					</div>
				</div>
			);
		}
	};

	const buildInjectors = () => {
		return query.map(({ container, content, img, isiSnippets }, i) => {
			return (
				<div key={`${i}-injected-mobile-chart`}>
					<ImageModal
						modalName={`${i}-mobile-chart`}
						trigger={
							<ImageZoomMobile
								zoom={zoom}
								setZoom={setZoom}
								content={content}
								img={img}
							/>
						}
						content={buildImageType(i, content, img)}
						trackWProps={trackWProps}
						setTrackWProps={setTrackWProps}
						transcriptState={transcriptState}
						setTranscriptState={setTranscriptState}
						setZoom={setZoom}
						isiSnippets={isiSnippets}
						container={container}
						trackKey={trackKey}
					/>
				</div>
			);
		});
	};

	return <>{buildInjectors()}</>;
};
