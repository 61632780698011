import React, { useState } from "react";
import { TabbingThroughStyles } from "../styles";

export const TabbingThrough = ({ id, resetSlides, tabs, setTrackWProps, setTranscriptState }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dropdownState, setDropdownState] = useState('');

  const setTab = (tabIndex) => {
    setActiveTab(tabIndex);
    resetSlides(tabIndex - 1);

    Array.from(document.querySelectorAll(`[data-tab-index]`)).forEach((el) => {
      setTimeout(() => { el.classList.remove(`show`); }, 100);
      setTimeout(() => { el.classList.remove(`visible`); }, 500);

      if (el.dataset.tabIndex === tabIndex.toString()) {
        setTimeout(() => { el.classList.add(`show`) }, 100);
        setTimeout(() => { el.classList.add(`visible`) }, 500);
      }
    });
  };

  const mobileSectTabTrigger = () => {
    (dropdownState === `active`) ? setDropdownState(``) : setDropdownState(`active`);
  };

  return (
    <>
      <TabbingThroughStyles className={dropdownState} id={id}>
        <ul>
          <button id={`tab-dropdown`} onClick={() => { mobileSectTabTrigger(); }}><span>tab dropdown</span></button>
          {
            tabs.map((tab, i) => {
              return (
                <li
                  className={i + 1 === activeTab ? `active` : null}
                  key={`tab-0${i}`}
                >
                  <button
                    className={"tab-btn gtm-click"}
                    data-gtm-event-label={tab.title}
                    data-isi={tab.isiSnippets}
                    onClick={() => {
                      setTab(i + 1);
                      setTrackWProps(i);
                      setDropdownState(``);
					  setTranscriptState(false);
					  document.querySelector('body').scrollTop = 0;
                    }
                    }>
                    {tab.title}
                  </button>
                </li>
              )
            })
          }
        </ul>
      </TabbingThroughStyles>
    </>
  )
};
