import React from "react";
import { createMarkup } from "../Helpers";

export const List = ({ title, items }) => {
  return (
    <div className={"bullet-list"}>
      <div
        className={"list-title"}
        dangerouslySetInnerHTML={createMarkup(title)}
      />
      <ul key={`${title}-list`}>
        {items.map((item, i) => {
          return (
            <li
              key={`${i}-${title}-${item}list-item`}
              dangerouslySetInnerHTML={createMarkup(item)}
            />
          );
        })}
      </ul>
    </div>
  );
};
