import styled from "styled-components";

export const CardWrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: columnn;
  align-items: center;

  &.content-card {
    flex-direction: column;
  }
`;
