import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const ModalContainer = styled.div`
	position: relative;
	z-index: 2;
	&.interstitial-modal {
		z-index: initial;
	}
	.modal-trigger,
	.modal-scan-trigger {
		cursor: pointer;
	}

	.modal-scan-trigger {
		position: relative;
		margin-top: 0.75rem;
		&::after {
			position: absolute;
			content: url("/images/icon-expand.svg");
			width: 20px;
			height: 20px;
			top: 0;
			right: 5px;
		}
	}
	.modal-content-wrap-wrap {
		padding-top: 3rem;
		top: 12rem;

		@media ${device.mobile} {
			left: 0;
			margin-top: 24rem;
			top: 0;
			width: 100%;
		}

		@media ${device.betweenTabletAndDesktop} {
			margin-top: -1.25rem;
		}
	}
	&.unbranded .landing-modal-container .are-you-hcp-modal-buttons > a,
	&.unbranded .landing-modal-container .are-you-hcp-modal-buttons > span {
		border: 1px solid red;
		justify-content: center;
		padding: 0;
	}

	&#terms-of-use-interstitial-modal,
	&#privacy-policy-interstitial-modal {
		z-index: 999999;
	}

	&#insmed-logo-interstitial-modal
		> div:last-of-type
		.interstitial-content-wrap {
		.button-title {
			color: ${(props) => props.theme.colors.brand_gray_03};
			font-size: 1.5rem;
		}

		.interstitial-modal-header {
			font-weight: bold;
		}
	}

	#primary-endpoint-popup-info-modal-trigger {
		position: relative;
		left: 9rem;
	}

	#secondary-endpoint-popup-info-modal-trigger {
		position: relative;
		right: 2rem;
	}

	&#patient-profile-daniela-modal-container,
	&#patient-profile-karen-modal-container {
		.patient-info-container .info-column .bottom-row .disclaimer {
			@media ${device.mobile} {
				margin: 0;
				top: 0;
			}
		}
	}

	&#patient-profile-karen-modal-container {
		.patient-info-container .info-column .bottom-row {
			margin-top: 3.75rem;
			@media ${device.mobile} {
				margin-top: 1rem;
			}
		}
		.audio-transcript {
			margin-top: -8.125rem;
			@media ${device.mobile} {
				margin-top: 0;
				z-index: 999;
			}
			#pi-link {
				display: none;
			}
		}
		#vimeo-player-mobile-wrapper {
			@media ${device.mobile} {
				display: none !important;
			}
		}
	}

	&#patient-profile-joan-modal-container {
		#vimeo-player-mobile-wrapper {
			@media ${device.mobile} {
				display: none !important;
			}
		}
	}

	&#patient-profile-william-modal-container {
		#vimeo-player-mobile-wrapper {
			@media ${device.mobile} {
				display: none !important;
			}
		}
	}

	&#patient-profile-daniela-modal-container {
		#vimeo-player-mobile-wrapper {
			@media ${device.mobile} {
				display: none !important;
			}
		}
	}

	&#patient-profile-daniela-modal-container {
		.patient-info-container {
			.mobileShow {
				& > h2 {
					padding-right: 0.8rem;
				}
			}
		}
	}

	&#patient-profile-daniela-modal-container {
		.patient-info-container {
			.info-column {
				.history-list {
					.history-col:first-child {
						.bullet-list:last-child {
							display: none;
						}
					}
				}
			}
		}
	}

	&#patient-profile-elisse-modal-container {
		.patient-info-container .info-column .bottom-row .disclaimer {
			/* margin-top: 2.75rem; */
			@media ${device.mobile} {
				margin-top: 1rem;
				font-size: 1rem;
			}
		}
		.patient-info-container .info-column .bottom-row .disclaimer {
			@media ${device.mobile} {
				margin-top: -3rem;
				font-size: 1rem;
			}
		}
	}

	&#patient-profile-judy-modal-container {
		.patient-info-container .info-column .bottom-row .disclaimer {
			@media ${device.mobile} {
				margin-top: -3rem;
				font-size: 1rem;
			}
		}
	}

	&#patient-profile-karen-modal-container {
		.patient-info-container .info-column .bottom-row .disclaimer {
			@media ${device.mobile} {
				display: none;
				/* font-size: 1rem;
        margin-top: -12rem;
        padding-bottom: 6.5rem; */
			}
		}
	}

	&#patient-profile-daniela-modal-container,
	&#patient-profile-elisse-modal-container,
	&#patient-profile-joan-modal-container,
	&#patient-profile-judy-modal-container,
	&#patient-profile-karen-modal-container,
	&#patient-profile-william-modal-container {
		.modal-content-wrap > div:last-of-type {
			//   position: absolute;
			//   top: 0;
		}
	}

	&#Judy-Before-mobile-modal,
	&#Elisse-Before-mobile-modal,
	&#Karen-Before-mobile-modal,
	&#Joan-Before-mobile-modal,
	&#Daniela-Before-mobile-modal,
	&#William-Before-mobile-modal {
		z-index: 1;
	}

	&#are-you-hcp-modal,
	&#insmed-logo-mobile-interstitial-modal {
		z-index: 999999;
		
		& > div:last-of-type:not(#insmed-logo-mobile-interstitial-modal-trigger) {
			@media ${device.mobile} {
				background-color: rgba(68, 0, 153, 0.5);
				margin-top: -50%;
				width: 100%;
			}

			h1 {
				@media ${device.mobile} {
					font-size: 19px;
				}
			}

			p {
				@media ${device.mobile} {
					color: ${(props) => props.theme.colors.brand_gray_03};
				}
			}
		}
		& > div:not(.modal-trigger) {
			top: calc(50% - 135.5px);
			&:before {
				content: "";
				position: absolute;
				top: -100%;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(68, 0, 153, 0.5);
				z-index: 100000;
			}
		}

		h1 {
			font-weight: bold;
		}
	}

	&#insmed-logo-mobile-interstitial-modal > div:last-of-type {
		@media ${device.mobile} {
			padding-top: 0;
			margin-top: -50%;
		}

		.interstitial-modal-container {
			@media ${device.mobile} {
				margin-top: -2rem;
			}

			.interstitial-modal-buttons {
				@media ${device.mobile} {
					margin-top: 2rem;
				}
			}
		}

		.interstitial-content-wrap {
			@media ${device.mobile} {
				height: 18.75rem;
			}
		}
	}

	&.interstitial-modal {
		& > div:last-of-type:not(.modal-trigger) {
			padding-top: 20vh;
			top: 0;

			@media ${device.mobile} {
				padding-top: 0;
				top: -100px;
			}
		}

		.modal-trigger {
			& > span {
				font-weight: bold;
				cursor: pointer;
				@media ${device.mobile} {
					font-weight: bold;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&#num3-interstitial-injector-modal > div {
		@media ${device.mobile} {
			width: 100%;
		}
	}

	&#num2-interstitial-injector-modal > div {
		@media ${device.mobile} {
			width: 100%;
		}
	}

	&#num0-interstitial-injector-modal > div {
		@media ${device.mobile} {
			width: 100%;
		}
	}

	&#num1-interstitial-injector-modal > div {
		@media ${device.mobile} {
			width: 100%;
		}
	}

	#patient-link {
		display: none;
		&::after {
			top: 0;
			right: -2px;
		}
	}
`;

export const ModalButton = styled.div`
	text-align: center;
	max-width: 200px;
	width: 100%;
	background-color: white;
	.modalButtonInnerWrap {
		height: 40px;
		line-height: 40px;
	}
`;

export const ModalAnchor = styled.div`
	text-decoration: none;
	z-index: 100;
`;

export const ModalPopup = styled.div`
	/* background: ${(props) => props.theme.colors.brand_white_00}; */
	background-color: ${(props) => props.theme.colors.brand_purple_02};
	display: ${(props) =>
		props.isOpen ? "block" : "none"}; /* Hidden by default */
	top: 246px;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed; /* Stay in place */
	z-index: 1000000; /* Sit on top */

	&.interstitial-popup, &.landing-popup.locked {
		top: 0 !important;
		display: ${(props) =>
			props.isOpen ? "flex" : "none"}; /* Hidden by default */
		flex-direction: column;
		justify-content: center;
		.interstitial-content-wrap {
			margin-top: 0;
		}
	}
	@media ${device.tablet} {
		top: 276px;
	}
	@media ${device.mobile} {
		top: 288px;
		bottom: 102px;
		&.unbranded {
			bottom: 0;
		}
	}

	.rotate-device {
		text-align: center;
		font-weight: bold;
		color: white;
		font-size: 1.33333rem;
		width: 180px;
		margin: 0 auto;
	}

	&#Judy-Before-mobile-modal-popup,
	&#Elisse-Before-mobile-modal-popup,
	&#Karen-Before-mobile-modal-popup,
	&#Joan-Before-mobile-modal-popup,
	&#Daniela-Before-mobile-modal-popup,
	&#William-Before-mobile-modal-popup {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(68,0,153,1);
	}
	&#primary-endpoint-popup-info-modal-popup {
		background-color: transparent;

		@media ${device.desktop} {
		}
	}
	&#secondary-endpoint-popup-info-modal-popup {
		background-color: transparent;

		@media ${device.desktop} {
		}
	}
`;

export const ModalContentWrap = styled.div`
	background-color: ${(props) => props.theme.colors.brand_white_00};
	border-radius: 0.25rem;
	margin: ${(props) => (props.isOpen ? "0" : "0%")} auto 0;
	transition: margin 0.2s ease-out;
	max-width: 1124px !important;
	min-width: 300px !important;
	width: calc(90% - 60px) !important;
	z-index: 100001;
	height: calc(100vh - 505px);
	/* margin-top: ${(props) => (props.isOpen ? "0" : "0%")}; */
	max-width: 70rem;
	overflow-y: scroll;

	.audio-transcript {
		@media ${device.mobile} {
			margin-top: 0;
			.header {
				margin-top: 0;
				margin-bottom: 0;
			}
			&.image-modal {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.ed-culture-conversion-content,
	.ed-culture-conversion-content:not(.nomargin) {
		@media ${device.mobile} {
			margin: 0 auto;
		}
		.slick-slider {
			.slick-arrow,
			.slick-dots {
				display: block;
				@media ${device.mobile} {
					display: none !important;
				}
			}
		}
	}
	@media ${device.desktop} {
		margin: 0 auto;
	}

	@media ${device.tablet} {
		height: calc(100vh - 535px);
	}
	@media ${device.mobile} {
		&.image-modal {
			margin-top: 30px;
			margin-left: auto;
			height: auto;
			max-height: calc(100vh - 445px);
			/* margin-top: ${(props) => (props.isOpen ? "16.5rem" : "0%")}; */
		}
		border-radius: 0.5rem;
		height: calc(100vh - 445px);
		// max-width: 85%;
		overflow-y: scroll;
	}

	@media ${device.desktop} {
		// max-width: 85%;
	}

	/* @media ${device.desktop_lg} {
    // margin-top: ${(props) => (props.isOpen ? "15.25rem" : "0%")};
    height: 52vh;
    margin-top: ${(props) => (props.isOpen ? "0" : "0%")};
    max-width: 70rem;
    overflow-y: scroll;
  } */

	&.image-modal {
		width: 90%;
	}

	&.landing-content-wrap,
	&.interstitial-content-wrap {
		height: 18.75rem;
		margin-top: 0;
		max-width: 33vw !important;
		overflow: hidden;
		position: relative;
		z-index: 999999;

		@media ${device.mobile} {
			height: 21.75rem;
			margin-top: 50vw;
			max-width: 85vw;
		}
	}

	&#num3-interstitial-injector-modal-content-wrap {
		left: 25%;
		margin-top: 0;
		@media ${device.mobile} {
			margin-top: 12.75rem;
			height: 19.75rem;
		}
	}

	&#num2-interstitial-injector-modal-content-wrap {
		left: -25%;
		margin-top: 0;
		@media ${device.mobile} {
			margin-top: 6.75rem;
			height: 19.75rem;
		}
	}

	&#num0-interstitial-injector-modal-content-wrap {
		left: 25%;
		margin-top: 3rem;
		@media ${device.mobile} {
			margin-top: 23.75rem;
			height: 20.75rem;
		}
	}

	&#num0-interstitial-injector-modal-content-wrap.unbrandedStyling {
		left: 0;
		margin-top: 3rem;
		@media ${device.mobile} {
			margin-top: 23.75rem;
		}
	}

	&#num1-interstitial-injector-modal-content-wrap {
		left: 25%;
		margin-top: 20rem;
		@media ${device.mobile} {
			margin-top: 46.75rem;
			height: 20.75rem;
		}
	}

	&.mobile-scan-modal-content {
		display: none;

		@media ${device.mobile} {
			display: block;
			margin-top: 45% !important;
			position: relative;
			background-image: url(${(props) => props.backgroundImage});
			width: 100%;
			// width: 400px;
			background-repeat: no-repeat;
			// max-height: 273px;
			// height: 100% ;
		}
	}

	//   margin: 11% auto;
	//   width: fit-content;
	max-width: unset;
	//   @media ${device.desktop}{
	// 	margin: 9% auto;
	//   }
	//   @media ${device.desktop_lg}{
	// 	margin: 5% auto;
	//   }
	//   @media ${device.desktop_xlg}{
	// 	margin: 8.75% auto;
	//   }
	//   @media ${device.tablet}{
	// 	margin: 5% auto;
	//   }

	//   &#primary-endpoint-popup-info-modal-content {
	//     margin: 11% auto;
	//     width: fit-content;
	//     max-width: unset;
	//     @media ${device.desktop}{
	//       margin: 9% auto;
	//     }
	//     @media ${device.desktop_lg}{
	//       margin: 5% auto;
	//     }
	//     @media ${device.desktop_xlg}{
	//       margin: 8.75% auto;
	//     }
	//     @media ${device.tablet}{
	//       margin: 5% auto;
	//     }
	//   }

	//   &#secondary-endpoint-popup-info-modal-content {
	//     margin: 11% auto;
	//     width: fit-content;
	//     max-width: unset;
	//     @media ${device.desktop}{
	//       margin: 9% auto;
	//     }
	//     @media ${device.desktop_lg}{
	//       margin: 5% auto;
	//     }
	//     @media ${device.desktop_xlg}{
	//       margin: 8.75% auto;
	//     }
	//     @media ${device.tablet}{
	//       margin: 5% auto;
	//     }
	//   }

	.md-container {
		font-size: 1.25rem;
		font-weight: 700;
		padding-top: 0;
	}
`;

export const ModalContent = styled.div`
	position: relative;
	@media ${device.mobile} {
		overflow: hidden;
	}
	&#primary-endpoint-popup-info-modal-content-inside {
		#pi-link {
			display: none;
		}
		.modal-popup-content {
			.copy-text {
				margin-left: 0;
				width: 100%;
			}
		}
		.tiny {
			color: ${(props) => props.theme.colors.brand_black_00};
			margin-top: 1.5rem;
		}
	}

	#vimeo-player-Judy-mobile-container {
		& > iframe {
			width: 100%;
			height: 100%;
		}
	}

	#vimeo-player-Elisse-mobile-container {
		& > iframe {
			width: 100%;
			height: 100%;
		}
	}
	#vimeo-player-mobile-wrapper {
		.disclaimer {
			@media ${device.mobile} {
				display: none !important;
			}
		}

		.patient-info-video-header {
			color: ${(props) => props.theme.colors.brand_orange_00};
		}
	}
	.image-modal-content {
		.content {
			color: ${(props) => props.theme.colors.brand_purple_00};
			font-size: 0.75rem;
			padding: 3.75rem 1.25rem 1.25rem 1.25rem;

			p {
				color: ${(props) => props.theme.colors.brand_gray_03};

				&.purple {
					color: ${(props) => props.theme.colors.brand_purple_00};
				}

				@media ${device.mobile} {
					line-height: 0.5rem !important;
					font-size: 0.25rem !important;
				}

				&:nth-of-type(1) {
					@media ${device.mobile} {
						line-height: 1rem !important;
						font-size: 0.75rem !important;
						padding-right: 1.5rem;
					}
				}
			}

			sup {
				@media ${device.mobile} {
					font-size: 0.5rem;
				}
			}
		}

		.img-container {
			width: 100%;
			padding: 1.25rem 1.25rem 3.75rem 1.25rem;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.landing-modal-container,
	.interstitial-modal-container {
		display: flex;
		flex-direction: column;
		padding: 1.875rem 4.375rem;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 23.188rem;
		margin-top: -1.5rem;
		@media ${device.mobile} {
			padding: 1rem 2rem;
		}

		.modal-header,
		.copy {
			margin-bottom: 1.25rem;
		}

		.interstitial-modal-header {
			h1 {
				margin-bottom: 1.25rem;
				line-height: 1;
			}
		}

		.modal-header {
			line-height: 24px;
			@media ${device.mobile} {
				h1 {
					font-size: 19px;
					font-weight: 600;
				}
			}
		}

		.copy {
			@media ${device.mobile} {
				p {
					line-height: 19px;
				}
			}
		}

		.are-you-hcp-modal-buttons {
			margin-top: 0.5rem !important;
			.btn-1 {
				width: 6.66rem;
				height: 2.8rem;
			}
		}

		.are-you-hcp-modal-buttons,
		.interstitial-modal-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			width: 20vw;
			margin: 2.5rem auto 0 auto;

			@media ${device.mobile} {
				flex-direction: row;
				width: auto;
				margin: 0;
				margin-top: 1rem;
			}

			.btn-1 {
				cursor: pointer;
				> img {
					width: 0.8rem;
					height: auto;
					top: 30%;
					@media ${device.ie11} {
						height: 0.8rem;
					}
				}
				@media ${device.mobile} {
					width: 5rem;
				}
				&:first-child {
					margin-right: 0.6665rem;
				}
				&:last-child {
					margin-left: 0.6665rem;
				}
			}
		}

		.interstitial-modal-buttons {
			.btn-1 {
				text-decoration: none !important;
				justify-content: center;
				font-size: 1rem;
				padding: 0 10% 0 10%;
			}
		}
	}

	.patient-info-container {
		display: ${(props) => (props.showVideo ? "none" : "flex")} !important;
		flex-direction: row;
		// padding: 1.3rem;
		height: 650px;
		// padding-right: 15px;
		box-sizing: content-box;
		width: 100%;
		font-size: 16px;
		line-height: 19px;

		@media ${device.tabletBelow} {
			height: 100%;
			flex-direction: column;
			box-sizing: border-box;
			overflow: auto;
		}

		@media ${device.mobile} {
			width: 100%;
			overflow: hidden;
		}

		.info-column {
			@media ${device.mobile} {
				padding-right: 0 !important;
			}

			.bullet-list ul > li .disclaimer {
				@media screen and (min-width: 992px) {
					display: none !important;
				}

				@media ${device.mobile} {
					display: flex;
					font-size: 12px;
					justify-content: flex-end;
				}
			}
		}

		.mobileonly {
			h2 {
				@media ${device.tabletBelow} {
					padding-right: 2.5rem;
					font-size: 1.5833rem;
					line-height: 1.9166rem;
					word-spacing: 0.15rem;
					margin-bottom: 1rem;
				}
			}
			.copy {
				@media ${device.tabletBelow} {
					font-size: 1.3333rem;
					line-height: 1.2rem;
					margin: 1.25rem 0 !important;
				}
			}
		}

		.copy {
			@media ${device.tabletBelow} {
				font-size: 16px;
				line-height: 19px;
				margin: 1.25rem 0 !important;
			}
		}

		.img-column {
			display: flex;
			flex-direction: column;
			padding-right: 2rem;
			@media ${device.tabletBelow} {
				padding-right: 0;
			}
			img {
				width: 220px;
				//max-height: 300px;
				height: auto;
				margin-bottom: 1.5rem;
				@media ${device.tabletBelow} {
					width: 100%;
					max-height: none;
				}
			}

			.scan-title {
				color: ${(props) => props.theme.colors.brand_purple_00};
				font-weight: 600;
				margin-bottom: 0.5rem;
				font-size: 1.25rem;
				@media ${device.tabletBelow} {
					font-size: 1.5833rem;
				}
			}
		}

		.info-column {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-right: 19px;
			h2 {
				margin-bottom: 0.6rem;
			}

			.copy {
				margin-bottom: 1.25rem;
			}

			.history-list {
				display: flex;
				flex-direction: row;
				justify-content: start;
				margin-bottom: 0.5rem;

				@media ${device.tabletBelow} {
					flex-direction: column;
				}

				.history-col {
					max-width: 340px;

					@media ${device.tabletBelow} {
						width: 100%;
					}
					&:first-child {
						margin-right: 3rem;
					}
				}

				.bullet-list {
					display: flex;
					flex-direction: column;
					ul > li > ul {
						padding-top: 0.75rem;
					}
					& > ul {
						margin-bottom: 0.675rem;
						& > li {
							@media ${device.mobile} {
								font-size: 1.3333rem;
							}
						}
					}
				}

				.list-title {
					font-weight: 600;
					// margin-top: .5rem;
					margin-bottom: 12px;
					color: ${(props) => props.theme.colors.brand_orange_00};
					@media ${device.mobile} {
						font-size: 1.3333rem;
					}
				}
			}

			.bottom-row {
				display: inherit;
				margin-top: auto;
				margin-right: 1.75rem;
				flex-direction: row;
				justify-content: space-between;

				@media ${device.tabletBelow} {
					flex-direction: column;
					margin-right: 0;
				}

				.disclaimer {
					/* display: block !important; */

					/* @media ${device.mobile} {
            display: none !important;
          } */
				}

				#vimeo-player-mobile-wrapper {
					@media ${device.mobile} {
						max-width: unset;
					}
					@media ${device.tabletBelow} {
						display: block !important;
					}
				}

				#vimeo-player-wrapper {
					&.patient-info-video {
						@media ${device.tabletBelow} {
							display: none !important;
						}
					}
				}

				.disclaimer {
					font-size: 0.8rem;
					// margin-top: auto;
					margin-right: auto;

					@media ${device.mobile} {
						font-size: 1rem;
						margin-left: auto;
						margin-right: 1.25rem;
						margin-top: -3rem;
						padding-bottom: 1rem;
						position: relative;
						text-align: right;
						top: 15.5rem;
					}
				}

				& > .callout {
					margin-right: 5rem;

					@media ${device.tablet} {
						margin-right: 0;
					}
				}

				.callout {
					.btn-2 {
						line-height: 2rem;
						@media ${device.mobile} {
							display: none;
						}
					}
				}

				a.btn-2 {
					font-size: 1.125rem;
					line-height: 1.125rem !important;
					padding-right: 4rem;
					padding-left: 1.5rem;
					::after {
						top: -7px;
						height: 3.5rem;
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.patient-info-video {
		display: ${(props) => (props.showVideo ? "flex" : "none")};
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 1.3rem;

		@media ${device.tabletBelow} {
			/* display: block; */
			max-width: 400px;
			width: 100%;
			padding: 1.3rem 0;
			height: 100%;
		}
		.patient-info-video-innerwrap {
			margin: 0 auto;
			position: relative;

			& > div:nth-of-type(2) {
				@media ${device.mobile} {
					position: relative;
				}
			}

			.modal-close {
				display: none;
				left: unset;
				margin-right: -0.95rem;
				margin-top: -0.45rem;
				position: absolute;
				right: 0;
				top: 0;
			}

			.patient-info-video-header {
				text-align: left;
				font-size: 1.25rem;
				font-weight: 600;
				color: ${(props) => props.theme.colors.brand_purple_00};
				margin-bottom: 1.25rem;

				@media ${device.mobile} {
					font-size: 1.33333rem;
				}
			}
		}
	}

	.modal-popup-content {
		padding: 1.875rem 4.375rem;
		overflow: hidden;
		z-index: 100;

		@media ${device.mobile} {
			// height: 500px;
			/* padding: 1rem; */
			padding: 3.5rem 1.25rem 1rem 1.25rem;
		}

		&.overflow {
		}
		.smalltext {
			p {
				font-size: 12px;
			}
		}
		.modal-header {
			color: ${(props) => props.theme.colors.brand_purple_00};
			font-size: 1.25rem;
			font-weight: 600;
			margin-bottom: 1.25rem;
			& > div {
				@media ${device.mobile} {
					font-size: 1.5833rem;
					padding-right: 1rem;
				}
			}
			span {
				font-weight: normal;
			}
			p {
				color: ${(props) => props.theme.colors.brand_gray_03};
				font-size: 1rem;
				font-weight: normal;
				margin-top: 1rem;
				margin-bottom: 0.75rem;
			}

			.warnings-header {
				font-size: 1.75rem;
				font-weight: normal;
				margin-bottom: 2.5rem;
			}

			.md-container {
				padding-top: 0.875rem;
			}

			.md-container {
				padding-top: 0.875rem;
			}
		}

		div {
			&.hang-reference {
				display: flex;
				flex-direction: row;
				&.smalltext {
					p {
						margin-bottom: 0.15rem;
					}
				}
			}
		}

		.md-container {
			p {
				color: ${(props) => props.theme.colors.brand_gray_03};
				font-size: 1rem;
				font-weight: normal;
				margin-top: 1rem;
				position: relative;
				.hang {
					position: absolute;
					left: -6px;
				}
			}
			p.small {
				font-size: 0.75rem;
				font-weight: normal;
			}
		}
		.modal-chart {
			width: 100%;
			height: auto;
			margin-bottom: 1rem;
			max-width: 895px;
		}

		.kit-image {
			text-align: center;
			// margin: 10px auto 2.625rem;
			margin: 10px auto;
			width: 90%;
			height: auto;

			@media ${device.mobile} {
				width: 100%;
				margin: 1.25rem auto;
			}

			&:first-of-type {
				.alt-text {
					left: 15%;
					@media ${device.mobile} {
						left: auto;
					}
				}
			}

			&:nth-of-type(2) {
				.alt-text {
					left: 25%;
					bottom: -64px;

					@media ${device.mobile} {
						bottom: 0;
						left: auto;
					}
				}
			}
		}

		p {
			margin-bottom: 0.75rem;
		}

		.boxed-warning {
			padding: 0.75rem 0.75rem 0.5rem;
			margin-top: -1rem;
			margin-bottom: 1rem;
			border: 2px solid black;
			font-weight: 600;

			p:nth-of-type(2) {
				margin-bottom: 0.3rem;
			}
		}
	}

	.pinch-and-zoom {
		position: absolute;
		opacity: 0;
		background-image: url("/images/icon-pinch-and-zoom.svg");
		background-repeat: no-repeat;
		width: 138px;
		height: 150px;
		left: 30%;
		top: 30%;
		z-index: 20000;
		animation-duration: 3s;
		animation-name: fadeOut;
		animation-timing-function: linear;
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		90% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;

export const ModalClose = styled.div`
	background-color: #d34604;
	background-image: url(${(props) => props.IconClose});
	background-position: center;
	background-repeat: no-repeat;
	background-size: 25px 25px;
	border-radius: 0.25rem;
	cursor: pointer;
	height: 40px;
	left: 69rem;
	margin-right: 0.5rem;
	padding: 0.375rem;
	position: sticky;
	right: 0;
	top: 1rem;
	width: 40px;
	z-index: 1;

	@media ${device.mobile} {
		height: 2rem;
		width: 2rem;
		background-size: 1rem 1rem;
		z-index: 2;
	}

	&.mobile-scan-modal-close {
	}
`;

export const ModalThirdParty = styled.div`
	position: relative;

	& > div:last-of-type {
		padding-top: 15vw;
		top: 0;
	}

	.leaving-content-wrap {
		height: 20rem;
		overflow: hidden;
	}

	.modal-content-wrap-wrap {
		padding-top: 27px;
		top: 216px;

		@media ${device.mobile} {
			left: 0;
			margin-top: 21.5rem;
			top: 0;
			width: 100%;
		}
	}
`;

export const ModalLeaving = styled.div`
  position: relative;

  &> div:last-of-type {
    padding-top: 15vw;
    top: 0;
  }

  .leaving-content-wrap {
    height: 20rem;
    overflow:hidden;
  }
  
  .modal-content-wrap-wrap {
    padding-top: 27px;
    top: 216px;

    @media ${device.mobile}{
      left: 0;
      margin-top: 21.5rem;
      top: 0;
      width: 100%;
    }
`;
