import React from "react";
import {
  PatientProfileCardContainer,
  PatientProfileInnerWrap,
  PatientProfileContent,
} from "../styles";
import { createMarkup } from "./Helpers";

export const PatientProfileCard = ({
  backgroundImage,
  id,
  title,
  copy,
  isiSnippets,
  altText
}) => {

  return (
    <>
      <PatientProfileCardContainer
        id={id}
        className={`patient-profile-card`}
        backgroundImage={backgroundImage}
		alt={altText}
		data-isi={isiSnippets}
      >
        <PatientProfileInnerWrap>
          <PatientProfileContent>
            <div
              className="header"
              dangerouslySetInnerHTML={createMarkup(title)}
            />
            <div
              className="desc"
              dangerouslySetInnerHTML={createMarkup(copy)}
            />
          </PatientProfileContent>
        </PatientProfileInnerWrap>
      </PatientProfileCardContainer>
    </>
  );
};
