import React, { useEffect } from "react";
import { NavigationStyling } from "../styles";
import { Link, useStaticQuery, graphql } from "gatsby";
import { interaction } from "./Helpers";
import { isWindow } from "./Helpers";

const MainNav = (props) => {
  const componentID = props.componentID;
  const markdownNodes = props.query.allMarkdownRemark.nodes;

  let navList = [];

  markdownNodes.forEach((el) => {
    if (el.frontmatter.subnavItems !== null) {
      navList[parseInt(el.frontmatter.pageOrder)] = el.frontmatter;
    }
  });

  const NavItems = (props) => {

    const parentNav = () => {
      return props.navList.map((el, i) => {
        return (
            <li
              key={`${i}-${el.markdown_id}-${el.pageOrder}-parent-nav-item-list-item`}
              className={el.markdown_id === componentID ? `active` : ``}
            >
              <Link
                key={`${i}-${el.markdown_id}-${el.pageOrder}-parent-nav-item-child`}
                to={`/` + el.parentNavItem[0].path}
                className={`nav-right-arrow  gtm-nav`}
                data-gtm-event-label={el.parentNavItem[0].copy}
              >
                {el.parentNavItem[0].copy}
              </Link>
            </li>
        );
      });
    };

    return (
      <>
        <nav>
          <ul>
			  {parentNav()}
			  { isWindow && window.innerWidth < 768 ?
			    <>
			  	<li key={`99-0-0-parent-nav-item-child`}><a href="https://www.arikayce.com/pdf/medication-guide.pdf" target="_blank" rel="noreferrer" className="nav-right-arrow  gtm-nav" data-gtm-event-label="medication-guide">Medication guide</a></li>
			  	<li key={`99-0-1-parent-nav-item-child`}><a href="https://www.arikayce.com/pdf/instructions_for_use.pdf" target="_blank" rel="noreferrer" className="nav-right-arrow  gtm-nav" data-gtm-event-label="instructions_for_use">Instructions for use</a></li>
				</>
			  : null }
		  </ul>
        </nav>
      </>
    );
  };

  useEffect(() => {
    const _subLinks = document.querySelectorAll("nav > ul > li > a")
      ? document.querySelectorAll("nav > ul > li > a")
      : "";

    if (document.documentElement.clientWidth < 768) {
      _subLinks.forEach((el) => {
        el.addEventListener(interaction, (e) => {
          e.preventDefault();

          _subLinks.forEach((la) => {
            la.parentNode.classList.remove("open");
          });

          if (el.parentNode.parentNode.classList.contains("utility-links")) {
            el.parentNode.classList.contains("open")
              ? el.parentNode.classList.remove("open")
              : el.parentNode.classList.add("open");
          } else {
            if (isWindow) {
              window.location = el.href;
            }
          }
        });
      });
    }
  });

  return (
    <>
      <NavItems navList={navList} />
    </>
  );
};

export const Navigation = (props) => {
  const componentID = props.componentID;
  const noSubNav = props.noSubNav;

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            markdown_id
            subnavItems {
              copy
              path
              sectionRef
            }
            pageOrder
            parentNavItem {
              copy
              path
            }
          }
          id
          html
        }
      }
    }
  `);

  const scrollSpying = () => {
    const checkScrolling = () => {
    //   const _sections = document.querySelectorAll("section[id]");

    //   _sections.forEach((el) => {
        // let elemPosition = el.getBoundingClientRect();

        // if (elemPosition.top <= window.innerHeight * 0.35) {
        //   document
        //     .querySelector('#navigation > ul > [data-link="' + el.id + '"]')
        //     .classList.add("active");

        //   if (
        //     document.querySelector(
        //       '#navigation > ul > [data-link="' + el.id + '"]'
        //     ).previousSibling !== null
        //   ) {
        //     document
        //       .querySelector('#navigation > ul > [data-link="' + el.id + '"]')
        //       .previousSibling.classList.remove("active");
        //   }
        // } else {
        //   document
        //     .querySelector('#navigation > ul > [data-link="' + el.id + '"]')
        //     .classList.remove("active");
        // }
    //   });
    };

    setTimeout(() => {
      checkScrolling();

      if (isWindow) {
        window.addEventListener("scroll", checkScrolling);
      }
    }, 1000);
  };

  const manipulateDOM = (action) => {
    const _currentPage = document.querySelector("nav > ul > li.active");

    if (
      document.querySelector("header").classList.contains("secondary") &&
      _currentPage
    ) {
      _currentPage.addEventListener(interaction, (e) => {
        e.preventDefault();
      });
    }
  };

  useEffect(() => {
    scrollSpying();
    manipulateDOM();
  });

  return (
    <NavigationStyling id={`navigation`}>
      <MainNav query={data} componentID={componentID} noSubNav={noSubNav} />
    </NavigationStyling>
  );
};
