import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const AudioNavigatorStyles = styled.div`

@media only screen and (min-width: 1200px) {
  transform: scale(100%) translate(2rem,.5rem);
}

.rhap_controls-section, .rhap_stacked {
  display:none;
}
&.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.autoplay-box {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 44px;
  left: 211px;
  
  @media ${device.mobile}{ 
    left: unset;
    margin-right: 8%;
    right: 0;
    top: 3.05rem;
  }

  p:nth-of-type(1){
    cursor:default;
  }

  p {
    font-weight:bold;
    font-size:12px;
  }

  a.transcript {
      color: ${(props) => (props.theme.colors.white)};
      font-weight: bold;
      left: 0;
      margin-top: 1rem;
      position: absolute;
      text-decoration: underline;
      top: 0;

      @media ${device.mobile} {        
        left: 0;
        margin-top: 1.5rem;
        top: 0;
      }
      p {
        cursor:pointer;
      }
    }
  }
}

  #audio-title {
      color:white;
      font-size:16px;
      font-weight:bold;
      left: 98px;
      position: relative;
      top: 12px;
      cursor:default;

      @media ${device.mobile}{
        font-size:14px;
        left: 0;
        top: 0;
      }
  }

  .btn-toggle{
      position: relative;
      width:64px;
      height:17px;
      left: 10px;
  }


  .btn-toggle > p {
      color:white;
      font-size:12px;
      font-weight:bold;
  }

  .off,.on {
    color:#97999B;
    text-align: center;
    width:32px;
    height:17px;
    border:none;
    font-weight:bold;
    cursor:pointer;
  }
  .on {
    border-top-left-radius: 3.19px;
    border-bottom-left-radius: 3.19px;
  }
  .off {
    border-top-right-radius: 3.19px;
    border-bottom-right-radius: 3.19px;
  }
  .off.active, 
  .on.active{
      background-color:#CE4A03;
      color:white;
  }
  

  .pause,
  .play {
      background-size: 64.5px 64.5px;
      cursor: pointer;
      height: 64.5px;
      left: 10.5px;
      position: absolute;
      top: 16.5px;
      width: 64.5px;
      z-index:2;

      @media ${device.mobile} {
        background-size: 48px 48px;
        height: 48px;
        left: 0.8rem;
        top: 2.75rem;
        width: 48px;
      }
  }

  .play { 
    background-image: url('../images/audio-player/play.svg');
    background-repeat: no-repeat; 
  }

  .pause  { 
    background-image: url("../images/audio-player/pause.svg");
    background-repeat: no-repeat;
  }

  .mute {
    background-image: url("../images/audio-player/mute.svg");
    background-size:39px 39px;
    width: 39px;
    height: 39px;
    position: absolute;
    top: 43px;
    left: 153px;
    opacity:100%;
    cursor:pointer;

    &.active {
      opacity:40%;
    }

    @media ${device.mobile}{
      display:none;
    }
}

  .replay {
      background-image: url("../images/audio-player/replay.svg");
      background-size:39px 39px;
      cursor: not-allowed;
      height: 39px;
      left:98px;
      opacity:40%;
      position: absolute;
      top: 43px;
      width: 39px;
      
      &.active {
        cursor: pointer;
        opacity:100%;
      }

      @media ${device.mobile}{
        background-size:30px 30px;
        height:30px;
        left: 6.25rem;
        top: 3.5rem;
        width:30px;
      }
  }

  svg {
      height: 91px;
      position: absolute;
      right: 350.5px;
      top: 4px;
      transform: rotateY(-180deg) rotateZ(-90deg);
      width: 90px;

      @media ${device.mobile}{
        /* height: 70px;
        left: 0;
        margin-left: .75%;
        top: 2.25rem;
        width: 70px; */
        height: 87px;
        left: 0;
        top: 1.115rem;
        transform: scale(97%) translateY(-1px) translateX(-11px) rotateY(-180deg) rotateZ(-90deg);
        width: 82px;
      }
    }
    
    svg circle {
      stroke-dasharray: 205px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 4px;
      stroke: #CE4A03;
      fill: none;

      @media ${device.mobile}{
        stroke-dasharray: 161px;
      }
    }

    #circle.animate {
      animation: timer linear ;

      @media ${device.mobile}{
        animation-name: timer-mobile;
        animation-timing-function: linear;
        /* animation-direction: reverse; */
      }
    }
    
    @keyframes timer {
      from {
        stroke-dashoffset: 0px;
      }
      to {
        stroke-dashoffset: 205px;
      }
    }

    @keyframes timer-mobile {
      from {
        stroke-dashoffset: 0px;
      }
      to {
        stroke-dashoffset: 161px;
      }
    }
`;
