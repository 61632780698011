import React, { useState } from "react";
import { createMarkup } from "./Helpers";
import { FootnotesContainer, FootnotesHeader } from "../styles";

export const Footnotes = ({ markdownID, footnotes, isBottom }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const isMore = () => {
    if (footnotes.length > 1) {
      return true;
    }
  };

  return (
    <FootnotesContainer
      className={`footnote ${isBottom ? "bottom-footnotes" : ""}`}
      isOpen={isOpen}
      markdownID={markdownID}
    >
      <FootnotesHeader>{isMore() ? "Footnotes" : "Footnote"}</FootnotesHeader>
      <div className="footnotesAccordion">
        <div
          className="trigger gtm-accordian"
          onClick={handleClick}
          onKeyDown={handleClick}
          aria-hidden="true"
          data-gtm-event-label={`Footnote`}
        >
          {isOpen ? "-" : "+"}
        </div>
        <div className="footnotes">
          {footnotes.map((footnote, i) => {
            return (
              <p
                key={`footnote-${i}`}
                dangerouslySetInnerHTML={createMarkup(footnote)}
              />
            );
          })}
        </div>
      </div>
    </FootnotesContainer>
  );
};
