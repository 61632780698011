import React, { useState, useLayoutEffect } from "react";
import {
	ModalContainer,
	ModalPopup,
	ModalContentWrap,
	ModalContent,
	ModalClose,
} from "../../styles";
import { ModalImages, handleLock } from "./utils";
import { createMarkup } from "../Helpers";
import { Injector } from "../Injector/partials";

export const InfoModal = ({
	modalName,
	audioSrcTrack,
	trigger,
	content,
	container,
	setInner,
	closegtm,
	isiSnippets,
	setTrackWProps,
	modalI,
}) => {
	const [open, setOpen] = useState(false);

	useLayoutEffect(() => {
		if (typeof window != "undefined") {
			document
				.querySelectorAll(".prevNavBtn, .nextNavBtn")
				.forEach((el) => {
					el.addEventListener("click", () => {
						setOpen(false);
						handleLock(true);
						document.querySelector("#overlay").style.display =
							"none";
						document.querySelector(".slick-slider").style.zIndex =
							"1";
						document
							.querySelectorAll(".slick-dots")
							.forEach((el) => el.classList.remove("hide"));
						document
							.querySelectorAll(".slick-arrow")
							.forEach((el) => el.classList.remove("hide"));
					});
				});
		}
	}, [open, setOpen]);
	const handleClick = (e) => {
		e.preventDefault();
		setOpen(!open);
		handleLock(open);
		setTrackWProps(open ? 0 : modalI + 1);

		if (
			typeof window != "undefined" &&
			window.location.pathname.includes("culture-conversion")
		) {
			document.querySelector("#overlay").style.display = "block";
			document.querySelector(".slick-slider").style.zIndex = "99";
			document
				.querySelectorAll(".slick-dots")
				.forEach((el) => el.classList.add("hide"));
			document
				.querySelectorAll(".slick-arrow")
				.forEach((el) => el.classList.add("hide"));

			if (open) {
				document.querySelector("#overlay").style.display = "none";
				document.querySelector(".slick-slider").style.zIndex = "1";
				document
					.querySelectorAll(".slick-dots")
					.forEach((el) => el.classList.remove("hide"));
				document
					.querySelectorAll(".slick-arrow")
					.forEach((el) => el.classList.remove("hide"));
			}
		}
	};

	return (
		<>
			<Injector
				container={container}
				content={
					<ModalContainer id={modalName}>
						<div
							role={"button"}
							tabIndex={modalName}
							id={`${modalName}-trigger`}
							className="modal-trigger"
							data-track={audioSrcTrack}
							data-isopen={open}
							onClick={handleClick}
							onKeyDown={handleClick}
							data-isi={isiSnippets}
						>
							{setInner ? (
								<span
									dangerouslySetInnerHTML={createMarkup(
										trigger
									)}
								/>
							) : null}
						</div>
					</ModalContainer>
				}
			/>
			<ModalPopup
				isOpen={open}
				id={`${modalName}-popup`}
				className="modal-content-wrap-wrap"
			>
				<ModalContentWrap
					isOpen={open}
					id={`${modalName}-content`}
					className={"modal-content-wrap scrollable-parent"}
				>
					<ModalContent id={`${modalName}-content-inside`}>
						<ModalClose
							IconClose={ModalImages()}
							onClick={handleClick}
							onKeyDown={handleClick}
							className={`gtm-cta modal-close`}
							data-gtm-event-label={`${closegtm}`}
							data-isi={"neuromuscular"}
						/>
						{content}
					</ModalContent>
				</ModalContentWrap>
			</ModalPopup>
		</>
	);
};
