import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const NextPageLinkStyles = styled.div`
  display: block;
  margin-right: 0;
  margin-left: auto;
  margin-top: 0;
  width: 23rem;

  & > a {
    height: auto !important;
    line-height: 1.3125rem !important;
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
    @media ${device.mobile} {
      line-height: 1.4166rem;
    }
  }
`;
