import { useStaticQuery, graphql } from "gatsby";
export const PatientScanImages = (patientName) => {
  const data = useStaticQuery(graphql`
    query {
      elisse: file(relativePath: { eq: "patient-elisse-before-scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      william: file(
        relativePath: { eq: "patient-william-diagnosis-scan.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      judyBefore: file(
        relativePath: { eq: "patient-judy-diagnosis-scan.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      judyAfter: file(relativePath: { eq: "patient-judy-after-scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      joan: file(relativePath: { eq: "patient-joan-diagnosis-scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      karenBefore: file(
        relativePath: { eq: "patient-karen-diagnosis-scan.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      karenAfter: file(relativePath: { eq: "patient-karen-after-scan.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      daniela: file(
        relativePath: { eq: "patient-daniela-diagnosis-scan.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  switch (patientName) {
    case "Elisse":
      return data.elisse.childImageSharp.fluid.src;
    case "William Before":
      return data.william.childImageSharp.fluid.src;
    case "William After":
      return data.william.childImageSharp.fluid.src;
    case "Judy Before":
      return data.judyBefore.childImageSharp.fluid.src;
    case "Judy After":
      return data.judyAfter.childImageSharp.fluid.src;
    case "Joan Before":
      return data.joan.childImageSharp.fluid.src;
    case "Joan After":
      return data.joan.childImageSharp.fluid.src;
    case "Karen Before":
      return data.karenBefore.childImageSharp.fluid.src;
    case "Karen After":
      return data.karenAfter.childImageSharp.fluid.src;
    case "Daniela Before":
      return data.daniela.childImageSharp.fluid.src;
    default:
      return data.elisse.childImageSharp.fluid.src;
  }
};
