import styled from "styled-components";
import { device } from "../components/Helpers";

export const NavWrapper = styled.nav`
  img {
    height: 30px;
  }

  @media ${device.tablet} {
  }
`;
