import React from "react";
import { createMarkup } from "./Helpers";

export const ConvertedMarkdown = (props) => {
  // const id    = props.markdownID;

  const html = props.mdhtml;

  // let mdObj = '',
  //     mkHTML = '';
  //
  // Array.from(content).forEach((el) => {
  //     if (el.node.frontmatter.markdown_id === markdownID) {
  //         mdObj = el.node;
  //         mkHTML = el.node.html;
  //     }
  // });

  return (
    <>
      <div
        className={`md-container `}
        dangerouslySetInnerHTML={createMarkup(html)}
      />
    </>
  );
};
