import styled from "styled-components";
import { device, Browser } from "../components/Helpers";

export const AudioTranscriptStyles = styled.div`
	&:not(.purpbg) {
		.transcript-accordion {
			.transcript {
				&:after {
					/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+62,1+62 */
					background: -moz-linear-gradient(
						top,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 62%,
						rgba(255, 255, 255, 1) 100%
					); /* FF3.6-15 */
					background: -webkit-linear-gradient(
						top,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 62%,
						rgba(255, 255, 255, 1) 100%
					); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 1) 62%,
						rgba(255, 255, 255, 1) 100%
					); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
					bottom: 0;
					margin-bottom: 2px;
				}

				&:before {
					/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+38,1+38,0+100 */
					background: -moz-linear-gradient(
						top,
						rgba(255, 255, 255, 1) 0%,
						rgba(255, 255, 255, 1) 38%,
						rgba(255, 255, 255, 0) 100%
					); /* FF3.6-15 */
					background: -webkit-linear-gradient(
						top,
						rgba(255, 255, 255, 1) 0%,
						rgba(255, 255, 255, 1) 38%,
						rgba(255, 255, 255, 0) 100%
					); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 1) 0%,
						rgba(255, 255, 255, 1) 38%,
						rgba(255, 255, 255, 0) 100%
					); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
					margin-top: 2px;
					top: 0;
				}
			}
		}
	}

	&.patient-video-transcript {
		max-width: unset;

		@media ${device.mobile} {
			// background: ${(props) => props.theme.colors.brand_purple_00};
		}

		& > .transcript-accordion {
			.transcript {
				@media ${device.mobile} {
					// background: ${(props) =>
						props.theme.colors.brand_purple_00};
				}

				&::after,
				&::before {
					@media ${device.mobile} {
						content: none;
					}
				}

				.trans-copy .wide p {
					@media ${device.mobile} {
						// color: ${(props) =>
							props.theme.colors.brand_orange_00};
					}
				}
			}

			.trigger {
				@media ${device.mobile} {
					// background: ${(props) => props.theme.colors.yellow};
				}
			}
		}

		& > .header {
			@media ${device.mobile} {
				color: ${(props) => props.theme.colors.brand_orange_00};
				line-height: 5rem;
				margin-bottom: -1rem;
				margin-top: 5rem;
			}
		}
	}

	.transcript-accordion {
		.transcript {
			font-size: inherit !important;
			line-height: inherit !important;

			.trans-copy {
				.wide {
					p {
						color: ${(props) => props.theme.colors.brand_black_00};
					}
					&.home-trans {
						p {
							color: ${(props) => props.theme.colors.white};
						}
					}
				}
			}
		}
	}

	margin-top: 50px;
	.trigger {
		padding-left: ${Browser.isSafari ? '1px' : '0'};
		position: absolute;
		top: -0.6rem;
		left: 49.35%;
		text-align: center;
		background-color: #e35205;
		color: white;
		width: 1.25rem;
		height: 1.25rem;
		line-height: 0.8rem;
		font-size: 2rem;
		font-weight: bold;
		border-radius: 100%;
		cursor: pointer;
		z-index: 2;
		&:focus {
			outline: none;
		}
		&:active {
			outline: none;
		}
		&.yellow {
			background-color: ${(props) => props.theme.colors.yellow};
		}

		@media ${device.mobile} {
			font-size: 2rem;
			height: 1.5rem;
			left: 47.35%;
			line-height: 1.15rem;
			width: 1.5rem;
		}
	}
	.header {
		color: ${(props) => props.theme.colors.brand_orange_00};
		font-size: 0.75rem;
		font-weight: bold;
		text-align: center;

		@media ${device.mobile} {
			font-size: 1rem;
			margin-bottom: 1.75rem;
			margin-top: 5rem;
		}
	}
	&.purpbg {
		margin: 32px auto 0 auto;
		max-width: 1170px !important;
		min-width: 300px !important;
		width: 90% !important;
		padding: 0 1.25rem;
		.header {
			color: ${(props) => props.theme.colors.brand_gray_02};
		}
	}

	.transcript-accordion {
		position: relative;

		@media ${device.mobile} {
			padding-bottom: 3rem;
		}

		.transcript {
			margin-top: 1rem;
			padding: 0 0;
			padding-left: 1rem;
			padding-right: 1rem;
			border-top: 0.5px solid
				${(props) => props.theme.colors.brand_gray_02};

			border-bottom: none;
			font-size: 0.75rem;
			line-height: 0.875rem;

			background-color: ${(props) => props.theme.colors.white};
			max-height: 0;
			overflow-y: scroll;
			transition: all 0.2s ease-out;

			@media ${device.mobile} {
				padding: 0rem 1rem 0 1rem;
				max-height: 0;
			}

			&.open {
				padding: 1rem;
				border-bottom: 0.5px solid
					${(props) => props.theme.colors.brand_gray_02};
				max-height: 10rem;

				@media ${device.mobile} {
					padding: 2.5rem 1rem 1rem 1rem;
					max-height: 250px;
				}
			}

			&:after,
			&:before {
				content: "";
				height: 1rem;
				left: 0;
				position: absolute;
				width: 98.5%;
				z-index: 1;
			}

			&.inverse {
				background-color: transparent;
				color: ${(props) => props.theme.colors.white};
			}

			p {
				position: relative;
				margin-bottom: 0.438rem;
				line-height: 0.875rem;
				@media ${device.mobile} {
					font-size: 1rem;
					line-height: 1.25rem;
				}

				& > span:not(.mobileShowInline, .desktoponly) {
					position: absolute;
					left: -6px;
				}

				a {
					font-weight: bold;
					text-decoration: underline;
				}
			}

			.trans-copy {
				.wide {
					p {
						margin-bottom: 20px;
					}
				}

				.narrow-p {
					margin-bottom: 8px !important;
				}

				.wide-p {
					margin-bottom: 20px !important;
				}

				p {
					margin-bottom: 8px;
					font-size: 16px;
					line-height: 19px;
				}

				ul {
					padding-left: 20px;
					li {
						margin-bottom: 10px;

						&::before {
							color: #262626;
							font-size: 12px;
							line-height: 22px;
						}
						span {
							display: block;
							margin-left: -10px;
						}
					}
				}
			}
		}
	}
	&.audiotrayopen {
		.transcript-accordion {
			.transcript {
				padding: 1rem 0;
				border-bottom: ${(props) => ".5px solid" + props.theme.colors.brand_gray_02};
				max-height: 10rem;
				@media ${device.mobile} {
					padding: 2.5rem 1rem 1rem 1rem;
					max-height: 250px;
				}
			}
		}
	}
	&.home-transcript {
		@media ${device.mobile} {
			border-bottom: 1px solid #fff;
		}
		.header {
			@media ${device.mobile} {
				margin-top: 2.25rem !important;
				margin-bottom: 0 !important;
			}
		}
	}
`;
