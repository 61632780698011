import styled from "styled-components/macro";
import { device } from "../components/Helpers";
import DownArrow from "../images/white-drop-down.svg";
import RightArrow from "../images/caret-white.svg";
import OrangeArrow from "../images/orange-drop-down.svg";

export const NavigationStyling = styled.div`
    border-radius: 10px;
    display: none;
    height: auto;
    left: 0;
    margin-top: 10rem;
    overflow: hidden;
    padding: 2rem 5% !important;
    position: fixed;
    top: 0;
    width: 100%;

    @media ${device.desktop_xlg} {
      box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
      max-width: 1170px!important;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 200px;
      z-index: 100;
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      padding: 2rem 0 1.25rem 5% !important;
    }

    @media ${device.mobile} {
      margin-top: 11rem !important;
    }

    @media ${device.tablet} {
      margin-top: 9.5rem;
    }

    @media ${device.desktop_toMobile} {
      margin-top: 9.5rem;
    }

    &.mobile-menu {
      display: block;
      z-index: 2;
    }

    #enrollment-download {
      display: inline-flex !important;
      font-size: 1.15rem !important;
      padding: 1rem 2.5rem;
      text-indent: -1.5rem;

      &:hover {
        text-decoration: none;
      }
    }

    .utiliy-links {
      display: block !important;

      & > li > a {
        font-size: 1.175rem;
        line-height: 1.2rem;
        padding-bottom: 0;
        padding-right: 0;
        margin-bottom: 0;
        padding: 0.25rem;
        display: inline-block;

        &::after {
          content: none;
        }

        &:hover::before {
          border-bottom: 2px solid ${(props) => props.theme.colors.white};
        }
      }
    }

  @media ${device.desktop_lg} {
    padding-left: 17rem;
  }

  & > ul {
    background-color: ${(props) => props.theme.colors.brand_purple_01};
    border-radius: 0.5rem;
    justify-content: flex-start !important;
    padding-top: 0.25rem;
    position: relative;
    width: 100%;
    z-index: 1;
    /* opacity: 50%; */

    @media ${device.mobile} {
      display: none !important;
    }
    @media ${device.tablet} {
      display: none !important;
    }
    @media ${device.desktop} {
      display: none !important;
    }
    @media ${device.desktop_max} {
      display: none !important;
    }

    & > li {
      line-height: 3rem;
      margin: 0;
      padding-top: 0.9rem !important;
      padding-bottom: 0.9rem !important;

      a {
        color: ${(props) => props.theme.colors.brand_purple_00};
        display: flex;
        margin: 0 0.75rem;
        position: relative;
        text-transform: initial;
        text-decoration: none !important;

        &::before {
          content: "";
          border-bottom: 3px solid
            ${(props) => props.theme.colors.brand_orange_00};
          bottom: 0;
          margin-bottom: -0.35rem;
          position: absolute;
          width: 0;
          transition: ${(props) => props.theme.transitions.transition_1};
        }
      }

      &.active > a {
        font-weight: bold;
        text-decoration: underline;
      }

      &.active > a::before {
        width: 100%;
      }
    }
  }

  nav {
    box-shadow: 1px 5px 7px -3px rgb(0 0 0 / 83%);
    margin-bottom: -0.5rem;
    margin-left: -0.25rem;
    width: 100.65%;
    
    @media ${device.desktop_max} {
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      margin: 0 auto;
      padding-top: 2rem !important;
      padding: 0 1rem;
    }

    @media ${device.tablet} {
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      margin: 0 auto;
      padding-top: 2rem !important;
      padding: 1rem!important;
    }

    @media ${device.mobile} {
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      border-top-left-radius: 0.75rem;
      margin: 0 auto;
      padding: .5rem 1.5rem;
      width: 100%;
    }

    @media ${device.desktop} {
      background-color: ${(props) => props.theme.colors.brand_orange_00};
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
      margin: 0 auto;
      // overflow-y: scroll;
      padding-top: 2rem !important;
      padding: 0 1rem;
    }

    @media ${device.desktop_xlg} {
      box-shadow: none;
    }

    & > .btn-4.download:hover {
      &::after {
        right: 0 !important;
        top: 0 !important;
      }

      margin: 0.85rem ​1rem !important;
    }

    & > ul {
      position: relative;
      z-index: 2;
      flex-wrap: wrap;
      @media ${device.desktop_xlgmx} {
        align-content: flex-start;
        flex-direction: row;
        // margin-left: -1rem;
        margin-top: 0.75rem;
        flex-wrap: wrap;
      }
      @media ${device.tablet} {
        align-content: flex-start;
        flex-direction: row;
        // margin-left: -1rem;
        margin-top: 0.75rem;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media ${device.mobile} {
        flex-direction: column;
      }
    }

    & > ul > li {
      align-items: center;
      display: flex;
      font-weight: bold;
      position: relative;
      margin-bottom: 1rem;
      width: 33%;
      
      @media ${device.desktop_xlgmx} {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: -0.85rem;
        line-height: 0rem !important;
        width: 33%;
        &.open > ul {
          height: initial;
          overflow: hidden;
        }

        & > a:hover,
        & > a:focus {
          text-decoration: none;
          outline: 0;
        }

        &:hover > a::after,
        &.open > a::after {
          // transform: rotate(90deg) translate(-1px, 0px);
          // transition: all 0.2s 0s ease-in;
        }
      }

      @media ${device.tablet} {
        width: 30%;
      }

      @media ${device.mobile} {
        width: 100%;
        margin-bottom:0;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }

      &:nth-of-type(10) {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      &:last-of-type {
        @media screen and (min-width: 768px) {
        //   display: none;
        }
      }

      &.open {
        a::before {
          @media ${device.desktop_xlgmx} {
            border-bottom: 2px solid ${(props) => props.theme.colors.white};
          }
        }
      }

      &:nth-of-type(6),
      &:last-of-type {
        & > ul {
          left: unset;
          right: 0;
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0;
        }
      }

      &:hover > ul {
        @media ${device.desktop_xlgmx} {
          display: block;
        }
      }

      &:not(.active):hover {
        //background-color: rgba(227, 82, 5, 1) !important;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        & > a {
          color: ${(props) => props.theme.colors.white} !important;
          //text-decoration: none;
        }

        & > ul {
          display: block;
          height: auto;
          transition: all 0.2s 0.5s ease-in;
        }

        & > ul > li {
          line-height: initial !important;
          transition: all 0.2s 0s ease-in;

          &:first-child {
            margin-top: -0.35rem;
          }

          &:hover {
            cursor: pointer;

            @media ${device.desktop_xlgmx} {
              // transform-origin: left;
              // transform: translateX(0.25rem);
            }

            & > a {
              @media ${device.desktop_xlg} {
                // transform-origin: left;
                // transform: translateX(0.25rem);
                // transition: all 0.2s 0s ease-in;
              }
            }

            & > a::after {
              content: "";
              background-image: none;
              /* border-bottom: 2px solid ${(props) =>
                props.theme.colors.white}; */
              bottom: 0;
              left: 0;
              position: absolute;
              width: 100%;
            }
          }
        }
      }

      &.active {
        background-color: ${(props) => props.theme.colors.brand_purple_01};
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        color: ${(props) => props.theme.colors.brand_purple_00};
        margin-left: 0.25rem;
        margin-top: 0;
        position: relative;
        z-index: -1;
        /* opacity: 50%; */
        margin-bottom: 0 !important;
        padding-top: 0.6rem !important;
        @media ${device.desktop_xlgmx} {
          background-color: unset;
          margin-top: 0.5rem;

          & > a {
            color: ${(props) => props.theme.colors.white} !important;
            /* font-style: italic; */
            font-weight: bolder;
          }
        }

        & > a {
          color: ${(props) => props.theme.colors.brand_purple_00};
          cursor: default;
          text-decoration: none !important;
          &::after {
            content: url(${OrangeArrow});
            bottom: 54%;
          }
        }
      }

      & > a {
        @media ${device.desktop_xlgmx} {
          font-size: 1rem;
          margin-bottom: 0.5rem;
          padding-bottom: 1rem;
          position: relative;

          &::before {
            content: "";
            bottom: 0;
            position: absolute;
            width: 100%;
          }
        }
        @media ${device.mobile} {
          font-size: 1.5rem;
          padding: 1rem 0;
          line-height: 1rem;
        }
		@media only screen and (max-height: 650px) and (max-width: 767px) {
		  line-height: 0.4rem;
		}
      }

      & > ul {
        @media ${device.desktop_lg} {
          margin-top: 2.63rem;
        }

        @media ${device.desktop_xlgmx} {
          display: none;
          box-shadow: unset;
          height: 100%;
          margin: 0 0 1rem -1rem;
          position: relative;
          transition: 0.2s 0.5s ease;
          transition: all 0.2s 0.5s ease-in;
          width: 100%;
        }

        background-color: rgba(227, 82, 5, 1);
        border-radius: 0.35rem;
        border-top-left-radius: 0;
        box-shadow: ${(props) => props.theme.shadows.shadow_1};
        display: none;
        left: 0;
        margin-top: 3.5rem;
        padding: 0.25rem 1rem;
        position: absolute;
        top: 0;
        width: 20vw;
        z-index: 1;
      }

      & > ul > li {
        margin-bottom: 0;
        margin-top: -0.5rem;

        &:hover > a::after {
          content: "";
          background-image: none;
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
          border-bottom: 2px solid ${(props) => props.theme.colors.white};

          @media ${device.desktop_xlg} {
            border-bottom: none;
          }
        }
      }

      & > ul > li > a {
        display: inline-block;

        @media ${device.desktop_xlgmx} {
          font-size: 1.175rem;
          line-height: 1.2rem;
          padding-right: 0;
        }

        &:hover {
          position: relative;
          text-decoration: none;
        }

        &:hover::after {
          content: "";
          background-image: none;
          border-bottom: 2px solid ${(props) => props.theme.colors.white};
          bottom: 0;
          left: 0;
          margin-bottom: -0.35rem;
          position: absolute;
          width: 100%;
        }
      }

      & > ul > li > a::after {
        margin-top: 0.5rem;

        @media ${device.desktop_xlgmx} {
          content: none;
        }
      }
    }
  }

  li {
    font-size: 0.97rem !important;
    line-height: 1.25rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    text-transform: initial !important;
    @media ${device.ie11} {
      text-transform: none !important;
    }
    a {
      font-size: inherit;
      letter-spacing: 0rem;
      line-height: 1rem;
      margin-top: -0.35rem;
      position: relative;
      color: ${(props) => props.theme.colors.white};
      //font-weight:normal;

      &:hover{
        text-decoration:none;
        transform-origin: left center 0px;
        transform: translateX(0.35rem);
        transition: all 0.2s ease-in 0s;
      }

      @media ${device.desktop_xlgmx} {
        line-height: 1rem;
        padding-right: 2rem;
        position: relative;

        &::after {
          content: "";
          background-image: url("../images/caret-white.svg");
          background-repeat: no-repeat;
          background-size: contain;
          margin-top: 0.225rem;
          padding: 0.35rem;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &.nav-down-arrow {
        &::after {
          content: url(${DownArrow});
          position: absolute;
          right: 8px;
          bottom: 45%;

          @media ${device.desktop_xlgmx} {
            content: "";
          }
        }
      }
      &.nav-right-arrow {
        &::after {
          content: url(${RightArrow});
          position: absolute;
          right: -15px;
          bottom: 0%;

          @media ${device.desktop_xlgmx} {
            content: "";
            // right: -15px;
            bottom: 0;
            height: 13px;
            top: -1px;
          }
          @media ${device.tablet} {
            right: 7px;
          }
          @media ${device.mobile} {
            bottom: 0;
            height: auto;
            top: 10px;
          }
        }
      }
    }
  }

  #enrollment-download {
    display: none;
  }

  .utiliy-links {
    display: none;
  }
`;
