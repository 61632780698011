import React from "react";
import { Injector } from "./partials";
import { InterstitialModal } from "../Modals";

export const InterstitialInjector = ({ query }) => {
  const buildInjectors = () => {
    return query.map(
      (
        { container, trigger, disclaimer, setInner, externalURL, gtmlabel, injectorContainer },
        i
      ) => {
        return (
          <div key={`${i}-interstitial-injector`}>
            <Injector
              container={container}
              content={
                <InterstitialModal
                  modalName={`num${i}-interstitial-injector-modal`}
                  trigger={trigger}
                  disclaimer={disclaimer}
                  setInner={setInner}
                  externalURL={externalURL}
                  gtmLabel={gtmlabel}
				  injectorContainer={injectorContainer}
                />
              }
            />
          </div>
        );
      }
    );
  };

  return <>{buildInjectors()}</>;
};
