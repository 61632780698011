import React, { useEffect } from "react";
import { SecondaryNavControlStyling } from "../styles";
import { Link, useStaticQuery, graphql } from "gatsby";
import { interaction } from "./Helpers";

export const SecondaryNavControl = ({slug, markdownID}) => {

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            markdown_id
            components_id
            slug
            subnavItems {
              copy
              path
              sectionRef
            }
            pageOrder
            parentNavItem {
              copy
              path
            }
          }
          id
          html
        }
      }
    }
  `);

    const markdownNodes = data.allMarkdownRemark.nodes;
    const currentslug = slug;

    let navList = [];

    markdownNodes.forEach((el) => {
      if (el.frontmatter.subnavItems !== null) {
        navList[parseInt(el.frontmatter.pageOrder)] = el.frontmatter;
      }
    });

    var backlink, backcopy, nextlink, nextcopy;

    navList.forEach((el) => {
      if (el.slug === currentslug) {

        backlink = "/" + el.subnavItems[0].path;
        backcopy = el.subnavItems[0].copy;

        nextlink = "/" + el.subnavItems[1].path;
        nextcopy = el.subnavItems[1].copy;
      }
    });

    if (!backlink ){
        backlink = "";
        backcopy = "";
        nextlink = "/patient-profiles";
        nextcopy = "Patient profiles";
    }

  useEffect(() => {
    const _disabledNavItem = document.querySelector(`#secondary-nav > a.disabled`);
    
    if (_disabledNavItem) {
      _disabledNavItem.addEventListener(interaction, (e) => {
        e.preventDefault();
      });
    }
    else return;
  });

  return (
    <SecondaryNavControlStyling id={`secondary-nav`}>
      <Link
          key={backlink}
          to={backlink}
          className={ (markdownID === `home-content`) ? `prevNavBtn disabled` : `prevNavBtn gtm-click ` }
          data-gtm-event-label={`Left Carousel Arrow`}
        >
          {backcopy}
        </Link>

        <Link
          key={nextlink}
          to={nextlink}
          className={ (markdownID === `ed-summary-content`) ? `nextNavBtn disabled` : `nextNavBtn gtm-click `}
          data-gtm-event-label={`Right Carousel Arrow`}
        >
          { (markdownID === `ed-summary-content`) ? `` : nextcopy}
        </Link>
    </SecondaryNavControlStyling>
    
  );
};
