import React from "react";
import { Injector } from "./partials";
import { FetchComponents } from "./utils";

export const ChartKeysInjector = ({ componentID }) => {
  const { chartKeys } = FetchComponents(componentID);

  const buildInjectors = () => {
    return (
      <div key={`chart-keys-injector`}>
        <Injector container={"chart-keys"} content={buildChartKeys()} />
      </div>
    );
  };

  const buildChartKeys = () => {
    return chartKeys.map((chartKey, i) => {
      return <p key={`${i}-chart-key`}>{chartKey}</p>;
    });
  };

  return <>{buildInjectors()}</>;
};
